import { emphasize } from '@material-ui/core/styles/colorManipulator';

const tagsStyle = theme => ({
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  }
});

export default tagsStyle;
