import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "locales/en/translation.json";
import translationDe from "locales/de/translation.json";
import translationFr from "locales/fr/translation.json";
import translationIt from "locales/it/translation.json";
import translationNl from "locales/nl/translation.json";

import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'

var moment = require('moment');

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: translationEn
    },
    de: {
      translations: translationDe
    },    
    fr: {
      translations: translationFr
    },
    it: {
      translations: translationIt
    },
    nl: {
      translations: translationNl
    },
  },
  fallbackLng: "de",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
}, () => {
  // set moment
  moment.locale(i18n.language === "de" ? "de-ch" : i18n.language);
});

export default i18n;