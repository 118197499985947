import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AlertDialog from "components/Dialog/Dialog.jsx";
import LinkTab from "../../components/Tab/LinkTab.jsx";
import Tabs from "../../components/Tab/Tabs.jsx";
import TabPanel from "../../components/Tab/TabPanel.jsx";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faCheckSquare, faSquare, faTimes} from '@fortawesome/free-solid-svg-icons'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import MUIDataTable from "mui-datatables";
import Tags from "components/Tags/Tags.jsx";

import {primaryColor} from "assets/jss/material-dashboard-react.jsx";

import {floatToText, textToFloat} from "../../functions/helpers";
import {api_ipaddress} from '../../variables.js'
import axios from 'axios'

import {withTranslation} from 'react-i18next';
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'
import {sprintf} from "sprintf-js";

var moment = require('moment-timezone');
moment.locale("de-ch");

const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

const dateFormat = "DD.MM.YYYY";
const COPY_TAB = 0;
const DELETE_TAB = 1;
const PUBLISH_TAB = 2;
var overviewAdressesFilter = [];
var overviewCustomerFilter = [];


function pad(a, b) { return (1e15 + a + "").slice(-b) }

function getFirstDay(year) {
  var d = new Date(year, 0, 1);
  var day = d.getDay();
  d.setDate(d.getDate() + (day%6? 0 :  (++day%4)));
  return moment(d);
}

function getWeekToChoose(date) {

  let labelWeek = pad(date, 2)
  let week = parseInt(labelWeek)
  let year = parseInt(date.substring(0,4))
  
  let momentWeek = moment().year(year).week(week)

  let firstDay = moment(Object.assign({}, momentWeek.weekday(0)))
  let lastDay = moment(Object.assign({}, momentWeek.weekday(6)))

  return { value: { start_date: firstDay, end_date: lastDay }, label: `W${labelWeek} (${firstDay.format(dateFormat)} - ${lastDay.format(dateFormat)})` };
}

class Disposition extends React.Component {
  constructor(props) {
    super(props);

    let weekdays = moment.weekdays();
    weekdays.push(weekdays.shift());

    let week = {};
    weekdays.forEach(day => {
      week[`d_day_enable_${day}`] = false;
      week[`d_group_${day}`] = null;
      week[`d_subcontractor_${day}`] = null;
      week[`d_vehicle_${day}`] = null;
      week[`d_driver_${day}`] = null;
      week[`d_sub_invoice_trip_${day}`] = null;
      week[`d_sub_invoice_trip_${day}_error`] = null;
      week[`d_sub_invoice_toll_${day}`] = null;
      week[`d_sub_invoice_toll_${day}_error`] = null;
      week[`d_observer_${day}`] = [];
      week[`d_vehicle_choices_${day}`] = [];
      week[`d_driver_choices_${day}`] = [];
    });

    let requirementChoices = ["11_2_7"];
    let requirements = {};
    requirementChoices.forEach(item => {
      requirements[`tr_requirement_${item}`] = true;
    });

    this.state = {
      tableLayout: "scroll",
      alert: false,
      tours: [],
      tr_status: true,
      tr_name: null,
      tr_client: null,
      tr_departure: null,
      tr_arrival: null,
      tr_tour_comb: null,
      tr_stp: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_std: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_std_plus_day: "0",
      tr_std_plus_day_error: "",
      tr_sta: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_sta_plus_day: "0",
      tr_sta_plus_day_error: "",
      tr_customer_invoice_trip: '0.00',
      tr_customer_invoice_toll: '0.00',
      tabValue: 0,
      actionWeeksCopy: [],
      actionWeeksChoicesCopy: [],
      actionWeeksDelete: [],
      actionWeeksChoicesDelete: [],
      actionWeeksPublish: [],
      actionWeeksChoicesPublish: [],
      departureChoices: [],
      arrivalChoices: [],
      addresses: [],
      statusChoices: [],
      customerChoices: [],
      combinationChoices: [],
      requirementChoices: requirementChoices,
      subcontractorChoices: [],
      vehicleChoices: [],
      driverChoices: [],
      observerChoices: [],
      weekdays: weekdays, //Sunday is always last
      updateDT: 1,
      filterList: JSON.parse(localStorage.getItem("dispositionFilterList")),
      dispositionColListDesktop: JSON.parse(localStorage.getItem("dispositionColListDesktop")),
      dispositionColListMobile: JSON.parse(localStorage.getItem("dispositionColListMobile")),
      ...week,
      ...requirements,
    };

    this.trStdPlusDayRef = React.createRef()
    this.trStaPlusDayRef = React.createRef()
  }

  //Get data to use in page
  componentDidMount() {
    moment.tz.setDefault("Etc/UTC");

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    const { t } = this.props;
    this.updateData()

    this.setState({ statusChoices: [{ value: true, label: `${t("dashboard_scheduled")}` }, { value: false, label: `${t("dashboard_inactive")}` }] });
  }

  updateFloatValues(res) {
    res.data.forEach(tour => {
      tour.tr_customer_invoice_trip = tour.tr_customer_invoice_trip ? floatToText(tour.tr_customer_invoice_trip) : '0.00'
      tour.tr_customer_invoice_toll = tour.tr_customer_invoice_toll ? floatToText(tour.tr_customer_invoice_toll) : '0.00'
      tour.tr_week_sub_invoice_trip = tour.tr_week_sub_invoice_trip.map(day => day ? floatToText(day) : '0.00')
      tour.tr_week_sub_invoice_toll = tour.tr_week_sub_invoice_toll.map(day => day ? floatToText(day) : '0.00')
    })
    return res
  }

  updateData() {
    axios.get(`${api_ipaddress}/api/tours/all`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const result = this.updateFloatValues(res)
        this.setState({ tours: result.data });
      });

    if(localStorage.getItem("role") == "admin"){
      axios.get(`${api_ipaddress}/api/customers/all`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          let customer_choices = "data" in res
            ? res.data.map(customer => ({ value: customer.cm_id, label: customer.cm_name }))
            : [];
          this.setState({ customerChoices: customer_choices });
        });
    }

    axios.get(`${api_ipaddress}/api/tours/combinations/all`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        let combination_choices = "data" in res
          ? res.data.map(combination => ({ value: combination, label: combination }))
          : [];
        this.setState({ combinationChoices: combination_choices });
      });

    axios.get(`${api_ipaddress}/api/address`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const address_choices = "data" in res
          ? res.data.map(item => ({ value: item.add_id, label: item.add_name }))
          : [];

        const address_choices_with_id = "data" in res
          ? res.data.map(item => ({ value: item.add_id, label: `${item.add_name} (${item.add_id})` }))
          : [];
        this.setState({ addresses: address_choices, departureChoices: address_choices_with_id, arrivalChoices: address_choices_with_id });
      });
    if(localStorage.getItem('role') === "admin"){
      this.getToursToCopy()
    }
  }

  resize() {
    this.setState({
      tableLayout: window.innerWidth <= 620 ? "stacked" : "scroll",
      windowHeight: window.innerHeight
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableRow: {
        root: {
          height: "24px",
        }
      },
      MuiTableCell: {
        root: this.state.tableLayout === "scroll" ? {
          paddingRight: "4px",
          paddingTop: "6px",
          paddingLeft: "4px",
          paddingBottom: "6px",
          whiteSpace: "nowrap",
        } : {
            paddingTop: "4px",
            paddingBottom: "1px",
          }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: window.innerWidth <= 1280 ?
            `${this.state.windowHeight - 435}px !important` :
            (localStorage.getItem('role') === "admin") ? `${this.state.windowHeight - 395}px !important`: `${this.state.windowHeight - 200}px !important`,
          minHeight: "300px",
        },
      },
    }
  })

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  checkedIcon = (value, t, i) => {
    const icon = value.tr_week_status[i] ?
      <FontAwesomeIcon icon={faCheckSquare} size="lg"
        style={{ color: "#23c644", paddingRight: "4px" }} /> :
      <FontAwesomeIcon icon={faSquare} size="lg"
        style={{ color: "lightgrey", paddingRight: "4px" }} />

    const tourWeekData = value.tr_week_observer ?
        <div>
          <div>{t("disposition_subcontractor")}: {value.tr_week_sub[i] !== null ? value.tr_week_sub[i] : "-"}</div>
          <div>{t("disposition_vehicle")}: {value.tr_week_gps[i] !== null ? value.tr_week_gps[i] : "-"}</div>
          <div>{t("disposition_driver")}: {value.tr_week_driver[i] !== null ? value.tr_week_driver[i] : "-"}</div>
          <div>{t("disposition_observer")}: {value.tr_week_observer[i] !== "" ? value.tr_week_observer[i].replace(/;/g, ", ") : "-"}</div>
          <div>{t("disposition_sub_invoice_trip")}: {value.tr_week_sub_invoice_trip[i] !== "" ? `${parseFloat(value.tr_week_sub_invoice_trip[i]).toFixed(2)} ${t("currency_symbol")}` : "-"}</div>
          <div>{t("disposition_sub_invoice_toll")}: {value.tr_week_sub_invoice_toll[i] !== "" ? `${parseFloat(value.tr_week_sub_invoice_toll[i]).toFixed(2)} ${t("currency_symbol")}` : "-"}</div>
        </div>
      :
        <div>
          <div>{t("disposition_subcontractor")}: {value.tr_week_sub[i] !== null ? value.tr_week_sub[i] : "-"}</div>
          <div>{t("disposition_vehicle")}: {value.tr_week_gps[i] !== null ? value.tr_week_gps[i] : "-"}</div>
          <div>{t("disposition_driver")}: {value.tr_week_driver[i] !== null ? value.tr_week_driver[i] : "-"}</div>
          <div>{t("disposition_sub_invoice_trip")}: {value.tr_week_sub_invoice_trip[i] !== "" ? `${parseFloat(value.tr_week_sub_invoice_trip[i]).toFixed(2)} ${t("currency_symbol")}` : "-"}</div>
          <div>{t("disposition_sub_invoice_toll")}: {value.tr_week_sub_invoice_toll[i] !== "" ? `${parseFloat(value.tr_week_sub_invoice_toll[i]).toFixed(2)} ${t("currency_symbol")}` : "-"}</div>
        </div>;

    return (this.state.tableLayout === "scroll") ?
      <Tooltip title={tourWeekData}>
        <div>
          {icon}
        </div>
      </Tooltip>
      : icon;
  }

  asTime = (momentObj) => {
    try {
      return momentObj.isValid() ? momentObj.format("HH:mm") : ""
    }
    catch (e) { return "" }
  }

  actionIcons = (d, t) => {
    let weekdays = moment.weekdays();
    weekdays.push(weekdays.shift());

    const { vehicleChoices, driverChoices } = this.state;

    return <div>
      <IconButton
        aria-label={t("disposition_edit")}
        style={{ padding: 0, marginRight: 10 }}
        onClick={() => {
          this.props.history.push(`/admin/editdisposition/${d.tr_id}`);
        }}
      >
        <EditIcon />
      </IconButton>
      {localStorage.getItem('role') === "admin" &&
      <IconButton
        aria-label={t("disposition_delete")}
        style={{ padding: 0 }}
        onClick={() => {
          this.setState({
            alert: true,
            clickedDisposition: d.tr_id,
          })
        }}
      >
        <DeleteIcon />
      </IconButton>
      }
    </div >
  }

  checkedOptions = (name, i, labels, t, colList) => {
    return {
      filter: true, sort: false,
      filterList: this.state.filterList[name],
      customFilterListRender: v => `${labels[i]}: ${v}`,
      customBodyRender: (value) => this.checkedIcon(value, t, i),
      display: this.state[colList][name],
      filterOptions: {
        names:
          [
            `${t("dashboard_active")}`,
            `${t("dashboard_inactive")}`,
          ],
        logic: (row, value) => {
          if (value.length > 0) {
            let bValue = value[0] === `${t("dashboard_active")}` ? true : false;
            return row.tr_week_status[i] !== bValue;
          }
          return false;
        },
      }
    }
  }

  activeOptions = (t, colList) => {
    return {
      filter: true, sort: true,
      filterList: this.state.filterList["status"],
      customFilterListRender: v => `${t("disposition_status")}: ${v}`,
      customBodyRender: (value) => {
        if (value) {
          return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
        } else {
          return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "red" }} />
        }
      },
      display: this.state[colList]["status"],
      filterOptions: {
        names:
          [
            `${t("dashboard_scheduled")}`,
            `${t("dashboard_inactive")}`,
          ],
        logic: (row, value) => {
          if (value.length > 0) {
            let bValue = value[0] === `${t("dashboard_scheduled")}` ? true : false;
            return row !== bValue;
          }
          return false;
        },
      }
    }
  }

  standardOptions = (name, label, colList) => {
    return {
      filter: true, sort: true,
      filterList: this.state["filterList"][name],
      customFilterListRender: v => `${label}: ${v}`,
      display: this.state[colList][name]
    }
  }

  clientColumnOptions = (name, label, colList) => {
    return {
      filter: true, sort: true,
      filterList: this.state["filterList"][name],
      customFilterListRender: v => `${label}: ${v}`,
      display: (localStorage.getItem('role') === "admin") ? this.state[colList][name] : 'excluded'
    }
  }

  customLabelOptions = (name, labelName, t, colList) => {
    return {
      filter: true, sort: true,
      filterList: this.state["filterList"][name],
      customFilterListRender: v => `${t(`${labelName}_${v}`)}: ${v}`,
      customBodyRender: (value) => (value !== null && value.length !== 0) ? t(`${labelName}_${value}`) : "",
      display: this.state[colList][name]
    }
  }

  dateOptions = (name, colList) => {
    return {
      sort: true,
      customBodyRender: (momentObj) => this.asTime(momentObj),
      display: this.state[colList][name]
    }
  }

  actionOptions = (t) => {
    return {
      filter: false,
      sort: false,
      searchable: false,
      print: false,
      download: false,
      viewColumns: false,
      display: true,
      customBodyRender: (value) => this.actionIcons(value, t),
    }
  }

  driverHasRequirements = (driver, t) => {
    const { requirementChoices } = this.state;
    let requerimentStatus = requirementChoices.filter(item => this.state[`tr_requirement_${item}`]).map(item => (item !== null && item.length !== 0)
        ? t(`disposition_requirement_${item}`) : "");

    if (requerimentStatus === null || requerimentStatus.length === 0)
      return true;

    if (driver.requirements.length > 0) {
      if (driver.requirements.includes(';'))
        return driver.requirements.split(';').every(item => requerimentStatus.includes(item));
      else
        return requerimentStatus.includes(driver.requirements);
    }
    else
      return false;
  }

  clearData = () => {
    const { requirementChoices } = this.state;

    let weekdays = moment.weekdays();
    weekdays.push(weekdays.shift());

    this.setState({
      tr_status: true,
      tr_name: null,
      tr_client: null,
      tr_departure: null,
      tr_arrival: null,
      tr_tour_comb: null,
      tr_stp: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_std: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_sta: moment("00:00", ["HH:mm", moment.ISO_8601]),
    })
    requirementChoices.forEach(item => {
      this.setState({ [`tr_requirement_${item}`]: true });
    });

    weekdays.forEach(day => {
      this.setState({
        [`d_day_enable_${day}`]: false,
        [`d_group_${day}`]: null,
        [`d_subcontractor_${day}`]: null,
        [`d_vehicle_${day}`]: null,
        [`d_driver_${day}`]: null,
        [`d_sub_invoice_trip_${day}`]: null,
        [`d_sub_invoice_toll_${day}`]: null,
        [`d_drivernr_${day}`]: null,
        [`d_observer_${day}`]: []
      })
    });
  }

  a11yProps = (index) => {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-linkTab-${index}`,
    };
  }

  getToursToCopy = () => {
    axios.get(`${api_ipaddress}/api/tours/weeks/copy`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const copy_choices = "data" in res
          ? res.data.map(item => getWeekToChoose(item))
          : [];
        this.setState({ actionWeeksChoicesCopy: copy_choices });
      });
  }

  getToursToDelete = () => {
    axios.get(`${api_ipaddress}/api/tours/weeks/delete`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const delete_choices = "data" in res
          ? res.data.map(item => getWeekToChoose(item))
          : [];
        this.setState({ actionWeeksChoicesDelete: delete_choices });
      });
  }

  getToursToPublish = () => {
    axios.get(`${api_ipaddress}/api/tours/weeks/publish`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const publish_choices = "data" in res
          ? res.data.map(item => getWeekToChoose(item))
          : [];
        this.setState({ actionWeeksChoicesPublish: publish_choices });
      });
  }

  changeTab = (event, newValue) => {
    this.setState({ tabValue: newValue });
    switch (newValue) {
      case COPY_TAB: {
        this.getToursToCopy()
        break;
      }
      case DELETE_TAB: {
        this.getToursToDelete()
        break;
      }
      case PUBLISH_TAB: {
        this.getToursToPublish()
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;

    const {
      tableLayout,
      tabValue,
      actionWeeksCopy,
      actionWeeksDelete,
      actionWeeksPublish,
      actionWeeksChoicesCopy,
      actionWeeksChoicesDelete,
      actionWeeksChoicesPublish,
      addresses,
      customerChoices,
      weekdays,
    } = this.state;

    overviewAdressesFilter = addresses;
    overviewCustomerFilter = customerChoices;

    const data = this.state.tours !== null ?  localStorage.getItem('role') === "admin" ? this.state.tours.map(
      r => ({
        status: r.tr_status,
        name: r.tr_name,
        client: r.tr_cm_name,
        customer_invoice_trip: r.tr_customer_invoice_trip,
        customer_invoice_toll: r.tr_customer_invoice_toll,
        departure: r.tr_departure,
        arrival: r.tr_arrival,
        monday: r,
        tuesday: r,
        wednesday: r,
        thursday: r,
        friday: r,
        saturday: r,
        sunday: r,
        stp: moment(r.tr_stp),
        std: moment(r.tr_std),
        sta: moment(r.tr_sta),
        combination: r.tr_tour_comb,
        requirements: r.tr_requirements,
        actions: r
      })
    ) : this.state.tours.map(
      r => ({
        status: r.tr_status,
        name: r.tr_name,
        customer_invoice_trip: r.tr_customer_invoice_trip,
        customer_invoice_toll: r.tr_customer_invoice_toll,
        departure: r.tr_departure,
        arrival: r.tr_arrival,
        monday: r,
        tuesday: r,
        wednesday: r,
        thursday: r,
        friday: r,
        saturday: r,
        sunday: r,
        stp: moment(r.tr_stp),
        std: moment(r.tr_std),
        sta: moment(r.tr_sta),
        combination: r.tr_tour_comb,
        requirements: r.tr_requirements,
        actions: r
      })
    ) : [];

    const columns = tableLayout === "scroll" ?
      [ // full desktop view
        { name: "status", label: t("disposition_status"), options: this.activeOptions(t, "dispositionColListDesktop") },
        { name: "name", label: t("disposition_tour_nr"), options: this.standardOptions("name", t("disposition_tour_nr"), "dispositionColListDesktop") },
        ...(localStorage.getItem('role') === "admin" ? [{ name: "client", label: t("disposition_client"), options: this.standardOptions("client", t("disposition_client"), "dispositionColListDesktop") }] : []),
        { name: "departure", label: t("disposition_departure"), options: this.standardOptions("departure", t("disposition_departure"), "dispositionColListDesktop") },
        { name: "arrival", label: t("disposition_arrival"), options: this.standardOptions("arrival", t("disposition_arrival"), "dispositionColListDesktop") },
        { name: "monday", label: t("general_weekday_abbr_monday"), options: this.checkedOptions("monday", 0, weekdays, t, "dispositionColListDesktop") },
        { name: "tuesday", label: t("general_weekday_abbr_tuesday"), options: this.checkedOptions("tuesday", 1, weekdays, t, "dispositionColListDesktop") },
        { name: "wednesday", label: t("general_weekday_abbr_wednesday"), options: this.checkedOptions("wednesday", 2, weekdays, t, "dispositionColListDesktop") },
        { name: "thursday", label: t("general_weekday_abbr_thursday"), options: this.checkedOptions("thursday", 3, weekdays, t, "dispositionColListDesktop") },
        { name: "friday", label: t("general_weekday_abbr_friday"), options: this.checkedOptions("friday", 4, weekdays, t, "dispositionColListDesktop") },
        { name: "saturday", label: t("general_weekday_abbr_saturday"), options: this.checkedOptions("saturday", 5, weekdays, t, "dispositionColListDesktop") },
        { name: "sunday", label: t("general_weekday_abbr_sunday"), options: this.checkedOptions("sunday", 6, weekdays, t, "dispositionColListDesktop") },
        { name: "stp", label: t("disposition_stp"), options: this.dateOptions("stp", "dispositionColListDesktop") },
        { name: "std", label: t("disposition_std"), options: this.dateOptions("std", "dispositionColListDesktop") },
        { name: "sta", label: t("disposition_sta"), options: this.dateOptions("sta", "dispositionColListDesktop") },
        { name: "combination", label: t("disposition_combination"), options: this.standardOptions("combination", t("disposition_combination"), "dispositionColListDesktop") },
        { name: "requirements", label: t("disposition_requirements"), options: this.customLabelOptions("requirements", "disposition_requirement", t, "dispositionColListDesktop") },
        { name: "actions", label: " ", options: this.actionOptions(t) },
      ] :
      [ // reduced mobile view
        { name: "status", label: t("disposition_status"), options: this.activeOptions("dispositionColListMobile") },
        { name: "name", label: t("disposition_tour_nr"), options: this.standardOptions("tour", t("disposition_tour_nr"), "dispositionColListMobile") },
        { name: "client", label: t("disposition_client"), options: this.standardOptions("client", t("disposition_client"), "dispositionColListMobile") },
        { name: "departure", label: t("disposition_departure"), options: this.standardOptions("departure", t("disposition_departure"), "dispositionColListMobile") },
        { name: "arrival", label: t("disposition_arrival"), options: this.standardOptions("arrival", t("disposition_arrival"), "dispositionColListMobile") },
        { name: "monday", label: t("general_weekday_abbr_monday"), options: this.checkedOptions("monday", 0, weekdays, t, "dispositionColListDesktop") },
        { name: "tuesday", label: t("general_weekday_abbr_tuesday"), options: this.checkedOptions("tuesday", 1, weekdays, t, "dispositionColListDesktop") },
        { name: "wednesday", label: t("general_weekday_abbr_wednesday"), options: this.checkedOptions("wednesday", 2, weekdays, t, "dispositionColListDesktop") },
        { name: "thursday", label: t("general_weekday_abbr_thursday"), options: this.checkedOptions("thursday", 3, weekdays, t, "dispositionColListDesktop") },
        { name: "friday", label: t("general_weekday_abbr_friday"), options: this.checkedOptions("friday", 4, weekdays, t, "dispositionColListDesktop") },
        { name: "saturday", label: t("general_weekday_abbr_saturday"), options: this.checkedOptions("saturday", 5, weekdays, t, "dispositionColListDesktop") },
        { name: "sunday", label: t("general_weekday_abbr_sunday"), options: this.checkedOptions("sunday", 6, weekdays, t, "dispositionColListDesktop") },
        { name: "stp", label: t("disposition_stp"), options: this.dateOptions("stp", "dispositionColListMobile") },
        { name: "std", label: t("disposition_std"), options: this.dateOptions("std", "dispositionColListMobile") },
        { name: "sta", label: t("disposition_sta"), options: this.dateOptions("sta", "dispositionColListMobile") },
        { name: "actions", label: " ", options: this.actionOptions(t) },
      ];

    const options = {
      filterType: 'multiselect',
      onFilterChange: (changedColumn, filterList) => {
        let tempfilterList = { ...this.state["filterList"] }
        const index = columns.findIndex(el => el.name === changedColumn)
        tempfilterList[changedColumn] = filterList[index]
        this.setState((prevState, props) => ({
          updateDT: prevState.updateDT + 1,
          filterList: tempfilterList
        }));
        localStorage.setItem("dispositionFilterList", JSON.stringify(tempfilterList))
      },
      onColumnViewChange: (changedColumn, action) => {
        const thelist = tableLayout === "scroll" ? "dispositionColListDesktop" : "dispositionColListMobile"
        let tempColList = { ...this.state[thelist] }
        tempColList[changedColumn] = action === "add" ? true : false
        this.setState({ [thelist]: tempColList })
        localStorage.setItem(thelist, JSON.stringify(tempColList))
      },
      pagination: false,
      elevation: 0,
      print: false,
      download: false,
      selectableRows: false,
      responsive: tableLayout,
      textLabels: {
        body: {
          noMatch: t("dashboard_no_trips_match_criteria"),
          toolTip: t("general_sort"),
        },
        toolbar: {
          search: t("general_search"),
          viewColumns: t("dashboard_select_cols"),
          filterTable: t("dashboard_filter_tab"),
        },
        filter: {
          all: t("dashboard_all_trips"),
          title: t("dashboard_filter"),
          reset: t("dashboard_filter_reset"),
        },
        viewColumns: {
          title: t("dashboard_select_cols"),
          titleAria: t("dashboard_show_hide_cols"),
        },
      }
    };


    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>{t("disposition_disposition")}</h4>
                {localStorage.getItem('role') === "admin" &&
                  <IconButton
                    aria-label="add"
                    style={{padding: 0, color: "white"}}
                    onClick={() => {
                      this.clearData();
                      this.props.history.push(`/admin/editdisposition/:id`)
                    }}
                  >
                    <AddIcon/>
                  </IconButton>
                }
              </CardHeader>
              <CardBody>
                { localStorage.getItem('role') === "admin" &&
                  <>
                    <Tabs
                      value={tabValue}
                      onChange={this.changeTab}
                    >
                      <LinkTab label={t("disposition_copy_weeks")} {...this.a11yProps(COPY_TAB)} />
                      <LinkTab label={t("disposition_delete_weeks")} {...this.a11yProps(DELETE_TAB)} />
                      <LinkTab label={t("disposition_publish_weeks")} {...this.a11yProps(PUBLISH_TAB)} />
                    </Tabs>
                    <TabPanel label={t("disposition_copy_weeks")} value={this.state.tabValue} index={0}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Tags
                            id="actionWeeksCopy"
                            labelField={t("disposition_choose_weeks")}
                            tags={actionWeeksCopy}
                            deleteCallback={(value) => { this.setState({ actionWeeksCopy: value }) }}
                            additionCallback={(value) => { this.setState({ actionWeeksCopy: value }) }}
                            select={true}
                            selectChoices={actionWeeksChoicesCopy}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                          <Button style={{ float: "right" }} color="primary"
                            disabled={actionWeeksCopy.length === 0}
                            onClick={() => {
                              let weeks = actionWeeksCopy.map(week => (
                                {
                                  start_date: week.value.start_date.toISOString().substring(0, 10),
                                  end_date: week.value.end_date.toISOString().substring(0, 10)
                                }
                              ));
                              let requestBody = { weeks: weeks };
                              axios.post(`${api_ipaddress}/api/tours/copy`,
                                requestBody,
                                { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                .then((res) => {
                                  this.showNotification("tc", t(res.data.message));
                                  this.getToursToCopy()
                                  this.setState({ actionWeeksCopy: [] })
                                })
                            }}
                          >{t("disposition_copy")}</Button>
                        </GridItem>
                      </GridContainer>
                    </TabPanel>
                    <TabPanel label={t("disposition_delete_weeks")} value={this.state.tabValue} index={1}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Tags
                            id="actionWeeksDelete"
                            labelField={t("disposition_choose_weeks")}
                            tags={actionWeeksDelete}
                            deleteCallback={(value) => { this.setState({ actionWeeksDelete: value }) }}
                            additionCallback={(value) => { this.setState({ actionWeeksDelete: value }) }}
                            select={true}
                            selectChoices={actionWeeksChoicesDelete}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                          <Button style={{ float: "right" }} color="primary"
                            disabled={actionWeeksDelete.length === 0}
                            onClick={() => {
                              let weeks = actionWeeksDelete.map(week => (
                                {
                                  start_date: week.value.start_date.toISOString().substring(0, 10),
                                  end_date: week.value.end_date.toISOString().substring(0, 10)
                                }
                              ));
                              let requestBody = { weeks: weeks };
                              axios.post(`${api_ipaddress}/api/tours/delete`,
                                requestBody,
                                { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                .then((res) => {
                                  this.showNotification("tc", t(res.data.message));
                                  this.getToursToDelete()
                                  this.setState({ actionWeeksDelete: [] })
                                })
                            }}
                          >{t("disposition_delete")}</Button>
                        </GridItem>
                      </GridContainer>
                    </TabPanel>
                    <TabPanel label={t("disposition_publish_weeks")} value={this.state.tabValue} index={2}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Tags
                            id="actionWeeksPublish"
                            labelField={t("disposition_choose_weeks")}
                            tags={actionWeeksPublish}
                            deleteCallback={(value) => { this.setState({ actionWeeksPublish: value }) }}
                            additionCallback={(value) => { this.setState({ actionWeeksPublish: value }) }}
                            select={true}
                            selectChoices={actionWeeksChoicesPublish}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                          <Button style={{ float: "right" }} color="primary"
                            disabled={actionWeeksPublish.length === 0}
                            onClick={() => {
                              let LANG_NAME_SEPARATOR = "-";

                              let weeks = actionWeeksPublish.map(week => (
                                {
                                  start_date: week.value.start_date.toISOString().substring(0, 10),
                                  end_date: week.value.end_date.toISOString().substring(0, 10)
                                }
                              ));

                              let lang = i18n.language;
                              if (lang.includes(LANG_NAME_SEPARATOR)) {
                                lang = lang.substring(0, lang.indexOf(LANG_NAME_SEPARATOR))
                              }

                              let requestBody = { weeks: weeks, language: lang };
                              axios.post(`${api_ipaddress}/api/tours/publish`,
                                requestBody,
                                { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                .then((res) => {
                                  this.showNotification("tc", t(res.data.message));
                                  this.getToursToPublish()
                                  this.setState({ actionWeeksPublish: [] })
                                })
                            }}
                          >{t("disposition_publish")}</Button>
                        </GridItem>
                      </GridContainer>
                    </TabPanel>
                  </>
                }
                {this.state.tours !== [] &&
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      key={
                        this.state.tableLayout +
                        this.state.updateDT +
                        i18n.language
                      }
                      title={""}
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>}
                {this.state.tours === [] &&
                  <CircularProgress
                    disableShrink
                    style={{
                      color: primaryColor[0],
                      margin: "auto",
                      display: "block"
                    }} />}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message ? this.state.message : ""}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <AlertDialog
          open={this.state.alert}
          onClose={() => this.setState({ alert: false })}
          handleNo={() => this.setState({ alert: false })}
          handleYes={() => {
            axios.delete(`${api_ipaddress}/api/tours/${this.state.clickedDisposition}`,
              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
              .then((res) => {
                axios.get(`${api_ipaddress}/api/tours/all`,
                  { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                  .then(res => {
                    const result = this.updateFloatValues(res)
                    this.clearData();
                    this.setState({
                      tours: result.data,
                      alert: false
                    });
                  });
                this.showNotification("tc", t(res.data.message));
              })
              .catch((err) => {
                this.showNotification("tc", t(err.response.data.message));
              })
          }}
          title={sprintf(t("disposition_delete_warning_title"), this.state.clickedDisposition)}
          content={t("disposition_delete_warning_message")}
        />
      </div>
    );
  }
}

Disposition.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Disposition));