import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomAutoComplete from "components/CustomInput/CustomAutocomplete.jsx";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import analysisAreaStyle from "assets/jss/material-dashboard-react/views/analysisAreaStyle.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import MUIDataTable from "mui-datatables";
import { api_ipaddress } from '../../variables.js'
import axios from 'axios';
import { initlocalStorage } from 'functions/sessionInit.js'
import { withTranslation } from 'react-i18next';
import Button from "../../components/CustomButtons/Button.jsx";
import {floatToText} from "functions/helpers.js";
import _ from 'lodash';

import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import button from "../../components/CustomButtons/Button.jsx";

var moment = require('moment-timezone');
moment.locale("de-ch");


const statusValue = (value, t) => {
  switch (value) {
    case "-":
      return "NONE";
    case `${t("analysisArea_ontime")} (${t("analysisArea_arrived")})`:
      return "ON_TIME_ARRIVED";
    case `${t("analysisArea_ontime")} (${t("analysisArea_in_transit")})`:
      return "ON_TIME_TRANSIT";
    case `${t("analysisArea_marg")} (${t("analysisArea_arrived")})`:
      return "MARG_ARRIVED";
    case `${t("analysisArea_marg")} (${t("analysisArea_in_transit")})`:
      return "MARG_TRANSIT";
    case `${t("analysisArea_delayed")} (${t("analysisArea_arrived")})`:
      return "DELAYED_ARRIVED";
    case `${t("analysisArea_delayed")} (${t("analysisArea_in_transit")})`:
      return "DELAYED_TRANSIT";
    case `${t("analysisArea_ontime")} (${t("analysisArea_departed")})`:
      return "ON_TIME_DEPARTED";
    case `${t("analysisArea_ontime")} (${t("analysisArea_waiting")})`:
      return "ON_TIME_WAITING";
    case `${t("analysisArea_marg")} (${t("analysisArea_departed")})`:
      return "MARG_DEPARTED";
    case `${t("analysisArea_marg")} (${t("analysisArea_waiting")})`:
      return "MARG_WAITING";
    case `${t("analysisArea_delayed")} (${t("analysisArea_departed")})`:
      return "DELAYED_DEPARTED";
    case `${t("analysisArea_delayed")} (${t("analysisArea_waiting")})`:
      return "DELAYED_WAITING";
    default:
      return "";
  }
}

const getDayOfTheWeek = (momentObj, t)  => {
  let dayOfWeek = momentObj.isoWeekday()
  let dayAbbr = ""
  switch (dayOfWeek) {
    case 1:
      dayAbbr = t("general_weekday_abbr_monday")
      break;
    case 2:
      dayAbbr = t("general_weekday_abbr_tuesday")
      break;
    case 3:
      dayAbbr = t("general_weekday_abbr_wednesday")
      break;
    case 4:
      dayAbbr = t("general_weekday_abbr_thursday")
      break;
    case 5:
      dayAbbr = t("general_weekday_abbr_friday")
      break;
    case 6:
      dayAbbr = t("general_weekday_abbr_saturday")
      break;
    case 7:
      dayAbbr = t("general_weekday_abbr_sunday")
      break;
    default:
      dayAbbr = momentObj.format("dd")
      break;
  }
  return dayAbbr;
}

const asTime = (momentObj, t) => {
  try {
    return momentObj.isValid() ? `${momentObj.format("HH:mm")}` : ""
  }
  catch (e) { return "" }
}

class PriceList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
      active:"",
      tableLayout: "scroll",
      tc: false,
      message: ".",
      tabValue: 0,
      openFilter: false,
      updateDT: 1,
      filterList: [],
      priceListColListDesktop: {},
      priceListColListMobile: {},
      loading: true,
      data: [],
      displayedData: null,
      dataPieArrival: null,
      dataPieDeparture: null,
      dataPiePositioning: null,
      c_toll: null,
      c_trip: null,
      customer: null,
      from: null,
      id: null,
      sub_toll: null,
      sub_trip: null,
      subcontractor: null,
      to: null,
      tour_comb: null,
      tour_no: null
    };
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  componentWillMount() {
    initlocalStorage()
  }

  filterData() {
    const { t } = this.props;
    let filter = {};

    const props = [
      "c_toll",
      "c_trip",
      "customer",
      "from",
      "id",
      "sub_toll",
      "sub_trip",
      "subcontractor",
      "to",
      "tour_comb",
      "tour_no",
    ]

    props.forEach(item => {
      if (this.state[item] !== null)
        filter[item] = this.state[item];
    });

    let filteredData = _.filter(this.state.data, filter);
    if(this.state.customer !== null)
      filteredData = filteredData.filter(r => r.customer.includes(this.state.customer));

    this.renderDropdowns(filteredData);
    this.renderTable(filteredData);

    this.setState({ loading: false });
  }

  renderDropdowns(data) {
    const { t } = this.props;

    //build dataset for dropdowns
    this.setState({ dropDataCToll : [...new Set(data.filter(f => f.c_toll != null).map(r => r.c_toll))].sort() });
    this.setState({ dropDataCTrip : [...new Set(data.filter(f => f.c_trip != null).map(r => r.c_trip))].sort() });
    this.setState({ dropDataCustomer : [...new Set(data.filter(f => f.customer != null).map(r => r.customer))].sort() });
    this.setState({ dropDataFrom : [...new Set(data.filter(f => f.from != null).map(r => r.from))].sort() });
    this.setState({ dropDataId : [...new Set(data.filter(f => f.id != null).map(r => r.id))].sort() });
    this.setState({ dropDataSubToll : [...new Set(data.filter(f => f.sub_toll != null).map(r => r.sub_toll))].sort() });
    this.setState({ dropDataSubTrip : [...new Set(data.filter(f => f.sub_trip != null).map(r => r.sub_trip))].sort() });
    this.setState({ dropDataSubcontractor : [...new Set(data.filter(f => f.subcontractor != null).map(r => r.subcontractor))].sort() });
    this.setState({ dropDataTo : [...new Set(data.filter(f => f.to != null).map(r => r.to))].sort() });
    this.setState({ dropDataTourComb : [...new Set(data.filter(f => f.tour_comb != null).map(r => r.tour_comb))].sort() });
    this.setState({ dropDataTourNo : [...new Set(data.filter(f => f.tour_no != null).map(r => r.tour_no))].sort() });
  }
  renderTable(tableData) {
    this.setState({ displayedData: tableData });
  }

  componentDidMount() {
    moment.tz.setDefault("Etc/UTC");

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    this.setState({
      loading: true,
      filterList: JSON.parse(localStorage.getItem("filterList")),
      priceListColListDesktop: JSON.parse(localStorage.getItem("priceListColListDesktop")),
      priceListColListMobile: JSON.parse(localStorage.getItem("priceListColListMobile"))
    }, this.updateData());
  }

  updateData() {

    axios.get(`${api_ipaddress}/api/pricelist/all`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {

        res.data.forEach( (ele) => {
          ele.sta = moment(ele.sta);
          ele.std = moment(ele.std);
          ele.stp = moment(ele.stp);
        })

        this.setState({ data: res.data });
        this.filterData();
        this.setState({ loading: false });
      });

  }

  resize() {
    this.setState({
      tableLayout: window.innerWidth <= 620 ? "stacked" : "scroll",
      windowHeight: window.innerHeight
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableRow: {
        root: {
          height: "24px",
        }
      },
      MuiTableCell: {
        root: this.state.tableLayout === "scroll" ?
          {
            paddingRight: "4px",
            paddingTop: "6px",
            paddingLeft: "4px",
            paddingBottom: "6px",
            whiteSpace: "nowrap",
            maxWidth: "6em",
            overflow: "hidden",
            textOverflow: "ellipsis"
          } :
          {
            paddingTop: "4px",
            paddingBottom: "1px",
          }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: window.innerWidth <= 1280 ?
            `${this.state.windowHeight - 560}px !important` :
            `${this.state.windowHeight - 520}px !important`,
          minHeight: "300px",
        },
      },
    }
  })

  buildRequest = () => {
    const { t } = this.props;

    let positioning = (this.state.tt_p !== null) ? statusValue(this.state.tt_p, t) : undefined;
    let departure = (this.state.tt_d !== null) ? statusValue(this.state.tt_d, t) : undefined;
    let arrival = (this.state.tt_a !== null) ? statusValue(this.state.tt_a, t) : undefined;

    return {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      tt_sub: (this.state.tt_sub !== null) ? this.state.tt_sub : undefined,
      tt_customer: (this.state.tt_customer !== null) ? this.state.tt_customer : undefined,
      tt_tour: (this.state.tt_tour !== null) ? this.state.tt_tour : undefined,
      tt_gps: (this.state.tt_gps !== null) ? this.state.tt_gps : undefined,
      tt_driver: (this.state.tt_driver !== null) ? this.state.tt_driver : undefined,
      tt_status: (this.state.tt_status !== null) ? this.state.tt_status : undefined,
      tt_from_text: (this.state.tt_from_text !== null) ? this.state.tt_from_text : undefined,
      tt_to_text: (this.state.tt_to_text !== null) ? this.state.tt_to_text : undefined,
      positioning: positioning,
      depature: departure,
      arrival: arrival
    };
  }

  exportExcel = () => {
    axios.get(`${api_ipaddress}/api/pricelist/pricelistdownload`,
      {
        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
        responseType: 'blob'
      }).then((response) => {

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'pricelist_export.xlsx');
      document.body.appendChild(link);
      link.click();
      return false;
    })
      .catch((err) => {
        this.showNotification("tc", "Leider gab es ein Problem");
        return false;
      })
  }

  textColor = (value, color) => {
    return color &&
      <span style={{ "color": color }} title={value}>{value}</span>
  }

  actionCell = (value, t, id) => {
    let eleId = this.state.data.filter(x => x.tour_no === value)
    return <span style={{cursor: "pointer", color: "black", textDecoration: "underline"}} onClick={() => this.props.history.push(`/admin/editdisposition/${eleId[0].id}`)}>{value}</span>
  }

  //
  // column specifications
  // ---------------------


  standardOptions = (name, colList, sortable = true, color = "inherit") => {
    return {
      sort: sortable,
      customBodyRender: (value) => this.textColor(value, color),
      display: this.state[colList][name]
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  };

  currencyOptions = (name, colList, t) => {
    return {
      filter: true, sort: true,
      customBodyRender: (value) => `${floatToText(value)} ${t('currency_symbol')}`,
      display: this.state[colList][name]
    }
  }

  activeOptions = (name, value, colList) => {
    return {
      filter: false, searchable: false, sort: false,
      customBodyRender: (value) => {
        if(value){
          return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
        }else{
          return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "red" }} />
        }
      },
      display: this.state[colList][name]
    }
  }

  timeOptions = (name, label, colList, t) => {
    return {
      filter: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (momentObj) => asTime(momentObj, t),
      display: this.state[colList][name]
    }
  }

  actionOptions = (name, colList, t) => {
    return {
      sort: true,
      customBodyRender: (value) => this.actionCell(value, t),
      display: this.state[colList][name]
    }
  }

  render() {
    const { classes } = this.props;
    const { tableLayout } = this.state;
    const { t, i18n } = this.props;

    const columns = tableLayout === "scroll" ?
      [ // full desktop view
        { name: "status", label: t("priceList_status"), options: this.activeOptions("status", "status","priceListColListDesktop", t) },
        { name: "tour_no", label: t("priceList_tour_no"), options: this.actionOptions("tour_no", "priceListColListDesktop", t) },
        { name: "from", label: t("priceList_from"), options: this.standardOptions("from", "priceListColListDesktop") },
        { name: "to", label: t("priceList_to"), options: this.standardOptions("to", "priceListColListDesktop") },
        { name: "tour_comb", label: t("priceList_tour_combination"), options: this.standardOptions("tour_comb", "priceListColListDesktop") },
        { name: "customer", label: t("priceList_customer"), options: this.standardOptions("customer", "priceListColListDesktop") },
        { name: "subcontractor", label: t("priceList_subcontractor"), options: this.standardOptions("subcontractor", "priceListColListDesktop") },
        { name: "c_trip", label: t("priceList_price_customer"), options: this.currencyOptions("c_trip", "priceListColListDesktop", t) },
        { name: "c_toll", label: t("priceList_toll_customer"), options: this.currencyOptions("c_toll", "priceListColListDesktop", t) },
        { name: "sub_trip", label: t("priceList_price_subcontractor"), options: this.currencyOptions("sub_trip", "priceListColListDesktop", t) },
        { name: "sub_toll", label: t("priceList_toll_subcontractor"), options: this.currencyOptions("sub_toll", "priceListColListDesktop", t) },
        { name: "stp", label: t("priceList_stp"), options: this.timeOptions("stp", "STP", "priceListColListDesktop", t) },
        { name: "std", label: t("priceList_std"), options: this.timeOptions("std", "STD", "priceListColListDesktop", t) },
        { name: "sta", label: t("priceList_sta"), options: this.timeOptions("sta", "STA", "priceListColListDesktop", t) },
      ] :
      [ // reduced mobile view
        { name: "tour_no", label: t("analysisArea_tour_nr"), options: this.standardOptions("tour_no", "priceListColListMobile") },
        { name: "from", label: t("general_from"), options: this.standardOptions("from", "priceListColListMobile") },
        { name: "to", label: t("general_to"), options: this.standardOptions("to", "priceListColListMobile") },
        { name: "tour_comb", label: t("general_tour_combination"), options: this.standardOptions("tour_comb", "priceListColListMobile") },
        { name: "customer", label: t("customer"), options: this.standardOptions("customer", "priceListColListMobile") },
        { name: "subcontractor", label: t("subcontractor"), options: this.standardOptions("subcontractor", "priceListColListMobile") },
        { name: "c_trip", label: t("price_customer"), options: this.currencyOptions("c_trip", "priceListColListMobile", t) },
        { name: "c_toll", label: t("toll_customer"), options: this.currencyOptions("c_toll", "priceListColListMobile", t) },
        { name: "sub_trip", label: t("price_subcontractor"), options: this.currencyOptions("sub_trip", "priceListColListMobile", t) },
        { name: "sub_toll", label: t("toll_subcontractor"), options: this.currencyOptions("sub_toll", "priceListColListMobile", t) },
        { name: "status", label: t("priceList_status"), options: this.activeOptions("status", "priceListColListMobile", t) },
        { name: "stp", label: t("priceList_stp"), options: this.timeOptions("stp", "STP", "priceListColListMobile", t) },
        { name: "std", label: t("priceList_std"), options: this.timeOptions("std", "STD", "priceListColListMobile", t) },
        { name: "sta", label: t("priceList_sta"), options: this.timeOptions("sta", "STA", "priceListColListMobile", t) },
      ];

    const options = {
      pagination: false,
      elevation: 0,
      print: false,
      download: true,
      selectableRows: false,
      responsive: tableLayout,
      customSearch: {},
      filter: false,
      search: false,
      viewColumns: true,
      onColumnViewChange: (changedColumn, action) => {
        const thelist = tableLayout === "scroll" ? "priceListColListDesktop" : "priceListColListMobile"
        let tempColList = { ...this.state[thelist] }
        tempColList[changedColumn] = action === "add" ? true : false
        this.setState({ [thelist]: tempColList })
        localStorage.setItem(thelist, JSON.stringify(tempColList))
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        try {
          this.exportExcel();
        }catch (e){
          console.warn(e)
          return false
        }
        return false
      },
      textLabels: {
        body: {
          noMatch: t("analysisArea_no_trips_match_criteria"),
          toolTip: t("general_sort"),
        },
        toolbar: {
          search: t("general_search"),
          viewColumns: t("analysisArea_select_cols"),
          filterTable: t("analysisArea_filter_tab"),
          downloadCsv: "Download",
        },
        filter: {
          all: t("analysisArea_all_trips"),
          title: t("analysisArea_filter"),
          reset: t("analysisArea_filter_reset"),
        },
        viewColumns: {
          title: t("analysisArea_select_cols"),
          titleAria: t("analysisArea_show_hide_cols"),
        },
      }
    };

    const buildDropdown = (prop) => {
      return prop in this.state
        ? this.state[prop].map(item => ({ value: item, label: item }))
        : [];
    }

    const cTollDropdown = buildDropdown("dropDataCToll");
    const cTripDropdown = buildDropdown("dropDataCTrip");
    const customerDropdown = buildDropdown("dropDataCustomer");
    const fromDropdown = buildDropdown("dropDataFrom");
    const idDropdown = buildDropdown("dropDataId");
    const subTollDropdown = buildDropdown("dropDataSubToll");
    const subTripDropdown = buildDropdown("dropDataSubTrip");
    const subcontractorDropdown = buildDropdown("dropDataSubcontractor");
    const toDropdown = buildDropdown("dropDataTo");
    const tourCombDropdown = buildDropdown("dropDataTourComb");
    const tourNoDropdown = buildDropdown("dropDataTourNo");

    const clearFilters = () => {
      this.setState({
        c_toll: null,
        c_trip: null,
        customer: null,
        from: null,
        id: null,
        sub_toll: null,
        sub_trip: null,
        subcontractor: null,
        to: null,
        tour_comb: null,
        tour_no: null
      }, () => {
          this.filterData();
      })
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("price_list_title")}</h4>
              </CardHeader>
              <CardBody>
                {/* START FILTERS*/}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Box className={classes.floatRight}>
                      <Button onClick={clearFilters} color="primary">
                        {t("analysisArea_clearFilters")}
                      </Button>
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                        labelText={"priceList_tour_no"}
                        id="tour"
                        value={tourNoDropdown.filter(el => el.value === this.state.tour_no)}
                        onChange={value => {
                          this.setState({ loading: true, tour_no: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={tourNoDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                        labelText={"priceList_from"}
                        id="from"
                        value={fromDropdown.filter(el => el.value === this.state.from)}
                        onChange={value => {
                          this.setState({ loading: true, from: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={fromDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                        labelText={"priceList_to"}
                        id="to"
                        value={toDropdown.filter(el => el.value === this.state.to)}
                        onChange={value => {
                          this.setState({ loading: true, to: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={toDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                        labelText={"priceList_tour_combination"}
                        id="subcontractor"
                        value={tourCombDropdown.filter(el => el.value === this.state.tour_comb)}
                        onChange={value => {
                          this.setState({ loading: true, tour_comb: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={tourCombDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                        labelText={"priceList_customer"}
                        id="customer"
                        value={customerDropdown.filter(el => el.value === this.state.customer)}
                        onChange={value => {
                          this.setState({ loading: true, customer: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={customerDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                        labelText={"priceList_subcontractor"}
                        id="subcontractor"
                        value={subcontractorDropdown.filter(el => el.value === this.state.subcontractor)}
                        onChange={value => {
                          this.setState({ loading: true, subcontractor: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={subcontractorDropdown}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomAutoComplete
                        labelText={"priceList_price_customer"}
                        id="c_trip"
                        value={cTripDropdown.filter(el => el.value === this.state.c_trip)}
                        onChange={value => {
                          this.setState({ loading: true, c_trip: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={cTripDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomAutoComplete
                        labelText={"priceList_toll_customer"}
                        id="c_toll"
                        value={cTollDropdown.filter(el => el.value === this.state.c_toll)}
                        onChange={value => {
                          this.setState({ loading: true, c_toll: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={cTollDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomAutoComplete
                        labelText={"priceList_price_subcontractor"}
                        id="sub_trip"
                        value={subTripDropdown.filter(el => el.value === this.state.sub_trip)}
                        onChange={value => {
                          this.setState({ loading: true, sub_trip: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={subTripDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomAutoComplete
                        labelText={"priceList_toll_subcontractor"}
                        id="sub_toll"
                        value={subTollDropdown.filter(el => el.value === this.state.sub_toll)}
                        onChange={value => {
                          this.setState({ loading: true, sub_toll: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={subTollDropdown}
                    />
                  </GridItem>
                </GridContainer>
                  {/* END FILTERS */}
                {!this.state.loading &&
                  <Box>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            key={
                              this.state.tableLayout +
                              this.state.updateDT +
                              i18n.language
                            }
                            title={""}
                            data={this.state.displayedData}
                            columns={columns}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </GridItem>
                    </GridContainer>
                  </Box>
                }
                {this.state.loading &&
                  <CircularProgress disableShrink className={classes.circularProgress} />
                }
              </CardBody>
            </Card>
            <Snackbar
              place="tc" //topcenter
              color="primary"
              message={this.state.message ? this.state.message : ""}
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </GridItem>
        </GridContainer>
      </div >
    );
  }
}

PriceList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(analysisAreaStyle)(PriceList));
