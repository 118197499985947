import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import ReactCountryFlag from "react-country-flag";
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

var moment = require('moment');

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, color, logo, image, logoText, routes } = props;
  const { t, i18n } = useTranslation();
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;

        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path)
        });
        
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
              )}
              <ListItemText
                primary={t(prop.name)}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      <ListItem button className={classes.itemLink}>
        {["de", "en", "fr", "it", "nl"].map((lang) =>
          <IconButton
            key={`key_${lang}`}
            aria-label={lang}
            style={{ padding: 0, marginRight: 10 }}
            onClick={() => {
              i18n.changeLanguage(lang);
              moment.locale(lang === "de" ? "de-ch" : lang);
            }}
          >
            <ReactCountryFlag
              styleProps={{ width: '20px', height: '20px' }}
              // because en is not a country change to gb for great britain
              code={lang === "en" ? "gb" : lang}
              svg />
          </IconButton>
        )}
      </ListItem>
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        target="_blank" rel="noopener noreferrer"
        href="http://gintransport.com/"
        className={classNames(classes.logoLink)}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + image + ")" }}
          />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + image + ")" }}
          />
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
