import moment from "moment-timezone";
import { react_app_version } from '../variables.js'

export function checkReactVersion(){
  const APP_VERSION = react_app_version;

  if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
    localStorage.setItem('APP_VERSION', APP_VERSION);
  }

  if (localStorage.APP_VERSION != APP_VERSION) {
    localStorage.clear();
  }
}
export function initlocalStorage() {
  if (localStorage.getItem("filterList") == null) {
    localStorage.setItem("filterList",
      JSON.stringify({
        "tour": [],
        "from": [],
        "to": [],
        "gps": [],
        "status": [],
        "pos": [],
        "dep": [],
        "arr": [],
        "customer": [],
        "sub": [],
        "driver": [],
        "date":[]
      })
    )
  }
  if (localStorage.getItem("colListDesktop") == null) {
    localStorage.setItem("colListDesktop",
      JSON.stringify({
        "id": false,
        "active": true,
        "tour": true,
        "from": true,
        "to": true,
        "date": true,
        "status": true,
        "pos": true,
        "pos_delay": false,
        "stp": true,
        "etp": true,
        "dep": true,
        "dep_delay": false,
        "std": true,
        "atd": true,
        "arr": true,
        "arr_delay": false,
        "sta": true,
        "eta": true,
        "gps": true,
        "driver": true,
        "note": true,
        "info": true,
        "sub": true,
        "customer": true,
      })
    )
  }
  if (localStorage.getItem("colListMobile") == null) {
    localStorage.setItem("colListMobile",
      JSON.stringify({
        "id": false,
        "active": true,
        "tour": true,
        "from": false,
        "to": false,
        "overview": true,
        "stp": false,
        "etp": false,
        "std": false,
        "atd": false,
        "sta": false,
        "eta": false,
        "gps": true,
        "note": true,
        "info": true,
        "sub": true,
        "customer": true,
      })
    )
  }
  if (localStorage.getItem("panicbutton") == null) {
    localStorage.setItem("panicbutton", JSON.stringify(false))
  }
  if (localStorage.getItem("dashboard_view") == null) {
    localStorage.setItem("dashboard_view", "today")
  }

  if (localStorage.getItem("dispositionFilterList") == null) {
    localStorage.setItem("dispositionFilterList",
      JSON.stringify({
        "status": [],
        "name": [],
        "departure": [],
        "arrival": [],
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": [],
        "sunday": [],
        "stp": [],
        "std": [],
        "sta": [],
        "client": [],
        "combination": [],
        "requirements": [],
      })
    )
  }

  if (localStorage.getItem("dispositionColListDesktop") == null) {
    localStorage.setItem("dispositionColListDesktop",
      JSON.stringify({
        "status": true,
        "name": true,
        "departure": true,
        "arrival": true,
        "monday": true,
        "tuesday": true,
        "wednesday": true,
        "thursday": true,
        "friday": true,
        "saturday": true,
        "sunday": true,
        "stp": true,
        "std": true,
        "sta": true,
        "client": true,
        "combination": true,
        "requirements": true
      })
    )
  }
  if (localStorage.getItem("dispositionColListMobile") == null) {
    localStorage.setItem("dispositionColListMobile",
      JSON.stringify({
        "status": true,
        "name": true,
        "departure": true,
        "arrival": true,
        "monday": true,
        "tuesday": true,
        "wednesday": true,
        "thursday": true,
        "friday": true,
        "saturday": true,
        "sunday": true,
        "stp": true,
        "std": true,
        "sta": true,
        "client": true,
        "combination": false,
        "requirements": false
      })
    )
  }
  const previousWeek = moment().week() - 1;
  if (localStorage.getItem("customerJournalFilterList") == null) {
    localStorage.setItem("customerJournalFilterList",
      JSON.stringify({
        "isChecked": true,
        "customer": null,
        "dateFrom": moment().week(previousWeek).weekday(0),
        "dateTo": moment().week(previousWeek).weekday(6),
        "weekFrom": null,
        "weekTo": null,
        "invoiceList": [],
        "calendarList": [],
        "page": 0,
        "rowsPerPage": 10,
      })
    )
  }
  if (localStorage.getItem("subcontractorJournalFilterList") == null) {
    localStorage.setItem("subcontractorJournalFilterList",
      JSON.stringify({
        "isChecked": true,
        "subcontractor": null,
        "dateFrom": moment().week(previousWeek).weekday(0),
        "dateTo": moment().week(previousWeek).weekday(6),
        "weekFrom": null,
        "weekTo": null,
        "invoiceList": [],
        "calendarList": [],
        "page": 0,
        "rowsPerPage": 10,
      })
    )
  }
  if (localStorage.getItem("customerJournalColListDesktop") == null) {
    localStorage.setItem("customerJournalColListDesktop",
      JSON.stringify({
        "id": false,
        "invoice": true,
        "active": true,
        "tour": true,
        "from": true,
        "to": true,
        "date": true,
        "status": true,
        "pos": false,
        "stp": true,
        "etp": false,
        "dep": false,
        "std": true,
        "atd": false,
        "arr": false,
        "sta": true,
        "eta": false,
        "gps": true,
        "driver": false,
        "customer_invoice_trip": true,
        "customer_invoice_toll": true,
        "customer_invoice_waiting_fee": true,
        "customer_invoice_cancellation_fee": true,
        "sub_invoice_trip": false,
        "sub_invoice_toll": false,
        "sub_invoice_waiting_fee": false,
        "sub_invoice_cancellation_fee": false,
        "sub_invoice_comment": true,
        "customer_invoice_text": true,
        "info": true,
        "note": true,
      })
    )
  }
  if (localStorage.getItem("customerJournalColListMobile") == null) {
    localStorage.setItem("customerJournalColListMobile",
      JSON.stringify({
        "id": false,
        "invoice": true,
        "active": true,
        "tour": true,
        "from": true,
        "to": true,
        "date": true,
        "status": true,
        "pos": false,
        "stp": true,
        "etp": false,
        "dep": false,
        "std": true,
        "atd": false,
        "arr": false,
        "sta": true,
        "eta": false,
        "gps": true,
        "driver": false,
        "customer_invoice_trip": true,
        "customer_invoice_toll": true,
        "customer_invoice_waiting_fee": true,
        "customer_invoice_cancellation_fee": true,
        "sub_invoice_trip": false,
        "sub_invoice_toll": false,
        "sub_invoice_waiting_fee": false,
        "sub_invoice_cancellation_fee": false,
        "sub_invoice_comment": true,
        "customer_invoice_text": true,
        "info": true,
        "note": true,
      })
    )
  }
  if (localStorage.getItem("subcontractorJournalColListDesktop") == null) {
    localStorage.setItem("subcontractorJournalColListDesktop",
      JSON.stringify({
        "id": false,
        "active": true,
        "tour": true,
        "sub_invoice_tour_combination": true,
        "from": true,
        "to": true,
        "date": true,
        "status": false,
        "pos": false,
        "stp": true,
        "etp": false,
        "dep": false,
        "std": true,
        "atd": false,
        "arr": false,
        "sta": true,
        "eta": false,
        "gps": true,
        "driver": false,
        "customer_invoice_trip": false,
        "customer_invoice_toll": false,
        "customer_invoice_waiting_fee": false,
        "customer_invoice_cancellation_fee": false,
        "sub_invoice_trip": true,
        "sub_invoice_toll": true,
        "sub_invoice_waiting_fee": true,
        "sub_invoice_cancellation_fee": true,
        "sub_invoice_comment": true,
        "customer_invoice_text": false,
        "info": true,
        "note": true
      })
    )
  }
  if (localStorage.getItem("subcontractorJournalColListMobile") == null) {
    localStorage.setItem("subcontractorJournalColListMobile",
      JSON.stringify({
        "id": false,
        "active": true,
        "tour": true,
        "sub_invoice_tour_combination": true,
        "from": true,
        "to": true,
        "date": true,
        "status": false,
        "pos": false,
        "stp": true,
        "etp": false,
        "dep": false,
        "std": true,
        "atd": false,
        "arr": false,
        "sta": true,
        "eta": false,
        "gps": true,
        "driver": false,
        "customer_invoice_trip": false,
        "customer_invoice_toll": false,
        "customer_invoice_waiting_fee": false,
        "customer_invoice_cancellation_fee": false,
        "sub_invoice_trip": true,
        "sub_invoice_toll": true,
        "sub_invoice_waiting_fee": true,
        "sub_invoice_cancellation_fee": true,
        "sub_invoice_comment": true,
        "customer_invoice_text": false,
        "info": true,
        "note": true
      })
    )
  }
  if (localStorage.getItem("priceListColListDesktop") == null) {
    localStorage.setItem("priceListColListDesktop",
      JSON.stringify({
        "tour_no": true,
        "from": true,
        "to": true,
        "tour_comb": true,
        "customer": true,
        "subcontractor": true,
        "c_trip": true,
        "c_toll": true,
        "sub_trip": true,
        "sub_toll": true,
        "status": false,
        "stp": false,
        "std": false,
        "sta": false
      })
    )
  }
  if (localStorage.getItem("priceListColListMobile") == null) {
    localStorage.setItem("priceListColListMobile",
      JSON.stringify({
        "tour_no": true,
        "from": true,
        "to": true,
        "tour_comb": true,
        "customer": true,
        "subcontractor": true,
        "c_trip": true,
        "c_toll": true,
        "sub_trip": true,
        "sub_toll": true,
        "status": false,
        "stp": false,
        "std": false,
        "sta": false
      })
    )
  }
  if (localStorage.getItem("alertListColListDesktop") == null) {
    localStorage.setItem("alertListColListDesktop",
      JSON.stringify({
        "al_active": true,
        "al_type": true,
        "al_customer": true,
        "al_sub": true,
        "al_adrnr_arr": true,
        "al_email_arr": true,
        "al_sms_arr": true,
        "al_lang": true
      })
    )
  }
  if (localStorage.getItem("alertListColListMobile") == null) {
    localStorage.setItem("alertListColListMobile",
      JSON.stringify({
        "al_active": true,
        "al_type": true,
        "al_customer": true,
        "al_sub": true,
        "al_adrnr_arr": true,
        "al_email_arr": true,
        "al_sms_arr": true,
        "al_lang": true
      })
    )
  }
  if (localStorage.getItem("analysisColListDesktop") == null) {
    localStorage.setItem("analysisColListDesktop",
      JSON.stringify({
        "id": false,
        "active": true,
        "tour": true,
        "from": true,
        "to": true,
        "tour_combination": true,
        "pos": true,
        "stp": true,
        "etp": true,
        "pos_delay": true,
        "dep": true,
        "std": true,
        "atd": true,
        "dep_delay": true,
        "arr": true,
        "sta": true,
        "eta": true,
        "arr_delay": true,
        "gps": true,
        "sub": true,
        "driver": true,
        "customer": true,
        "c_trip": false,
        "c_toll": false,
        "c_waiting": false,
        "c_cnl": false,
        "s_trip": false,
        "s_toll": false,
        "s_waiting": false,
        "s_cnl": false,
        "info": true,
        "note": true,
      })
    )
  }
  if (localStorage.getItem("analysisColListMobile") == null) {
    localStorage.setItem("analysisColListMobile",
      JSON.stringify({
        "id": true,
        "active": true,
        "tour": true,
        "from": true,
        "to": true,
        "overview": true,
        "stp": true,
        "etp": true,
        "std": true,
        "atd": true,
        "sta": true,
        "eta": true,
        "gps": true,
        "sub": true,
        "driver": true,
      })
    )
  }
}