import {
} from "assets/jss/material-dashboard-react.jsx";

//TODO: improve style of the button
const toolbarButtonStyle = {
  IconButton: {
    color: "gray",
    "&:hover": {
      color: "#3f51b5"
    }
  },
};

export default toolbarButtonStyle;
