import React from 'react';
import Button from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';


function AlertDialog({ ...props }) {

  const { t } = useTranslation();

  return (
      <Dialog
        open={props.open}
        onClose={props.handleClose}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{"whiteSpace": "pre"}}>
            {props.content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleNo} color="primary">
            {t("general_no")}
            </Button>
          <Button onClick={props.handleYes} color="primary" autoFocus>
            {t("general_yes")}
            </Button>
        </DialogActions>
      </Dialog>
  );
}

export default AlertDialog;