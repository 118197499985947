/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";

// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";
import withAuth from "layouts/withAuth.jsx"

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-gin.jpg";
import logo from "assets/img/gin_express_logo.png";

import { withTranslation } from 'react-i18next';
import {checkReactVersion} from "../functions/sessionInit";
import 'dotenv/config'

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "primary",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  resizeFunction = () => {
    if (window.innerWidth >= 1280) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    checkReactVersion()
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }


  switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          const Component = prop.path === "/login" ? prop.component : withAuth(prop.component)
          return (
            <Route
              path={prop.layout + prop.path}
              component={Component}
              key={key}
            />
          );
        }
      })}
    </Switch>
  );

  render() {
    const { classes, ...rest } = this.props;
    const { t } = this.props;

    const authenticated = localStorage.getItem('access_token') !== null
    const role = localStorage.getItem('role')

    let routesVisible =
      authenticated ?
        routes :
        routes.filter(x => x.path == "/login")

    if(role === "admin"){
      routesVisible = routesVisible.filter(x =>
        x.path !== "/editdisposition/" &&
        x.path !== "/editdisposition/:id"
      )
    }

    if(role !== "admin"){
      routesVisible = routesVisible.filter(x =>
        x.path !== "/editdisposition/" &&
        x.path !== "/editdisposition/:id"
      )
    }
        
    if (role !== "admin") {
      routesVisible = routesVisible.filter(x =>
        x.path !== "/upload" &&
        x.path !== "/users" &&
        x.path !== "/report" &&
        x.path !== "/alerts" &&
        x.path !== "/analysisArea" &&
        x.path !== "/customers" &&
        x.path !== "/customerjournal" &&
        x.path !== "/pricelist"
      )
    }

    if (role !== "admin" && role !== "sub") {
      routesVisible = routesVisible.filter(x =>
        x.path !== "/disposition"
      )
    }

    if (role !== "admin" && role !== "sub") {
      routesVisible = routesVisible.filter(x =>
        x.path !== "/subcontractorjournal"
      )
    }

    if (role === "customer" || role === "observer") {
      routesVisible = routesVisible.filter(x => x.path !== "/edit/:id")
    }

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routesVisible}
          logoText={"TruckWatch"}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div id="mainPanel" className={classes.mainPanel} ref="mainPanel">
          <Hidden lgUp implementation="css">
            <Navbar
              routes={routesVisible}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
          </Hidden>
          <div className={classes.content}>
            <div className={classes.container}>{this.switchRoutes}</div>
          </div>
        </div>
      </div>
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(dashboardStyle)(Admin));
