import {
} from "assets/jss/material-dashboard-react.jsx";

const linkTabStyle = {
  root: {
    backgroundColor: '#fff',
    color: '#c20e1a',
    width: "10em",
    maxWidth: "19em",
    opacity:1,
    fontFamily: [
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: "#c13c44",
      color: '#fff',
    },
    '&$selected': {
      backgroundColor: "#fff",
      color: '#c20e1a',
    },
    '&:focus': {
      backgroundColor: "#fff",
      color: '#c20e1a',
    }
  },
  selected: {
    backgroundColor: "#fff",
    color: '#c20e1a',
  },
  indicator: {
    backgroundColor: '#fff',
  }
};

export default linkTabStyle;
