import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import CustomInput from "../CustomInput/CustomInput";

class CustomerList extends React.Component {
    render() {
        const { customer, onChangeCustomer } = this.props;
        const references = [...Array(5).keys()];
        return (<>
            <span>Generic references</span>
            {
                customer && references.map((ref) =>
                    <CustomInput
                        labelText={`Reference ${ref + 1}`}
                        id={`reference_${ref + 1}`}
                        key={`reference_${ref + 1}`}
                        inputProps={{
                            value: customer.hasOwnProperty(`cm_reference_${ref + 1}`) ? customer[`cm_reference_${ref + 1}`] : "",
                            onChange: (event) => { onChangeCustomer(ref, event.target.value) },
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                )
            }
        </>);
    }
}

CustomerList.propTypes = {
    classes: PropTypes.object.isRequired,
    tasksIndexes: PropTypes.arrayOf(PropTypes.number),
    tasks: PropTypes.arrayOf(PropTypes.node),
    rtlActive: PropTypes.bool
};

export default withStyles(tasksStyle)(CustomerList);
