// withAuth.jsx

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { api_ipaddress } from '../variables.js'
import axios from 'axios'

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    componentDidMount() {
      const currentTime = new Date();
      const loginTimeout = new Date(localStorage.getItem('login_timeout'));
      if (
        localStorage.getItem('login_timeout') != null &&
        loginTimeout.getTime() < currentTime.getTime()
      ) {
        this.setState({ loading: false, redirect: true });
      } else {
        axios.get(api_ipaddress + '/api/auth',
          { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
          .then(res => {
            if (res.status === 200 && "username" in res.data) {
              this.setState({ loading: false });
              if (localStorage.getItem('login_timeout') != null) {
                var loginTimeout = new Date();
                loginTimeout.setHours(loginTimeout.getHours() + 1);
                localStorage.setItem('login_timeout', loginTimeout);
              }
            } else {
              this.setState({ loading: false, redirect: true });
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({ loading: false, redirect: true });
          });
      }
    }
    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        return <Redirect to="/login" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
  }
}