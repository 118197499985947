import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";

import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

import { useTranslation } from 'react-i18next';

function CustomTime({ ...props }) {
  const {
    classes,
    formControlProps,
    clearable,
    inputProps,
  } = props;
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}
      >
        <KeyboardTimePicker
          className={classes.underline}
          {...inputProps}
          autoOk
          {...clearable}
          fullWidth
          ampm={false}
          variant="inline"
          format="HH:mm"
          allowKeyboardControl={true}
          invalidDateMessage={t("general_time_invalid")}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
}

CustomTime.propTypes = {
  classes: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
};

export default withStyles(customInputStyle)(CustomTime);