import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import {floatToText, textToFloat} from "functions/helpers.js";


class Counters extends React.Component {

    render() {
        const { labels, invoiceList, data, properties, sumIsChecked } = this.props

        let sumTrip = 0
        let sumToll = 0
        let sumCNL = 0
        let sumWaiting = 0

        data.forEach( (ele) => {
            if(sumIsChecked){
                if (ele.tt_invoice_check) {
                    sumTrip += textToFloat(ele[properties.trip]);
                    sumToll += textToFloat(ele[properties.toll]);
                    sumCNL += textToFloat(ele[properties.cancellation_fee]);
                    sumWaiting += textToFloat(ele[properties.waiting_fee]);
                }
            }else{
                sumTrip += textToFloat(ele[properties.trip]);
                sumToll += textToFloat(ele[properties.toll]);
                sumCNL += textToFloat(ele[properties.cancellation_fee]);
                sumWaiting += textToFloat(ele[properties.waiting_fee]);
            }

        });



        let invoiceListTotal = 0
        invoiceList.forEach( (ele) => {
            invoiceListTotal += ele.extra_cost;
        });

        let total = sumTrip + sumToll + sumCNL + sumWaiting + parseFloat(invoiceListTotal)

        return (
            data.length > 0 && <>
                <div style={{'margin': '20px 0', 'display':'flex', 'gap': '10px 20px'}}>

                    <div>
                        <span style={{'marginRight': '0.5rem'}}>{labels.Title !== undefined ? labels.Title : null}</span>
                    </div>
                    <div>
                        <span style={{'marginRight': '0.5rem'}}>{labels.Total}</span>
                        <span>€{floatToText(total)}</span>
                    </div>
                    <div>
                        <span style={{'marginRight': '0.5rem'}}>{labels.Trip}</span>
                        <span>€{floatToText(sumTrip)}</span>
                    </div>
                    <div>
                        <span style={{'marginRight': '0.5rem'}}>{labels.Toll}</span>
                        <span>€{floatToText(sumToll)}</span>
                    </div>
                    <div>
                        <span style={{'marginRight': '0.5rem'}}>{labels.CNL}</span>
                        <span>€{floatToText(sumCNL)}</span>
                    </div>
                    <div>
                        <span style={{'marginRight': '0.5rem'}}>{labels.Waiting}</span>
                        <span>€{floatToText(sumWaiting)}</span>
                    </div>
                </div>
            </>
        );
    }
}

export default withStyles(customInputStyle)(Counters);
