import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomDate from "../../components/CustomInput/CustomDate";
import CustomCurrencyInput from "components/CustomInput/CustomCurrencyInput.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Item extends React.Component {
    render() {
        const { t, key, idx, startDate, endDate, date, fuel, fuel_error, extra_cost, extra_cost_error, invoice_text, removeItem, onChangeItem } = this.props;

        const rangeDates = startDate && endDate ? { minDate: startDate, maxDate: endDate} : {}
        return <GridContainer>
            <GridItem xs={2} sm={2} md={3} >
                <CustomDate
                    inputProps={{
                        id: `date_${key}`,
                        label: "Date",
                        value: date,
                        onChange: (date) => {
                            onChangeItem(idx, 'date', date)
                        },
                        onClear: () => this.setState({ date: null }),
                        required: true,
                        ...rangeDates
                    }}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
            </GridItem>
            <GridItem xs={2} sm={2} md={2} >
                <CustomCurrencyInput
                    id={`fuel_${key}`}
                    labelText="Fuel Charge"
                    currencySymbol={t("percentage_symbol")}
                    validateOnChange= {
                        (val, error) => onChangeItem(idx, 'fuel', val, error)
                    }
                    inputProps={{
                        value: fuel,
                        style:{marginTop:"44px"},
                    }}
                    labelProps={{
                        style:{marginTop:"27px"},
                    }}
                    helperText={t("item_fuel_error")}
                    error={fuel_error}
                    required={true}
                    formControlProps={{
                        fullWidth: true,
                    }}
                />
            </GridItem>
            <GridItem xs={2} sm={2} md={2} >
                <CustomCurrencyInput
                    id={`extra_cost_${key}`}
                    labelText="Price"
                    currencySymbol={t("currency_symbol")}
                    validateOnChange= {(val, error) => onChangeItem(idx, 'extra_cost', val, error)}
                    inputProps={{
                        value: extra_cost,
                        style:{marginTop:"44px"},
                    }}
                    labelProps={{
                        style:{marginTop:"27px"},
                    }}
                    helperText={t("item_extra_cost_error")}
                    error={extra_cost_error}
                    required={true}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
            </GridItem>
            <GridItem xs={2} sm={2} md={4} >
                <CustomInput
                    id={`invoice_text_${key}`}
                    labelText="Invoice Text"
                    inputProps={{
                        multiline: true,
                        value: invoice_text,
                        onChange: (event) => onChangeItem(idx, 'invoice_text', event.target.value),
                    }}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
            </GridItem>
            <a onClick={() => removeItem(key)}><FontAwesomeIcon icon={faMinusCircle} size="lg" style={{ color: "lightgray", marginTop:"50px", cursor: "pointer" }} /></a>
        </GridContainer>
    }
}

Item.propTypes = {
    classes: PropTypes.object.isRequired,
    tasksIndexes: PropTypes.arrayOf(PropTypes.number),
    tasks: PropTypes.arrayOf(PropTypes.node),
    rtlActive: PropTypes.bool
};

export default withStyles(tasksStyle)(Item);
