import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import toolbarButtonStyle from "assets/jss/material-dashboard-react/components/toolbarButtonStyle.jsx";
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ToolbarButton(props) {
  
  return (
    <IconButton onClick={props.onClick} {...props}>
      <FontAwesomeIcon icon={props.icon} />
    </IconButton>
  );
}

export default withStyles(toolbarButtonStyle)(ToolbarButton);
