import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CustomAutoComplete from "../../components/CustomInput/CustomAutocomplete";
import CustomDate from "../../components/CustomInput/CustomDate";
import ListItems from "../../components/ListItems/ListItems";
import CalendarList from "../../components/CalendarList/CalendarList";
import CustomerList from "../../components/CustomerList/CustomerList";

import customerJournalStyle from "assets/jss/material-dashboard-react/views/customerJournalStyle.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomTooltip from "components/Tooltip/CustomTooltip.jsx";
import MUIDataTable from "mui-datatables";
import {api_ipaddress} from '../../variables.js'
import axios from 'axios';
import AlertDialog from "components/Dialog/Dialog.jsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faCheckSquare, faCommentAlt, faParking, faSquare, faTimes, faTruck} from '@fortawesome/free-solid-svg-icons'
import Switch from '@material-ui/core/Switch';
import {initlocalStorage} from 'functions/sessionInit.js'
import {primaryColor} from "assets/jss/material-dashboard-react.jsx";
import {floatToText, textToFloat} from "functions/helpers.js";

import {withTranslation} from 'react-i18next';

import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'
import Counters from "../../components/Counters/Counters";
import Button from "../../components/CustomButtons/Button";

var moment = require('moment-timezone');

function getFirstDay(year) {
  var d = new Date(year, 0, 1);
  var day = d.getDay();
  d.setDate(d.getDate() + (day%6? 0 :  (++day%4)));
  return moment(d);
}

const shortenLongString = (string) => {
    return string.length > 12 ? string.substring(0, 12) + "..." : string
}

const timeDelayInMinutes = (dtActual, dtTarget) => {
    return moment.duration(moment(dtActual).diff(moment(dtTarget))).asMinutes()
}

const textFromDelay = (DelayMinutes, t) => {
    return DelayMinutes >= 16 ?
        t("dashboard_delayed") :
        DelayMinutes >= 1 ?
            t("dashboard_marg") :
            t("dashboard_ontime")
}

const getDelayStatus = (r, event, t) => {
    let driving, time_departure, DelayMinutes
    if (event === "positioning") {
        if (r.tt_etp == null) return "-"
        DelayMinutes = timeDelayInMinutes(r.tt_etp, r.tt_stp)
        driving = r.tt_atp ? t("dashboard_arrived") : t("dashboard_in_transit")
    } else if (event === "departure") {
        if (r.tt_atp == null) return "-"
        time_departure = r.tt_atd ? moment(r.tt_atd) : moment()
        DelayMinutes = timeDelayInMinutes(time_departure, r.tt_std)
        driving = r.tt_atd ? t("dashboard_departed") : t("dashboard_waiting")
    } else if (event === "arrival") {
        if (r.tt_eta == null) return "-"
        DelayMinutes = timeDelayInMinutes(r.tt_eta, r.tt_sta)
        driving = r.tt_ata ? t("dashboard_arrived") : t("dashboard_in_transit")
    }
    return `${textFromDelay(DelayMinutes, t)} (${driving})`
}

const getDrivingStatus = (r, t) => {
    if (r.tt_status === "inactive")
        return t("dashboard_inactive")
    if (r.tt_status === "canceled")
        return t("dashboard_canceled")
    if (r.tt_ignition == null || r.tt_ata != null ||
        (r.tt_etp == null & r.tt_eta == null)) return "-"
    const status = r.tt_ignition === 1 && (r.tt_atd != null || r.tt_atp == null) ?
        t("dashboard_drives") : t("dashboard_stands")
    return status
}

const getDayOfTheWeek = (momentObj, t)  => {
    let dayOfWeek = momentObj.isoWeekday()
    let dayAbbr = ""
    switch (dayOfWeek) {
        case 1:
            dayAbbr = t("general_weekday_abbr_monday")
            break;
        case 2:
            dayAbbr = t("general_weekday_abbr_tuesday")
            break;
        case 3:
            dayAbbr = t("general_weekday_abbr_wednesday")
            break;
        case 4:
            dayAbbr = t("general_weekday_abbr_thursday")
            break;
        case 5:
            dayAbbr = t("general_weekday_abbr_friday")
            break;
        case 6:
            dayAbbr = t("general_weekday_abbr_saturday")
            break;
        case 7:
            dayAbbr = t("general_weekday_abbr_sunday")
            break;
        default:
            dayAbbr = momentObj.format("dd")
            break;
    }
    return dayAbbr;
}


const asTime = (momentObj, t) => {
    try {
        return momentObj.isValid() ? `${getDayOfTheWeek(momentObj, t)}  ${momentObj.format("HH:mm")}` : ""
    }
    catch (e) { return "" }
}

const asDate = (momentObj) => {
    try {
        return momentObj.isValid() ? momentObj.format("DD.MM.YYYY") : ""
    }
    catch (e) { return "" }
}

const dateFormat = "DD.MM.";
function pad(a, b) { return (1e15 + a + "").slice(-b) }
function getWeekToChoose(week) {
    let labelWeek = pad(week, 2)
    let currentYear =  moment().year()

    let momentWeek = moment().week(week)
    if (momentWeek.year() !== currentYear) {
      momentWeek = getFirstDay(currentYear).week(week)
    }

    let firstDay = moment(Object.assign({}, momentWeek.weekday(0)))
    let lastDay = moment(Object.assign({}, momentWeek.weekday(6)))

    return { value: week, label: `W${labelWeek} (${firstDay.format(dateFormat)} - ${lastDay.format(dateFormat)})` };
}

class CustomerJournal extends React.Component {

    constructor(props) {
        super(props);

        this.weeksAvailable = [...Array(moment().weeksInYear()).keys()].map((week) =>{return getWeekToChoose(week + 1)})
        const previousWeek = moment().week() - 1;

        let currentYear =  moment().year()
        let momentWeek = moment().week(previousWeek)
        if (momentWeek.year() !== currentYear) {
          momentWeek = getFirstDay(currentYear).week(previousWeek)
        }

        let dateFrom = moment(Object.assign({}, momentWeek.weekday(0)))
        let dateTo = moment(Object.assign({}, momentWeek.weekday(6)))

        this.state = {
            value: 0,
            tableLayout: "scroll",
            alertAskEdit: false,
            tc: false,
            message: "",
            updateDT: 1,
            customerJournalFilterList: {},
            searchText: "",
            sortedCol: "stp",
            sortDirection: "asc",
            customerJournalColListDesktop: {},
            customerJournalColListMobile: {},
            data: "",
            customers: [],
            isChecked: true,
            date_from: dateFrom,
            date_to: dateTo,
            week_from: null,
            week_to: null,
            invoiceList: [],
            references:[],
            calendarList: [],
            customer:"",
            page: 0,
            rowsPerPage: 10,
        };

        this.removeInvoiceItem = this.removeInvoiceItem.bind(this)
        this.addInvoiceItem = this.addInvoiceItem.bind(this)
        this.changeInvoiceItem = this.changeInvoiceItem.bind(this)

        this.onChangeCustomer = this.onChangeCustomer.bind(this)
        this.onChangeCalendar = this.onChangeCalendar.bind(this)
        this.buildExcelRequest = this.buildExcelRequest.bind(this)
        this.changePage = this.changePage.bind(this)
    }

    showNotification(place, message) {
        this.setState({
            [place]: true,
            message: message
        });

    }

    componentWillMount() {
        initlocalStorage();
    }

    componentDidMount() {
        moment.tz.setDefault();
        const previousWeek = moment().week() - 1;
        let currentYear =  moment().year()
        let momentWeek = moment().week(previousWeek)
        if (momentWeek.year() !== currentYear) {
          momentWeek = getFirstDay(currentYear).week(previousWeek)
        }

        const dateFrom = this.updateCustomerJournalFiltersFromState('dateFrom')
        const dateTo = this.updateCustomerJournalFiltersFromState('dateTo')

        this.setState({
            customerJournalFilterList: JSON.parse(localStorage.getItem("customerJournalFilterList")),
            isChecked: this.updateCustomerJournalFiltersFromState('isChecked'),
            date_from: dateFrom ? moment(dateFrom) : moment(Object.assign({}, momentWeek.weekday(0))),
            date_to: dateTo ? moment(dateTo) : moment(Object.assign({}, momentWeek.weekday(6))),
            week_from: this.updateCustomerJournalFiltersFromState('weekFrom'),
            week_to: this.updateCustomerJournalFiltersFromState('weekTo'),
            invoiceList: this.updateCustomerJournalFiltersFromState('invoiceList'),
            calendarList: this.updateCustomerJournalFiltersFromState('calendarList'),
            customerJournalColListDesktop: JSON.parse(localStorage.getItem("customerJournalColListDesktop")),
            customerJournalColListMobile: JSON.parse(localStorage.getItem("customerJournalColListMobile")),
            page: this.updateCustomerJournalFiltersFromState('page'),
            rowsPerPage: this.updateCustomerJournalFiltersFromState('rowsPerPage'),
        })
        this.getCustomers();
    }

    updateData(startDay, endDay, customer) {
        if (startDay && endDay && customer) {
            if (endDay.diff(startDay, 'days') <= (startDay.isLeapYear() ? 366 : 365)) {
              this.setState({ data: ""})
              axios.get(`${api_ipaddress}/api/customerjournal/customerjournaltimetable`,
                  {
                      headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                      params: {
                          role: localStorage.getItem('role'),
                          username: localStorage.getItem('username'),
                          startDay: moment(startDay).format("YYYY-MM-DD"),
                          endDay: moment(endDay).format("YYYY-MM-DD"),
                          customer: customer,
                          status: 'active;canceled'
                      }
                  })
                  .then(res => {
                      let total = 0
                      res.data.forEach( (ele) => {
                          if (ele.tt_invoice_check) {
                              total += ele.tt_customer_invoice_trip
                          }

                          ele.tt_customer_invoice_trip = floatToText(ele.tt_customer_invoice_trip)
                          ele.tt_customer_invoice_toll = floatToText(ele.tt_customer_invoice_toll)
                          ele.tt_customer_invoice_waiting_fee = floatToText(ele.tt_customer_invoice_waiting_fee)
                          ele.tt_customer_invoice_cancellation_fee = floatToText(ele.tt_customer_invoice_cancellation_fee)
                          ele.tt_sub_invoice_trip = floatToText(ele.tt_sub_invoice_trip)
                          ele.tt_sub_invoice_toll = floatToText(ele.tt_sub_invoice_toll)
                          ele.tt_sub_invoice_waiting_fee = floatToText(ele.tt_sub_invoice_waiting_fee)
                          ele.tt_sub_invoice_cancellation_fee = floatToText(ele.tt_sub_invoice_cancellation_fee)
                      });
                      let invoiceList = this.state.invoiceList
                      invoiceList.forEach((i) => {
                          const fuel = textToFloat(i['fuel'])
                          i['extra_cost'] = floatToText((total/100) * fuel)
                      })

                      this.setState({
                          data: res.data,
                          invoiceList: invoiceList
                      })
                  })
                  .catch(err => { 
                    this.setState({ data: [] })
                  })
            } else {
              this.setState({ data: []})
              alert(this.props.t("customer_journal_interval_message"))
            }
        } else {
            this.setState({ data: []})
        }
    }

    getCustomers(){
        axios.get(`${api_ipaddress}/api/customers/all`,
            { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
            .then(res => {
                this.setState({ customers: res.data.map(customer => {
                        return {"label": customer.cm_name, "value": customer}
                    }), customer: this.updateCustomerJournalFiltersFromState('customer')
                });
                const { isChecked, date_from, date_to, week_from, week_to } = this.state;
                const startDay = isChecked ? (week_from ? moment().week(week_from).weekday(0) : null): date_from
                const endDay = isChecked ? (week_to ? moment().week(week_to).weekday(6) : null) : date_to
                this.updateData(startDay, endDay, this.state.customer);
            });
    }

    resize() {
        this.setState({
            tableLayout: window.innerWidth <= 620 ? "stackedFullWidth" : "scroll",
            windowHeight: window.innerHeight
        });
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MuiTableRow: {
                root: {
                    height: "24px",
                }
            },
            MuiTableCell: {
                root: this.state.tableLayout === "scroll" ? {
                    paddingRight: "4px",
                    paddingTop: "6px",
                    paddingLeft: "4px",
                    paddingBottom: "6px",
                    whiteSpace: "nowrap",
                } : {
                    paddingTop: "4px",
                    paddingBottom: "1px",
                }
            },
            MUIDataTableBodyCell: {
                stackedCommon: {
                    width: "calc(50% - 32px) !important"
                }
            },
            MUIDataTable: {
                paperResponsiveScrollFullHeightFullWidth :{
                    position: "relative",
                },
                responsiveScroll: {
                    maxHeight: window.innerWidth <= 1280 ?
                        `${this.state.windowHeight - 640}px !important` :
                        `${this.state.windowHeight - 600}px !important`,
                    minHeight: "300px",
                },
            },
        }
    })


    delayToIcon = (value, t) => {
        if (value == null) return null
        const iconColor =
            value.includes(t("dashboard_ontime")) ? "#23c644" :
                value.includes(t("dashboard_marg")) ? "#ffae00" :
                    value.includes(t("dashboard_delayed")) ? "#ff4300" :
                        "lightgrey"
        const icon =
            value.includes(t("dashboard_arrived")) || value.includes(t("dashboard_departed")) ?
                <FontAwesomeIcon icon={faCheckSquare} size="lg"
                                 style={{ color: iconColor, paddingRight: "4px" }} /> :
                <FontAwesomeIcon icon={faSquare} size="lg"
                                 style={{ color: iconColor, paddingRight: "4px" }} />
        return (iconColor !== "lightgrey" && this.state.tableLayout === "scroll") ?
            <Tooltip title={value}><div>{icon}</div></Tooltip> :
            icon;
    }

    drivingToIcon = (value, t) => {
        if (value === "-" || value === t("dashboard_inactive")) return " "
        const icon =
            value.includes(t("dashboard_drives")) ?
                <FontAwesomeIcon icon={faTruck} size="lg"
                                 style={{ color: "gray", paddingRight: "4px" }} />
                :
                value.includes(t("dashboard_stands")) ?
                    <FontAwesomeIcon icon={faParking} size="lg"
                                     style={{ color: "lightgray", paddingRight: "4px" }} />
                    : ""
        return this.state.tableLayout === "scroll" ?
            <Tooltip title={t("dashboard_car") + " " + value}><div>{icon}</div></Tooltip> :
            icon;
    }

    // column specifications
    timeOptions = (name, label, colList, t) => {
        return {
            filter: false, sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (momentObj) => asTime(momentObj, t),
            display: this.state[colList][name]
        }
    }

    dateOptions = (name, label, colList) => {
        return {
            filter: false, sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (momentObj) => asDate(momentObj),
            display: this.state[colList][name]
        }
    }

    standardOptions = (name, label, colList) => {
        return {
            filter: true, sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            display: this.state[colList][name]
        }
    }

    currencyOptions = (name, label, colList, t) => {
        return {
            filter: true, sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (value) => `${value} ${t('currency_symbol')}`,
            display: this.state[colList][name]
        }
    }

    delayOptions = (name, label, colList, dep = false, t) => {
        return {
            filter: true,
            sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (value) => this.delayToIcon(value, t),
            display: this.state[colList][name],
            filterOptions: dep ?
                [
                    `${t("dashboard_ontime")} (${t("dashboard_departed")})`,
                    `${t("dashboard_ontime")} (${t("dashboard_waiting")})`,
                    `${t("dashboard_marg")} (${t("dashboard_departed")})`,
                    `${t("dashboard_marg")} (${t("dashboard_waiting")})`,
                    `${t("dashboard_delayed")} (${t("dashboard_departed")})`,
                    `${t("dashboard_delayed")} (${t("dashboard_waiting")})`,
                ] :
                [
                    `${t("dashboard_ontime")} (${t("dashboard_arrived")})`,
                    `${t("dashboard_ontime")} (${t("dashboard_in_transit")})`,
                    `${t("dashboard_marg")} (${t("dashboard_arrived")})`,
                    `${t("dashboard_marg")} (${t("dashboard_in_transit")})`,
                    `${t("dashboard_delayed")} (${t("dashboard_arrived")})`,
                    `${t("dashboard_delayed")} (${t("dashboard_in_transit")})`,
                ]
        }
    }

    drivingOptions = (name, label, colList, t) => {
        return {
            filter: true,
            sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (v) => this.drivingToIcon(v, t),
            display: this.state[colList][name],
            filterOptions: [t("dashboard_drives"), t("dashboard_stands")]
        }
    }

    activeOptions = (colList) => {
        return {
            filter: false, searchable: false, sort: true,
            customBodyRender: (value) => {
                switch (value) {
                    case "active":
                        return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
                    case "inactive":
                        return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "red" }} />
                    case "scheduled":
                        return <FontAwesomeIcon icon={faClock} size="lg" style={{ color: "lightgray" }} />
                    case "canceled":
                        return <strong style={{ color: "red" }}>CNL</strong>
                    default:
                        return "";
                }
            },
            display: this.state[colList]["active"],
        }
    }

    invoiceOptions = (name, colList) => {
        return {
            filter: false,
            searchable: false,
            sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (value) => {
                if(value){
                    return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
                }else{
                    return ""
                }
            },
            display: this.state[colList]["invoice"],
        }
    }

    noteDesktopOptions = (name, colList, field) => {
        return {
            filter: false, searchable: false, sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (value) => {
                if (value !== "" && value !== null) {
                    let color = 'gray'
                    if(field === 'info') {
                        color = '#C20E1A'
                    }

                    return (
                        <CustomTooltip title={value}><div>
                            <FontAwesomeIcon
                                icon={faCommentAlt}
                                size="lg"
                                style={{ color: color }}
                            />
                        </div></CustomTooltip>
                    )
                }
                else
                    return null;
            },
            display: this.state[colList][field],
        }
    }

    tableHeaderTooltip = (title, child) => {
        return{
            customBodyRender: (value) => {
                return(
                    <CustomTooltip title={title}></CustomTooltip>
                )
            }

        }
    }

    noteMobileOptions = (name, colList) => {
        return {
            filter: false, searchable: false, sort: true,
            sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
            customBodyRender: (tt_note) => {
                if (tt_note !== "" && tt_note !== null) {
                    return tt_note.length > 16 ? tt_note.substring(0, 15) + "..." : tt_note;
                }
                else
                    return null;
            },
            display: this.state[colList]["note"]
        }
    }

    idOptions = (colList) => {
        return {
            display: this.state[colList]["id"],
            filter: false,
            sort: true,
            sortDirection: this.state.sortedCol === "id" ? this.state.sortDirection : "none",
            searchable: false
        }
    }

    overviewOptions = (colList) => {
        return {
            display: this.state[colList]["overview"],
            filter: false
        }
    }

    changePage = (page) => {
        const filters = this.changeCustomerJournalFilters(['page'], [page]);
        this.setState({ page: page, customerJournalFilterList: filters })
    }

    addInvoiceItem = (event) => {
        const { invoiceList, isChecked, week_from, date_from } = this.state
        const startDay = isChecked ? (week_from ? moment().week(week_from).weekday(0) : null): date_from
        invoiceList.push( { "date": startDay, "fuel": '0.00', "fuel_error": false, "extra_cost":'0.00', "extra_cost_error": false, "invoice_text": "" })
        const filters = this.changeCustomerJournalFilters(['invoiceList'], [invoiceList]);
        this.setState({ invoiceList: invoiceList, customerJournalFilterList: filters });
    };

    removeInvoiceItem = (key) => {
        const { invoiceList } = this.state
        invoiceList.splice(key, 1)
        const filters = this.changeCustomerJournalFilters(['invoiceList'], [invoiceList]);
        this.setState({ invoiceList: invoiceList, customerJournalFilterList: filters });
    };

    changeInvoiceItem = (key, prop, value, error) => {
        const { invoiceList, data } = this.state

        let total = 0
        if (prop === 'fuel') {
            data.forEach( (ele) => {
                if (ele.tt_invoice_check) {
                    const invoiceTripValue = textToFloat(ele.tt_customer_invoice_trip)
                    total += invoiceTripValue
                }
            });
            invoiceList[key]['extra_cost'] = floatToText((total/100) * textToFloat(value))
        }

        if (prop === 'fuel' || prop === 'extra_cost')
            invoiceList[key][`${prop}_error`] = error

        invoiceList[key][prop] = value
        const filters = this.changeCustomerJournalFilters(['invoiceList'], [invoiceList]);
        this.setState({ invoiceList: invoiceList, customerJournalFilterList: filters });
    }

    onChangeCalendar = (week, reference) => {
        const { calendarList } = this.state
        let item = calendarList.filter((itemWeek) => itemWeek.week === week)[0]
        item.reference = reference

        const filters = this.changeCustomerJournalFilters(['calendarList'], [calendarList]);
        this.setState({ calendarList: calendarList, customerJournalFilterList: filters });
    }

    onChangeCustomer = (ref, value) => {
        const {customer, customers}  = this.state
        const customerInfo = customers.filter((c)=> c.label === customer)[0].value
        customerInfo[`cm_reference_${ref + 1}`] = value
        this.setState({customers: customers})
    }

    buildExcelRequest = () => {
        const {isChecked, date_from, date_to, week_from, week_to, invoiceList, customers, calendarList, customer} = this.state

        const startDay = isChecked ? (week_from ? moment().week(week_from).weekday(0) : null): date_from
        const endDay = isChecked ? (week_to ? moment().week(week_to).weekday(6) : null) : date_to
        const customerInfo = this.state.customers.filter((customer)=> customer.label === this.state.customer)[0].value

        let calendarReferences = calendarList.map((i) => {
                let item = {}
                item[`${i.week.substr(1, 2)}`] = i.reference
                return item
            }
        )

        invoiceList.forEach((el) => {
            el.fuel = textToFloat(el.fuel)
            el.extra_cost = textToFloat(el.extra_cost)
        })

        const request = {
            "start_date": startDay,
            "end_date": endDay,
            "customer": customer,
            "invoice_items": invoiceList,
            "calendar_references": calendarReferences,
            "customer_references": {
                "reference_1": customerInfo.cm_reference_1,
                "reference_2": customerInfo.cm_reference_2,
                "reference_3": customerInfo.cm_reference_3,
                "reference_4": customerInfo.cm_reference_4,
                "reference_5": customerInfo.cm_reference_5
            }
        }

        return request
    }

    exportExcel = (request) => {
        if (request == null)
            return
        axios.post(`${api_ipaddress}/api/customerjournal/customerjournaldownload`, request,
            {
                headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                params: {
                    role: localStorage.getItem('role'),
                    username: localStorage.getItem('username')
                },
                responseType: 'blob'
            }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'customer_journal.xlsx');
            document.body.appendChild(link);
            link.click();
            return false;
        })
        .catch((err) => {
            this.showNotification("tc", "Leider gab es ein Problem");
            return false;
        })
    }

    changeCustomerJournalFilters = (changedColumns, values) => {
        const customerJournalFilterList = "customerJournalFilterList";
        let tempColList = { ...this.state[customerJournalFilterList] };

        changedColumns.forEach((el, idx) => {
          if (el === 'isChecked') {
              tempColList[el] = values[idx];
          } else {
              tempColList[el] = values[idx] ? values[idx] : "";
          }
        })

        localStorage.setItem(customerJournalFilterList, JSON.stringify(tempColList));
        return tempColList
    }

    updateCustomerJournalFiltersFromState = (changedColumn) => {
        let tempFilterList = JSON.parse(localStorage.getItem("customerJournalFilterList"));
        return tempFilterList[changedColumn];
    }

    setDefaultFilter = () => {
        const previousWeek = moment().week() - 1;
        let currentYear =  moment().year()
        let momentWeek = moment().week(previousWeek)
        if (momentWeek.year() !== currentYear) {
          momentWeek = getFirstDay(currentYear).week(previousWeek)
        }

        const defaultState = {
            "calendarList": [],
            "customer": null,
            "dateFrom": moment(Object.assign({}, momentWeek.weekday(0))),
            "dateTo": moment(Object.assign({}, momentWeek.weekday(6))),
            "invoiceList": [],
            "isChecked": true,
            "weekFrom": null,
            "weekTo": null,
            "page": 0,
            "rowsPerPage": 10,
        }
        
        localStorage.setItem("customerJournalFilterList",
            JSON.stringify(defaultState)
        )

        this.setState( {
            value: 0,
            alertAskEdit: false,
            tc: false,
            message: "",
            updateDT: 1,
            customerJournalFilterList: defaultState,
            searchText: "",
            sortedCol: "stp",
            sortDirection: "asc",
            data: [],
            isChecked: true,
            date_from: moment(moment().week(previousWeek).weekday(0)),
            date_to: moment(moment().week(previousWeek).weekday(6)),
            week_from: null,
            week_to: null,
            invoiceList: [],
            references:[],
            calendarList: [],
            customer:"",
            page:0,
            rowsPerPage: 10,
        } );

    }

    render() {
        const { tableLayout, customer, customers, date_from, date_to, week_from, week_to, isChecked, invoiceList, calendarList } = this.state;
        const { t, i18n } = this.props;
        const customerInfo = customers.filter((c)=> c.label === customer)[0]

        const data = this.state.data !== "" ? this.state.data.map(
                r => ({
                    id: r.tt_id,
                    invoice: r.tt_invoice_check,
                    active: r.tt_status,
                    tour: shortenLongString(r.tt_tour),
                    from: shortenLongString(r.tt_from_text),
                    to: shortenLongString(r.tt_to_text),
                    gps: r.tt_gps,
                    date: moment(r.tt_stp),
                    status: getDrivingStatus(r, t),
                    pos: getDelayStatus(r, "positioning", t),
                    stp: moment(r.tt_stp),
                    etp: moment(r.tt_etp),
                    dep: getDelayStatus(r, "departure", t),
                    std: moment(r.tt_std),
                    atd: moment(r.tt_atd),
                    arr: getDelayStatus(r, "arrival", t),
                    sta: moment(r.tt_sta),
                    eta: moment(r.tt_eta),
                    driver: r.tt_driver,
                    note: localStorage.getItem('role') === "admin" ? r.tt_note : "",
                    info: (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "customer" || localStorage.getItem('role') === "sub" || localStorage.getItem('role') === "observer") ? r.tt_info : "",
                    overview: <div>
                        {this.delayToIcon(getDelayStatus(r, "positioning", t), t)}
                        {this.delayToIcon(getDelayStatus(r, "departure", t), t)}
                        {this.delayToIcon(getDelayStatus(r, "arrival", t), t)}
                        {this.drivingToIcon(getDrivingStatus(r, t), t)}
                    </div>,
                    customer_invoice_text: r.tt_customer_invoice_text,
                    customer_invoice_trip: r.tt_customer_invoice_trip,
                    customer_invoice_toll: r.tt_customer_invoice_toll,
                    customer_invoice_waiting_fee: r.tt_customer_invoice_waiting_fee,
                    customer_invoice_cancellation_fee: r.tt_customer_invoice_cancellation_fee,
                    sub_invoice_trip: r.tt_sub_invoice_trip,
                    sub_invoice_toll: r.tt_sub_invoice_toll,
                    sub_invoice_waiting_fee: r.tt_sub_invoice_waiting_fee,
                    sub_invoice_cancellation_fee: r.tt_sub_invoice_cancellation_fee,
                    sub_invoice_comment: r.tt_sub_invoice_comment,
                })
            )
                .filter(r =>
                    (!r.arr.includes(t("dashboard_arrived")) &&
                        (r.sta.clone().add(6, 'hours') > moment()) && (
                            r.pos.includes(t("dashboard_marg")) || r.pos.includes(t("dashboard_delayed")) ||
                            r.dep.includes(t("dashboard_marg")) || r.dep.includes(t("dashboard_delayed")) ||
                            r.arr.includes(t("dashboard_marg")) || r.arr.includes(t("dashboard_delayed")))) ||
                    !JSON.parse(localStorage.getItem("panicbutton")))
            :
            null

        const columns = tableLayout === "scroll" ?
            [ // full desktop view
                { name: "id", label: "ID", options: this.idOptions("customerJournalColListDesktop") },
                { name: "invoice", label: "Inv.", options: this.invoiceOptions("invoice","customerJournalColListDesktop") },
                { name: "active", label: t("dashboard_active"), options: this.activeOptions("customerJournalColListDesktop") },
                { name: "tour", label: t("dashboard_tour_nr"), options: this.standardOptions("tour", t("dashboard_tour_nr"), "customerJournalColListDesktop") },
                { name: "from", label: t("general_from"), options: this.standardOptions("from", t("general_from"), "customerJournalColListDesktop") },
                { name: "to", label: t("general_to"), options: this.standardOptions("to", t("general_to"), "customerJournalColListDesktop") },
                { name: "date", label: t("dashboard_date"), options: this.dateOptions("date", t("dashboard_date"), "customerJournalColListDesktop", t) },
                { name: "status", label: t("dashboard_status"), options: this.drivingOptions("status", t("dashboard_status"), "customerJournalColListDesktop", t) },
                { name: "pos", label: "P", options: this.delayOptions("pos", "P", "customerJournalColListDesktop", false, t) },
                { name: "stp", label: "STP", options: this.timeOptions("stp", "STP", "customerJournalColListDesktop", t) },
                { name: "etp", label: "ETP/ATP", options: this.timeOptions("etp", "ETP/ATP", "customerJournalColListDesktop", t) },
                { name: "dep", label: "D", options: this.delayOptions("dep", "D", "customerJournalColListDesktop", true, t) },
                { name: "std", label: "STD", options: this.timeOptions("std", "STD", "customerJournalColListDesktop", t) },
                { name: "atd", label: "ATD", options: this.timeOptions("atd", "ATD", "customerJournalColListDesktop", t) },
                { name: "arr", label: "A", options: this.delayOptions("arr", "A", "customerJournalColListDesktop", false, t) },
                { name: "sta", label: "STA", options: this.timeOptions("sta", "STA", "customerJournalColListDesktop", t) },
                { name: "eta", label: "ETA/ATA", options: this.timeOptions("eta", "ETA/ATA", "customerJournalColListDesktop", t) },
                { name: "gps", label: t("dashboard_car"), options: this.standardOptions("gps", t("dashboard_car"), "customerJournalColListDesktop") },
                { name: "driver", label: t("dashboard_driver"), options: this.standardOptions("driver", t("dashboard_driver"), "customerJournalColListDesktop") },
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_trip", label: t("dashboard_customer_invoice_trip"), options: this.currencyOptions("customer_invoice_trip", t("dashboard_customer_invoice_trip"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_toll", label: t("dashboard_customer_invoice_toll"), options: this.currencyOptions("customer_invoice_toll", t("dashboard_customer_invoice_toll"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_waiting_fee", label: t("dashboard_customer_invoice_waiting_fee"), options: this.currencyOptions("customer_invoice_waiting_fee", t("dashboard_customer_invoice_waiting_fee"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_cancellation_fee", label: t("dashboard_customer_invoice_cancellation_fee"), options: this.currencyOptions("customer_invoice_cancellation_fee", t("dashboard_customer_invoice_cancellation_fee"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_trip", label: t("dashboard_sub_invoice_trip"), options: this.currencyOptions("sub_invoice_trip", t("dashboard_sub_invoice_trip"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_toll", label: t("dashboard_sub_invoice_toll"), options: this.currencyOptions("sub_invoice_toll", t("dashboard_sub_invoice_toll"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_waiting_fee", label: t("dashboard_sub_invoice_waiting_fee"), options: this.currencyOptions("sub_invoice_waiting_fee", t("dashboard_sub_invoice_waiting_fee"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_cancellation_fee", label: t("dashboard_sub_invoice_cancellation_fee"), options: this.currencyOptions("sub_invoice_cancellation_fee", t("dashboard_sub_invoice_cancellation_fee"), "customerJournalColListDesktop", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_comment", label: t("dashboard_sub_invoice_comment"), options: this.noteDesktopOptions("sub_invoice_comment", "customerJournalColListDesktop", "sub_invoice_comment")}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_text", label: t("dashboard_customer_invoice_text"), options: this.noteDesktopOptions("customer_invoice_text", "customerJournalColListDesktop", "customer_invoice_text")}] : []),
                { name: "info", label: t("dashboard_info"), options: this.noteDesktopOptions("info","customerJournalColListDesktop", "info") },
                ...(localStorage.getItem('role') === "admin" ? [{ name: "note", label: t("dashboard_notes"), options: this.noteDesktopOptions("note", "customerJournalColListDesktop", "note") }] : [])
            ] :
            [ // reduced mobile view
                { name: "id", label: "ID", options: this.idOptions("customerJournalColListMobile") },
                { name: "invoice", label: "Inv.", options: this.invoiceOptions("invoice","customerJournalColListMobile") },
                { name: "active", label: t("dashboard_active"), options: this.activeOptions("customerJournalColListMobile") },
                { name: "tour", label: t("dashboard_tour_nr"), options: this.standardOptions("tour", t("dashboard_tour_nr"), "customerJournalColListMobile") },
                { name: "from", label: t("general_from"), options: this.standardOptions("from", t("general_from"), "customerJournalColListMobile") },
                { name: "to", label: t("general_to"), options: this.standardOptions("to", t("general_to"), "customerJournalColListMobile") },
                { name: "date", label: t("dashboard_date"), options: this.dateOptions("date", t("dashboard_date"), "customerJournalColListMobile", t) },
                { name: "status", label: t("dashboard_status"), options: this.drivingOptions("status", t("dashboard_status"), "customerJournalColListMobile", t) },
                { name: "pos", label: "P", options: this.delayOptions("pos", "P", "customerJournalColListMobile", false, t) },
                { name: "stp", label: "STP", options: this.timeOptions("stp", "STP", "customerJournalColListMobile", t) },
                { name: "etp", label: "ETP/ATP", options: this.timeOptions("etp", "ETP/ATP", "customerJournalColListMobile", t) },
                { name: "dep", label: "D", options: this.delayOptions("dep", "D", "customerJournalColListMobile", true, t) },
                { name: "std", label: "STD", options: this.timeOptions("std", "STD", "customerJournalColListMobile", t) },
                { name: "atd", label: "ATD", options: this.timeOptions("atd", "ATD", "customerJournalColListMobile", t) },
                { name: "arr", label: "A", options: this.delayOptions("arr", "A", "customerJournalColListMobile", false, t) },
                { name: "sta", label: "STA", options: this.timeOptions("sta", "STA", "customerJournalColListMobile", t) },
                { name: "eta", label: "ETA/ATA", options: this.timeOptions("eta", "ETA/ATA", "customerJournalColListMobile", t) },
                { name: "gps", label: t("dashboard_car"), options: this.standardOptions("gps", t("dashboard_car"), "customerJournalColListMobile") },
                { name: "driver", label: t("dashboard_driver"), options: this.standardOptions("driver", t("dashboard_driver"), "customerJournalColListMobile") },
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_trip", label: t("dashboard_customer_invoice_trip"), options: this.currencyOptions("customer_invoice_trip", t("dashboard_customer_invoice_trip"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_toll", label: t("dashboard_customer_invoice_toll"), options: this.currencyOptions("customer_invoice_toll", t("dashboard_customer_invoice_toll"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_waiting_fee", label: t("dashboard_customer_invoice_waiting_fee"), options: this.currencyOptions("customer_invoice_waiting_fee", t("dashboard_customer_invoice_waiting_fee"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_cancellation_fee", label: t("dashboard_customer_invoice_cancellation_fee"), options: this.currencyOptions("customer_invoice_cancellation_fee", t("dashboard_customer_invoice_cancellation_fee"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_trip", label: t("dashboard_sub_invoice_trip"), options: this.currencyOptions("sub_invoice_trip", t("dashboard_sub_invoice_trip"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_toll", label: t("dashboard_sub_invoice_toll"), options: this.currencyOptions("sub_invoice_toll", t("dashboard_sub_invoice_toll"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_waiting_fee", label: t("dashboard_sub_invoice_waiting_fee"), options: this.currencyOptions("sub_invoice_waiting_fee", t("dashboard_sub_invoice_waiting_fee"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_cancellation_fee", label: t("dashboard_sub_invoice_cancellation_fee"), options: this.currencyOptions("sub_invoice_cancellation_fee", t("dashboard_sub_invoice_cancellation_fee"), "customerJournalColListMobile", t)}] : []),
                ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_comment", label: t("dashboard_sub_invoice_comment"), options: this.noteDesktopOptions("sub_invoice_comment", "customerJournalColListMobile", "sub_invoice_comment")}] : []),
                ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_text", label: t("dashboard_customer_invoice_text"), options: this.noteDesktopOptions("customer_invoice_text", "customerJournalColListMobile", "customer_invoice_text")}] : []),
                { name: "info", label: t("dashboard_info"), options: this.noteDesktopOptions("info","customerJournalColListMobile", "info") },
                ...(localStorage.getItem('role') === "admin" ? [{ name: "note", label: t("dashboard_notes"), options: this.noteDesktopOptions("note", "customerJournalColListMobile", "note") }] : [])
            ];



        const options = {
            filterType: 'multiselect',
            search: false,
            filter: false,
            rowsPerPage: this.state.rowsPerPage,
            onRowClick: (rowData, rowMeta) => {
                let role = localStorage.getItem('role');
                if (role === "admin" || role === "sub") {
                    if (role === "admin" || (role === "sub" && ["active", "scheduled"].includes(data[rowMeta.dataIndex].active) && data[rowMeta.dataIndex].sta > moment())) {
                        this.setState({
                            alertAskEdit: true,
                            clickedTrip: rowData[columns.findIndex(el => el.name === "id")],
                            clickedTripNr: rowData[columns.findIndex(el => el.name === "tour")],
                        })
                    }
                    else {
                        this.showNotification("tc", t("edit_no_permission_edit_old_trips"));
                    }
                } else {
                    console.log("not allowed")
                }
            },
            onColumnViewChange: (changedColumn, action) => {
                const thelist = tableLayout === "scroll" ? "customerJournalColListDesktop" : "customerJournalColListMobile"
                let tempColList = { ...this.state[thelist] }
                tempColList[changedColumn] = action === "add" ? true : false
                this.setState({ [thelist]: tempColList })
                localStorage.setItem(thelist, JSON.stringify(tempColList))
            },
            onColumnSortChange: (changedColumn, direction) => {
                this.setState({
                    sortedCol: changedColumn,
                    sortDirection: direction === "descending" ? "desc" : "asc",
                })
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const {isChecked, date_from, date_to, week_from, week_to, customer} = this.state
                const startDay = isChecked ? week_from : date_from
                const endDay = isChecked ? week_to: date_to
                if (startDay && endDay && customer) {
                    let request = this.buildExcelRequest();
                    this.exportExcel(request);
                } else {
                    alert('Invalid filters')
                }
                return false;
            },
            onChangeRowsPerPage: (numberOfRows) => {
                const filters = this.changeCustomerJournalFilters( ['rowsPerPage', 'page'], [numberOfRows, 0])
                this.setState({page: 0, rowsPerPage: numberOfRows, customerJournalFilterList: filters})
            },
            pagination: true,
            rowsPerPageOptions: [10,50,100],
            elevation: 0,
            print: false,
            download: true,
            selectableRows: false,
            responsive: tableLayout,
            textLabels: {
                body: {
                    noMatch: t("dashboard_no_trips_match_criteria"),
                    toolTip: t("general_sort"),
                },
                toolbar: {
                    search: t("general_search"),
                    downloadCsv: "Download",
                },
                viewColumns: {
                    title: t("dashboard_select_cols"),
                    titleAria: t("dashboard_show_hide_cols"),
                },
            },
            onChangePage: this.changePage,
            page: this.state.page,
        };

        const handleSwitchChange = (event) => {
            const startDay = event.target.checked ? (week_from ? moment().week(week_from).weekday(0) : null): date_from
            const endDay = event.target.checked ? (week_to ? moment().week(week_to).weekday(6) : null) : date_to

            let calendarList = []
            if (startDay && endDay) {
                const dayOfWeekFrom = startDay.week()
                const dayOfWeekTo = endDay.week()
                const weeks = this.weeksAvailable.filter((week) => week.value >= dayOfWeekFrom && week.value <= dayOfWeekTo)
                calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
            }

            const filters = this.changeCustomerJournalFilters(['isChecked', 'calendarList'], [event.target.checked, calendarList]);
            this.setState({ isChecked: event.target.checked, calendarList: calendarList, customerJournalFilterList: filters });
            this.updateData(startDay, endDay, customer)
        };

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary"
                                        style={tableLayout === "scroll" ?
                                            { display: "flex", justifyContent: "space-between" } :
                                            {}}
                            >
                                <h4 style={tableLayout === "scroll" ?
                                    { marginTop: "0px", marginBottom: "0px" } :
                                    { marginTop: "0px", marginBottom: "10px" }}>
                                    {t('customer_journal')}
                                </h4>
                                <div style={{ marginTop: "-5px", marginBottom: "-10px"}}>
                                    <span>Date</span>
                                    <Switch
                                        checked={isChecked}
                                        onChange={handleSwitchChange}
                                        disabled={localStorage.getItem('dashboard_view') === "last_week"}
                                        value="week_date_selection"
                                        style={{ color: "white" }}
                                    />
                                    <span>Week</span>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={2} sm={2} md={3} >
                                        <CustomAutoComplete
                                            labelText={"customer_journal_customer"}
                                            id="customer"
                                            value={customers.filter(el => el.label === customer)}
                                            onChange={value => {
                                                const filters = this.changeCustomerJournalFilters(['customer'], [value ? value.label : ""]);
                                                this.setState({ customer: value !== null ? value.label : null, data: [], customerJournalFilterList: filters });
                                                if (value){
                                                    const startDay = isChecked ? (week_from ? moment().week(week_from).weekday(0) : null): date_from
                                                    const endDay = isChecked ? (week_to ? moment().week(week_to).weekday(6) : null) : date_to
                                                    this.updateData(startDay, endDay, value.label);
                                                }
                                            }}
                                            required={true}
                                            selectChoices={customers}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={2}>
                                        <CustomDate
                                            inputProps={{
                                                label: t("general_date_from"),
                                                id: "date_from",
                                                value: date_from,
                                                style: { marginTop:"7px" },
                                                onChange: (date) => {
                                                    if (date) {
                                                        let calendarList = []
                                                        if (date_to) {
                                                            const dayOfWeekFrom = date.week()
                                                            const dayOfWeekTo = date_to.week()
                                                            const weeks = this.weeksAvailable.filter((week) => week.value >= dayOfWeekFrom && week.value <= dayOfWeekTo)
                                                            calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
                                                        }

                                                        const filters = this.changeCustomerJournalFilters(['calendarList', 'dateFrom'], [calendarList, moment(date)]);
                                                        this.setState({ date_from: date, calendarList: calendarList, customerJournalFilterList: filters });
                                                        this.updateData(date, date_to, customer);
                                                    }
                                                },
                                                onClear: () => this.setState({ date_from: null }),
                                                disabled: isChecked
                                            }}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={2}>
                                        <CustomDate
                                            inputProps={{
                                                label: t("general_date_to"),
                                                id: "date_to",
                                                value: date_to,
                                                style: {marginTop:"7px"},
                                                onChange: (date) => {
                                                    if (date) {
                                                        let calendarList = []
                                                        if (date_from) {
                                                            const dayOfWeekFrom = date_from.week()
                                                            const dayOfWeekTo = date.week()
                                                            const weeks = this.weeksAvailable.filter((week) => week.value >= dayOfWeekFrom && week.value <= dayOfWeekTo)
                                                            calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
                                                        }
                                                        const filters = this.changeCustomerJournalFilters(['calendarList', 'dateTo'], [calendarList, moment(date)]);
                                                        this.setState({ date_to: date, calendarList: calendarList, customerJournalFilterList: filters });
                                                        this.updateData(date_from, date, customer);
                                                    }
                                                },
                                                onClear: () => this.setState({ date_to: null }),
                                                disabled: isChecked
                                            }}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} >
                                        <CustomAutoComplete
                                            labelText="general_week_from"
                                            id="weekSelectionFrom"
                                            value={this.weeksAvailable.filter(el => el.value === week_from)}
                                            onChange={value => {
                                                let calendarList = []
                                                if (week_to && value !== null) {
                                                    const weeks = this.weeksAvailable.filter((week) => week.value >= value.value && week.value <= week_to);
                                                    calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }});
                                                }
                                                if(value) {
                                                    const filters = this.changeCustomerJournalFilters(['calendarList', 'weekFrom'], [calendarList, value.value]);
                                                    this.setState({ week_from: value.value, calendarList: calendarList, customerJournalFilterList: filters });
                                                }else{
                                                    const filters = this.changeCustomerJournalFilters(['calendarList', 'weekFrom'], [calendarList, null]);
                                                    this.setState({week_from: null, calendarList: calendarList});
                                                }
                                                if(value){
                                                    this.updateData(moment().week(value.value).weekday(0), week_to ? moment().week(week_to).weekday(6) : null, customer);
                                                }

                                            }}
                                            selectChoices={this.weeksAvailable}
                                            disabled={!isChecked}
                                        />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} >
                                        <CustomAutoComplete
                                          labelText="general_week_to"
                                            id="weekSelectionTo"
                                            value={this.weeksAvailable.filter(el => el.value === week_to)}
                                            onChange={value => {
                                                let calendarList = []
                                                if (week_from && value !== null) {
                                                    const weeks = this.weeksAvailable.filter((week) => week.value >= week_from && week.value <= value.value)
                                                    calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
                                                }
                                                if(value){
                                                    const filters = this.changeCustomerJournalFilters(['calendarList', 'weekTo'], [calendarList, value.value]);
                                                    this.setState({ week_to: value.value, calendarList: calendarList, customerJournalFilterList: filters });
                                                }else{
                                                    const filters = this.changeCustomerJournalFilters(['calendarList', 'weekTo'], [calendarList, null]);
                                                    this.setState({ week_to: null, calendarList: calendarList, customerJournalFilterList: filters });
                                                }
                                                if(value){
                                                    this.updateData( week_from ? moment().week(week_from).weekday(0) : null, moment().week(value.value).weekday(6), customer);
                                                }

                                            }}
                                            selectChoices={this.weeksAvailable}
                                            disabled={!isChecked}
                                        />
                                    </GridItem>
                                    <GridItem xs={1} sm={1} md={1}>
                                        <Button style={{ float: "right", marginTop: "2.5rem" }} color="primary"
                                                onClick={() => {
                                                    this.setDefaultFilter();
                                                }}
                                        >{t("customer_reset")}</Button>
                                    </GridItem>
                                </GridContainer>
                                {this.state.data !== "" && <>
                                    <MuiThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable
                                            key={
                                                this.state.tableLayout +
                                                this.state.updateDT +
                                                i18n.language
                                            }
                                            // In order to have components on the left side, we have to pass the as title.
                                            data={data}
                                            columns={columns}
                                            options={options}
                                        />
                                    </MuiThemeProvider>
                                    <Counters labels={{Title:'Totals:', Total:"Total", Trip:"Customer Trip", Toll:"Customer Toll", CNL:"Customer CNL", Waiting:"Customer Waiting"}} data={this.state.data} invoiceList={invoiceList} sumIsChecked={true} properties={
                                        {
                                            trip: "tt_customer_invoice_trip",
                                            toll: "tt_customer_invoice_toll",
                                            cancellation_fee: "tt_customer_invoice_cancellation_fee",
                                            waiting_fee: "tt_customer_invoice_waiting_fee",
                                        }
                                    }/>
                                    <div style={{'marginTop': '50px'}}>
                                        <GridContainer>
                                            <GridItem xs={6} sm={6} md={6}>
                                                <ListItems list={invoiceList}
                                                           startDate={ isChecked ? (week_from ? moment().week(week_from).weekday(0) : null): date_from }
                                                           endDate= { isChecked ? (week_to ? moment().week(week_to).weekday(6) : null) : date_to}
                                                           onChangeItem={this.changeInvoiceItem}
                                                           addItem={this.addInvoiceItem}
                                                           removeItem={this.removeInvoiceItem} t={t} />
                                            </GridItem>
                                            <GridItem xs={6} sm={6} md={6}>
                                                <GridContainer>
                                                    <GridItem xs={6} sm={6} md={6}>
                                                        <CalendarList list={calendarList} onChangeCalendar={this.onChangeCalendar} t={t}/>
                                                    </GridItem>
                                                    <GridItem xs={6} sm={6} md={6}>
                                                        <CustomerList customer={customerInfo ? customerInfo.value : null} onChangeCustomer={this.onChangeCustomer}></CustomerList>
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </>}
                                {this.state.data === "" &&
                                    <CircularProgress
                                        disableShrink
                                        style={{
                                            color: primaryColor[0],
                                            margin: "auto",
                                            display: "block"
                                        }} />}
                            </CardBody>
                        </Card>
                        <AlertDialog
                            open={this.state.alertAskEdit}
                            onClose={() => this.setState({ alertAskEdit: false })}
                            handleNo={() => this.setState({ alertAskEdit: false })}
                            handleYes={() => this.props.history.push(`/admin/edit/${this.state.clickedTrip}`)}
                            title={`${t("edit_edit_trip")}: ${this.state.clickedTripNr}?`}
                            // content={"Dies leitet Sie auf die Bearbeitungsmaske weiter."}
                        />
                        <Snackbar
                            place="tc" //topcenter
                            color="primary"
                            message={this.state.message}
                            open={this.state.tc}
                            closeNotification={() => this.setState({ tc: false })}
                            close
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

CustomerJournal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(customerJournalStyle)(CustomerJournal));
