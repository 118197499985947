import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { ResponsivePie } from '@nivo/pie'

// core components
import donutStyle from "assets/jss/material-dashboard-react/components/donutStyle.jsx";

function Donut(props) {
  let total = props.data.reduce((sum, item) => {
    return sum + item.value
  }, 0)
  
  return (
    <ResponsivePie
      data={props.data}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      fit={true}
      innerRadius={0.5}
      padAngle={0.2}
      cornerRadius={3}
      borderColor={{ from: 'color', modifiers: [['brighter', 0.2]] }}
      sortByValue={true}
      colors={{ datum: 'color' }}
      enableRadialLabels={false}
      animate={true}
      tooltipFormat={e => `${e} (${Math.round(e * 100 / total)}%)`}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor={"#FFF"}
      onMouseEnter={(data, event) => {
        event.target.style["opacity"] = '0.85';
      }}
      onMouseLeave={(data, event) => {
        event.target.style["opacity"] = '1';
      }}
      sliceLabel={function (e) { return `${e.value} (${Math.round(e.value * 100 / total)}%)` }}
      {...props} />
  );
}

export default withStyles(donutStyle)(Donut);
