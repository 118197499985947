import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCurrencyInput from "components/CustomInput/CustomCurrencyInput.jsx";
import CustomAutoComplete from "components/CustomInput/CustomAutocomplete.jsx";
import CustomCheckbox from "components/CustomInput/CustomCheckbox.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Tags from "components/Tags/Tags.jsx";

import {api_ipaddress} from '../../variables.js'
import axios from 'axios'

import {withTranslation} from 'react-i18next';
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'
import {floatToText, textToFloat} from "../../functions/helpers";
import CustomTime from "../../components/CustomInput/CustomTime";
import Table from "../../components/Table/Table";
import {weekdays} from "moment";

var moment = require('moment-timezone');
moment.locale("de-ch");

const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};


class EditDisposition extends React.Component {
  constructor(props) {
    super(props);

    const{ t, i18n } = this.props;

    let weekdays = moment.weekdays();
    weekdays.push(weekdays.shift());

    let week = {};
    weekdays.forEach(day => {
      week[`d_day_enable_${day}`] = false;
      week[`d_group_${day}`] = null;
      week[`d_subcontractor_${day}`] = null;
      week[`d_vehicle_${day}`] = null;
      week[`d_driver_${day}`] = null;
      week[`d_sub_invoice_trip_${day}`] = null;
      week[`d_sub_invoice_trip_${day}_error`] = null;
      week[`d_sub_invoice_toll_${day}`] = null;
      week[`d_sub_invoice_toll_${day}_error`] = null;
      week[`d_observer_${day}`] = [];
      week[`d_vehicle_choices_${day}`] = [];
      week[`d_driver_choices_${day}`] = [];
    });

    let availableStatus = [
      { "value": true, "label": t("dashboard_scheduled") },
      { "value": false, "label": t("dashboard_inactive") },
    ];

    let requirementChoices = ["11_2_7"];
    let requirements = {};
    requirementChoices.forEach(item => {
      requirements[`tr_requirement_${item}`] = true;
    });

    this.state = {
      mode: "add", // shows table, or add / edit
      tr_id: null,
      alert: false,
      tr_status: true,
      tr_name: null,
      tr_client: null,
      tr_departure: null,
      tr_arrival: null,
      tr_tour_comb: null,
      tr_stp: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_std: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_std_plus_day: "0",
      tr_std_plus_day_error: "",
      tr_sta: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_sta_plus_day: "0",
      tr_sta_plus_day_error: "",
      tr_customer_invoice_trip: '0.00',
      tr_customer_invoice_toll: '0.00',
      departureChoices: [],
      arrivalChoices: [],
      addresses: [],
      statusChoices: availableStatus,
      customerChoices: [],
      combinationChoices: [],
      requirementChoices: requirementChoices,
      subcontractorChoices: [],
      vehicleChoices: [],
      driverChoices: [],
      observerChoices: [],
      weekdays: weekdays, //Sunday is always last
      filterList: JSON.parse(localStorage.getItem("dispositionFilterList")),
      dispositionColListDesktop: JSON.parse(localStorage.getItem("dispositionColListDesktop")),
      dispositionColListMobile: JSON.parse(localStorage.getItem("dispositionColListMobile")),
      ...week,
      ...requirements,
      tr_week_driver: null,
      tr_week_drivernr: null,
      tr_week_gps: null,
      tr_week_observer: null,
      tr_week_status:null,
      tr_week_sub:null,
      tr_week_sub_invoice_toll:null,
      tr_week_sub_invoice_trip:null,
      tr_week_locked:null,
    };

    this.trStdPlusDayRef = React.createRef()
    this.trStaPlusDayRef = React.createRef()
  }


  componentDidMount() {
    moment.tz.setDefault("Etc/UTC");
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    const requests = this.initView();
    Promise.all(requests.all)
       .then(() => {
        
        if(localStorage.getItem('role') === "admin") {
          Promise.all(requests.admin).then(() => {
            this.getDispositionById(this.props.match.params.id)
          })
        } else {
          this.getDispositionById(this.props.match.params.id)
        }
      })
  }

  updateFloatValues(res){
    res.data.forEach(tour => {
      tour.tr_customer_invoice_trip = tour.tr_customer_invoice_trip ? floatToText(tour.tr_customer_invoice_trip) : '0.00'
      tour.tr_customer_invoice_toll = tour.tr_customer_invoice_toll ? floatToText(tour.tr_customer_invoice_toll) : '0.00'
      tour.tr_week_sub_invoice_trip = tour.tr_week_sub_invoice_trip.map(day => day ? floatToText(day) : '0.00')
      tour.tr_week_sub_invoice_toll = tour.tr_week_sub_invoice_toll.map(day => day ? floatToText(day) : '0.00')
    })
    return res
  }  
  
  initView(){
    // if admin
    const getAllCustomers = axios.get(`${api_ipaddress}/api/customers/all`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
          .then(res => {
            let customer_choices = "data" in res
              ? res.data.map(customer => ({ value: customer.cm_id, label: customer.cm_name }))
              : [];
            this.setState({ customerChoices: customer_choices });
          });


    const getAllObservers = axios.get(`${api_ipaddress}/api/observers`,
          {headers: {"Authorization": `Bearer ${localStorage.getItem('access_token')}`}})
          .then(res => {
            let observers_choices = "data" in res
              ? res.data.map(observer => ({value: observer, label: observer}))
              : [];
            this.setState({observerChoices: observers_choices});
          });

    // without restrictions
    const getAllCombinations = axios.get(`${api_ipaddress}/api/tours/combinations/all`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          let combination_choices = "data" in res
            ? res.data.map(combination => ({ value: combination, label: combination }))
            : [];
          this.setState({ combinationChoices: combination_choices });
        });

    const getAllSubcontractors = axios.get(`${api_ipaddress}/api/subcontractors`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          let subcontractor_choices = "data" in res
            ? res.data.map(subcontractor => ({ value: subcontractor.sc_name, label: subcontractor.sc_name }))
            : [];
          this.setState({ subcontractorChoices: subcontractor_choices });
        });

    const getAllVehicles = axios.get(`${api_ipaddress}/api/gps`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          let vehicle_choices = "data" in res
            ? res.data.map(gps => ({ value: gps.gps_id, label: gps.gps_id, groups: gps.gps_groups }))
            : [];
          this.setState({ vehicleChoices: vehicle_choices });
        });

    const getAllDrivers = axios.get(`${api_ipaddress}/api/drivers`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          let driver_choices = "data" in res
            ? res.data.map(driver => ({ value: driver.dr_id, label: driver.dr_name, groups: driver.dr_groups, requirements: driver.dr_requirements }))
            : [];
          this.setState({ driverChoices: driver_choices });
        });


    const getAllAddresses = axios.get(`${api_ipaddress}/api/address`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          const address_choices = "data" in res
            ? res.data.map(item => ({ value: item.add_id, label: item.add_name }))
            : [];

          const address_choices_with_id = "data" in res
            ? res.data.map(item => ({ value: item.add_id, label: `${item.add_name} (${item.add_id})` }))
          : [];
        this.setState({ addresses: address_choices, departureChoices: address_choices_with_id, arrivalChoices: address_choices_with_id });
      });

    return { admin: [getAllCustomers, getAllObservers], all: [getAllCombinations, getAllSubcontractors, getAllVehicles, getAllDrivers, getAllAddresses]}
  }

  getDispositionById(id){
    if(!(Number.isInteger(parseInt(id)) || id === "neu")){
      return
    }

    const { t } = this.props;
    axios.get(`${api_ipaddress}/api/tours/${id}`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then((res) => {

        this.setState({
          mode: "edit",
          tr_arrival: res.data.tr_arrival,
          tr_client: res.data.tr_cm_id,
          tr_customer_invoice_toll: res.data.tr_customer_invoice_toll ? floatToText(res.data.tr_customer_invoice_toll) : '0.00',
          tr_customer_invoice_trip: res.data.tr_customer_invoice_trip ? floatToText(res.data.tr_customer_invoice_trip) : '0.00',
          tr_departure: res.data.tr_departure,
          tr_id: res.data.tr_id,
          tr_name: res.data.tr_name,
          tr_requirements: res.data.tr_requirements,
          tr_sta: moment(res.data.tr_sta),
          tr_sta_plus_day: res.data.tr_sta_plus_day,
          tr_status: res.data.tr_status,
          tr_std: moment(res.data.tr_std),
          tr_std_plus_day: res.data.tr_std_plus_day,
          tr_stp: moment(res.data.tr_stp),
          tr_tour_comb: res.data.tr_tour_comb,
          tr_week_driver: res.data.tr_week_driver,
          tr_week_drivernr: res.data.tr_week_drivernr,
          tr_week_gps: res.data.tr_week_gps,
          tr_week_observer: res.data.tr_week_observer ? res.data.tr_week_observer : null,
          tr_week_status: res.data.tr_week_status,
          tr_week_sub: res.data.tr_week_sub,
          tr_week_sub_invoice_toll: res.data.tr_week_sub_invoice_toll.map( day => day ? floatToText(day) : '0.00'),
          tr_week_sub_invoice_trip: res.data.tr_week_sub_invoice_trip.map( day => day ? floatToText(day) : '0.00'),
          tr_week_locked: res.data.tr_week_locked ? res.data.tr_week_locked : []
        })
        for (let i = 0; i < this.state.weekdays.length; i++) {
          this.setState({
            [`d_day_enable_${this.state.weekdays[i]}`]: this.state.tr_week_status[i],
            [`d_vehicle_${this.state.weekdays[i]}`]: this.state.tr_week_gps[i],
            [`d_subcontractor_${this.state.weekdays[i]}`]: this.state.tr_week_sub[i],
            [`d_group_${this.state.weekdays[i]}`]: this.state.tr_week_sub[i],
            [`d_observer_${this.state.weekdays[i]}`]: this.state.tr_week_observer ? this.state.tr_week_observer[i].length > 0 ? this.state.tr_week_observer[i].split(";").map(item => { return { value: item, label: item } }) : [] : [],
            [`d_driver_${this.state.weekdays[i]}`]: this.state.tr_week_driver[i],
            [`d_drivernr_${this.state.weekdays[i]}`]: this.state.tr_week_drivernr[i],
            [`d_vehicle_choices_${this.state.weekdays[i]}`]: this.state.tr_week_sub[i] !== null ? this.state.vehicleChoices.filter(
              gps => gps.groups.split(';').includes(this.state.tr_week_sub[i].toLowerCase())
            ) : [],
            [`d_driver_choices_${this.state.weekdays[i]}`]: this.state.tr_week_sub[i] !== null ? this.state.driverChoices.filter(
              driver => driver.groups.split(';').includes(this.state.tr_week_sub[i]) &&
                this.driverHasRequirements(driver, t)
            ) : [],
            [`d_sub_invoice_trip_${this.state.weekdays[i]}`]: this.state.tr_week_sub_invoice_trip[i],
            [`d_sub_invoice_toll_${this.state.weekdays[i]}`]: this.state.tr_week_sub_invoice_toll[i]
          })
        }
      })
      .catch((err) => {
        this.showNotification("tc", t(err.response.data.message));
      });
  }

  resize() {
    this.setState({
      windowHeight: window.innerHeight
    });
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  validateForm = () => {
    const {
      tr_name,
      tr_client,
      tr_departure,
      tr_arrival,
      tr_stp,
      tr_std,
      tr_sta,
      tr_std_plus_day,
      tr_sta_plus_day
    } = this.state;

    if (
      tr_name === null || //Required name
      tr_client === null || //Required client
      tr_departure === null || //Required departure
      tr_arrival === null || //Required arrival
      tr_stp === null || (tr_stp !== null && !tr_stp._isValid) || //Required stp
      tr_std === null || (tr_std !== null && !tr_std._isValid) || //Required std
      tr_sta === null || (tr_sta !== null && !tr_sta._isValid) || //Required sta
      tr_std_plus_day < 0 ||
      tr_sta_plus_day < 0
    )
      return false;

    let enabledDays = 0;
    let valid = true;
    moment.weekdays().forEach(day => {
      if (this.state[`d_day_enable_${day}`] === true) {
        enabledDays++;
        if (this.state[`d_subcontractor_${day}`] === null)
          valid = false;
      }
    });

    return enabledDays !== 0 && valid;
  }

  isDisabled(stp) {
    if(localStorage.getItem('role') !== "admin"){
      let tripDate = moment(stp);
      let dateToday = moment();
      return tripDate <= dateToday;
    }
    return false
  }

  asTime = (momentObj) => {
    try {
      return momentObj.isValid() ? momentObj.format("HH:mm") : ""
    }
    catch (e) { return "" }
  }

  driverHasRequirements = (driver, t) => {
    const { requirementChoices } = this.state;
    let requerimentStatus = requirementChoices.filter(item => this.state[`tr_requirement_${item}`]).map(item => (item !== null && item.length !== 0)
      ? t(`disposition_requirement_${item}`) : "");

    if (requerimentStatus === null || requerimentStatus.length === 0)
      return true;

    if (driver.requirements.length > 0) {
      if (driver.requirements.includes(';'))
        return driver.requirements.split(';').every(item => requerimentStatus.includes(item));
      else
        return requerimentStatus.includes(driver.requirements);
    }
    else
      return false;
  }

  clearData = () => {
    const { requirementChoices } = this.state;

    let weekdays = moment.weekdays();
    weekdays.push(weekdays.shift());

    this.setState({
      tr_status: true,
      tr_name: null,
      tr_client: null,
      tr_departure: null,
      tr_arrival: null,
      tr_tour_comb: null,
      tr_stp: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_std: moment("00:00", ["HH:mm", moment.ISO_8601]),
      tr_sta: moment("00:00", ["HH:mm", moment.ISO_8601]),
    })
    requirementChoices.forEach(item => {
      this.setState({ [`tr_requirement_${item}`]: true });
    });

    weekdays.forEach(day => {
      this.setState({
        [`d_day_enable_${day}`]: false,
        [`d_group_${day}`]: null,
        [`d_subcontractor_${day}`]: null,
        [`d_vehicle_${day}`]: null,
        [`d_driver_${day}`]: null,
        [`d_sub_invoice_trip_${day}`]: null,
        [`d_sub_invoice_toll_${day}`]: null,
        [`d_drivernr_${day}`]: null,
        [`d_observer_${day}`]: []
      })
    });
  }

  buildRequestBody = () => {
    const {
      tr_status,
      tr_name,
      tr_client,
      tr_tour_comb,
      tr_departure,
      tr_arrival,
      tr_stp,
      tr_std,
      tr_std_plus_day,
      tr_sta,
      tr_sta_plus_day,
      tr_customer_invoice_trip,
      tr_customer_invoice_toll,
      requirementChoices,
      weekdays,
    } = this.state;

    let requirements = [];
    requirementChoices.forEach(item => {
      if (this.state[`tr_requirement_${item}`]) {
        requirements.push(`${item}`);
      }
    });

    let tr_week_status = new Array(7);
    let tr_week_sub = new Array(7);
    let tr_week_gps = new Array(7);
    let tr_week_driver = new Array(7);
    let tr_week_drivernr = new Array(7);
    let tr_week_observer = new Array(7);
    let tr_week_sub_invoice_trip = new Array(7);
    let tr_week_sub_invoice_toll = new Array(7);

    let i = 0;
    weekdays.forEach(day => {
      let day_enabled = this.state[`d_day_enable_${day}`];
      tr_week_status[i] = day_enabled;

      let subcontractor = this.state[`d_subcontractor_${day}`];
      tr_week_sub[i] = subcontractor !== undefined ? subcontractor : null;

      let vehicle = this.state[`d_vehicle_${day}`];
      tr_week_gps[i] = vehicle !== undefined ? vehicle : null;

      let driver = this.state[`d_driver_${day}`];
      tr_week_driver[i] = driver !== undefined ? driver : null;

      let drivernr = this.state[`d_drivernr_${day}`];
      tr_week_drivernr[i] = drivernr !== undefined ? drivernr : null;

      let observer = this.state[`d_observer_${day}`];
      tr_week_observer[i] = observer !== undefined ? observer.map(o => o.value).join(";") : null;

      let sub_invoice_trip = this.state[`d_sub_invoice_trip_${day}`];
      tr_week_sub_invoice_trip[i] = sub_invoice_trip !== undefined ? textToFloat(sub_invoice_trip) : null;

      let sub_invoice_toll = this.state[`d_sub_invoice_toll_${day}`];
      tr_week_sub_invoice_toll[i] = sub_invoice_toll !== undefined ? textToFloat(sub_invoice_toll) : null;



      i++;
    });

    return {
      "tr_cm_id": tr_client,
      "tr_status": tr_status,
      "tr_name": tr_name,
      "tr_tour_comb": tr_tour_comb,
      "tr_departure": tr_departure,
      "tr_arrival": tr_arrival,
      "tr_stp": tr_stp,
      "tr_std": tr_std,
      "tr_std_plus_day": tr_std_plus_day,
      "tr_sta": tr_sta,
      "tr_sta_plus_day": tr_sta_plus_day,
      "tr_requirements": requirements,
      "tr_customer_invoice_trip": textToFloat(tr_customer_invoice_trip),
      "tr_customer_invoice_toll": textToFloat(tr_customer_invoice_toll),
      "tr_week_status": tr_week_status,
      "tr_week_sub": tr_week_sub,
      "tr_week_gps": tr_week_gps,
      "tr_week_driver": tr_week_driver,
      "tr_week_drivernr": tr_week_drivernr,
      "tr_week_observer": tr_week_observer ? tr_week_observer: [],
      "tr_week_sub_invoice_trip": tr_week_sub_invoice_trip,
      "tr_week_sub_invoice_toll": tr_week_sub_invoice_toll,
      "tr_week_locked": this.state.tr_week_locked,
    }
  }

  minutesOfDay = (m) => m.minutes() + m.hours() * 60;

  scrollToRef = (ref) => document.querySelector('#mainPanel').scrollTo(0, ref.current.offsetTop)

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;

    const {
      mode,
      tr_status,
      tr_name,
      tr_client,
      tr_tour_comb,
      tr_departure,
      tr_arrival,
      tr_stp,
      tr_std,
      tr_std_plus_day,
      tr_std_plus_day_error,
      tr_sta,
      tr_sta_plus_day,
      tr_sta_plus_day_error,
      tr_customer_invoice_trip,
      tr_customer_invoice_trip_error,
      tr_customer_invoice_toll,
      tr_customer_invoice_toll_error,
      departureChoices,
      arrivalChoices,
      addresses,
      statusChoices,
      customerChoices,
      combinationChoices,
      requirementChoices,
      subcontractorChoices,
      vehicleChoices,
      driverChoices,
      observerChoices,
      weekdays,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  {t("disposition_edit_disposition")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>{t("disposition_tours")}</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomAutoComplete
                      labelText={"disposition_status"}
                      id="status"
                      value={statusChoices.filter(el => el.value === tr_status)}
                      onChange={value => {
                        this.setState({ tr_status: value !== null ? value.value : null });
                      }}
                      required={true}
                      selectChoices={statusChoices}
                      disabled={localStorage.getItem('role') === "sub"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("disposition_name")}
                      id="name"
                      inputProps={{
                        value: tr_name ? tr_name : "",
                        onChange: (event) => this.setState({
                          tr_name: event.target.value
                        }),
                        disabled: localStorage.getItem('role') === "sub"
                      }}
                      required={true}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  { localStorage.getItem('role') === "admin" && <>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomAutoComplete
                        labelText={"disposition_client"}
                        id="client"
                        value={customerChoices.filter(el => el.value === tr_client)}
                        onChange={value => {
                          this.setState({ tr_client: value !== null ? value.value : null });
                        }}
                        required={true}
                        selectChoices={customerChoices}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomAutoComplete
                        labelText={"disposition_combination"}
                        id="combination"
                        value={combinationChoices.filter(el => el.value === tr_tour_comb)}
                        isCreatable={true}
                        onChange={value => {
                          this.setState({ tr_tour_comb: value !== null ? value.value : null });
                        }}
                        onCreateOption={value => {
                          combinationChoices.push({ value: value, label: value });
                          this.setState({ tr_tour_comb: value !== "" ? value : null });
                        }}
                        required={true}
                        selectChoices={combinationChoices}
                      />
                    </GridItem>
                  </>}
                  { localStorage.getItem('role') === "sub" && <>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomAutoComplete
                        labelText={"disposition_combination"}
                        id="combination"
                        value={combinationChoices.filter(el => el.value === tr_tour_comb)}
                        isCreatable={true}
                        onChange={value => {
                          this.setState({ tr_tour_comb: value !== null ? value.value : null });
                        }}
                        onCreateOption={value => {
                          combinationChoices.push({ value: value, label: value });
                          this.setState({ tr_tour_comb: value !== "" ? value : null });
                        }}
                        required={true}
                        selectChoices={combinationChoices}
                        disabled={localStorage.getItem('role') === "sub"}
                      />
                    </GridItem>
                  </>}
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomAutoComplete
                      labelText={"disposition_departure"}
                      id="departure"
                      value={departureChoices.filter(el => el.value === tr_departure)}
                      onChange={value => {
                        this.setState({
                          tr_departure: value !== null ? value.value : null,
                          arrivalChoices: addresses.filter(address => address !== value)
                        });
                      }}
                      required={true}
                      selectChoices={departureChoices}
                      disabled={localStorage.getItem('role') === "sub"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomAutoComplete
                      labelText={"disposition_arrival"}
                      id="arrival"
                      value={arrivalChoices.filter(el => el.value === tr_arrival)}
                      onChange={value => {
                        this.setState({
                          tr_arrival: value !== null ? value.value : null,
                          departureChoices: addresses.filter(address => address !== value)
                        });
                      }}
                      required={true}
                      selectChoices={arrivalChoices}
                      disabled={localStorage.getItem('role') === "sub"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomTime
                      inputProps={{
                        label: t("disposition_stp"),
                        id: "tr_stp",
                        value: tr_stp,
                        onChange: (event) => {
                          if (event !== null)
                            this.setState({ tr_stp: event })
                          else
                            this.setState({ tr_stp: null })
                        },
                        disabled: localStorage.getItem('role') === "sub",
                        error: tr_stp === null || (tr_stp !== null && !tr_stp._isValid)
                      }}
                      required={true}
                      formControlProps={{
                        fullWidth: true
                      }}
                      clearable={false}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomTime
                      inputProps={{
                        label: t("disposition_std"),
                        id: "tr_std",
                        value: tr_std,
                        onChange: (event) => {
                          if (event !== null){
                            this.setState({ tr_std: event })
                          } else {
                            this.setState({ tr_std: null })
                          }
                        },
                        disabled: localStorage.getItem('role') === "sub",
                        error: tr_std === null || (tr_std !== null && !tr_std._isValid)
                      }}
                      required={true}
                      formControlProps={{
                        fullWidth: true
                      }}
                      clearable={false}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <div ref={this.trStdPlusDayRef}>
                      <CustomInput
                        labelText={t("disposition_std_plus_day")}
                        id="tr_std_plus_day"
                        min="0"
                        inputProps={{
                          type: "number",
                          value: tr_std_plus_day,
                          onChange: (event) => this.setState({
                            tr_std_plus_day: event.target.value,
                            tr_std_plus_day_error: null
                          }),
                          disabled: localStorage.getItem('role') === "sub",
                        }}
                        required={true}
                        helperText={t("disposition_std_plus_day_error")}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomTime
                      inputProps={{
                        label: t("disposition_sta"),
                        id: "tr_sta",
                        value: tr_sta,
                        onChange: (event) => {
                          if (event !== null)
                            this.setState({ tr_sta: event })
                          else
                            this.setState({ tr_sta: null })
                        },
                        disabled: localStorage.getItem('role') === "sub",
                        error: tr_sta === null || (tr_sta !== null && !tr_sta._isValid)
                      }}
                      required={true}
                      formControlProps={{
                        fullWidth: true
                      }}
                      clearable={false}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <div ref={this.trStaPlusDayRef}>
                      <CustomInput
                        labelText={t("disposition_sta_plus_day")}
                        id="tr_sta_plus_day"
                        inputProps={{
                          type: "number",
                          min: "0",
                          value: tr_sta_plus_day,
                          onChange: (event) => this.setState({
                            tr_sta_plus_day: event.target.value,
                            tr_sta_plus_day_error: ""
                          }),
                          disabled: localStorage.getItem('role') === "sub",
                        }}
                        required={true}
                        helperText={t("disposition_sta_plus_day_error")}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>{t("disposition_requirements")}</h4>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {
                          requirementChoices.map(item =>
                            <CustomCheckbox
                              key={`d_requirement_${item}`}
                              labelText={t(`disposition_requirement_${item}`)} id={`d_requirement_${item}`}
                              inputProps={{
                                checked: this.state[`tr_requirement_${item}`],
                                disabled: localStorage.getItem('role') === "sub",
                                onChange: (event) => {
                                  this.setState({
                                    [`tr_requirement_${item}`]: event.target.checked
                                  }, function (){
                                    weekdays.forEach(day => {
                                      var group = this.state[`d_group_${day}`];
                                      this.setState({
                                          [`d_driver_${day}`]: null,
                                          [`d_drivernr_${day}`]: null,
                                          [`d_driver_choices_${day}`]: group !== null
                                            ? driverChoices.filter(driver => driver.groups.split(';').includes(group) &&
                                              this.driverHasRequirements(driver, t))
                                            : []
                                        },
                                        () => {
                                          this.setState({
                                            [`d_driver_choices_${day}`]: group !== null
                                              ? driverChoices.filter(driver => driver.groups.split(';').includes(group) &&
                                                this.driverHasRequirements(driver, t))
                                              : []
                                          });
                                        });
                                    });
                                  });
                                }
                              }}
                            />
                          )
                        }
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  { localStorage.getItem('role') === "admin" && <>
                    <GridItem xs={12} sm={12} md={6}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>{t("disposition_customer_price")}</h4>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomCurrencyInput
                            id={`tr_customer_invoice_trip`}
                            labelText={t("disposition_customer_invoice_trip")}
                            currencySymbol={t("currency_symbol")}
                            validateOnChange={(val, error) => this.setState({ 'tr_customer_invoice_trip': val, 'tr_customer_invoice_trip_error': error  })}
                            inputProps={{
                              value: tr_customer_invoice_trip,
                              disabled: localStorage.getItem('role') === "sub",
                            }}
                            required={true}
                            helperText={t("disposition_customer_invoice_trip_error")}
                            error={tr_customer_invoice_trip_error}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomCurrencyInput
                            id={`tr_customer_invoice_toll`}
                            labelText={t("disposition_customer_invoice_toll")}
                            currencySymbol={t("currency_symbol")}
                            validateOnChange={(val, error) => this.setState({ 'tr_customer_invoice_toll': val, 'tr_customer_invoice_toll_error': error })}
                            inputProps={{
                              value: tr_customer_invoice_toll,
                              disabled: localStorage.getItem('role') === "sub",
                            }}
                            required={true}
                            helperText={t("disposition_customer_invoice_toll_error")}
                            error={tr_customer_invoice_toll_error}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </>}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>{t("disposition_operator")}</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    { localStorage.getItem('role') === "sub" && <>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={[
                          { value: t("disposition_weekdays"), props: { width: "6em" } },
                          { value: t("disposition_subcontractor"), props: { width: "200px" } },
                          { value: t("disposition_vehicle"), props: { width: "200px" } },
                          { value: t("disposition_driver"), props: { width: "200px" } },
                          { value: t("disposition_sub_invoice_trip"), props: { width: "200px" } },
                          { value: t("disposition_sub_invoice_toll"), props: { width: "200px" } }
                        ]}
                        tableData={
                          this.state.weekdays.map(day => [
                            <CustomCheckbox
                              labelText={day}
                              id={`d_day_enable_$(day)`}
                              inputProps={{
                                checked: this.state[`d_day_enable_${day}`],
                                disabled: localStorage.getItem('role') === "sub",
                                onChange: (event) => {
                                  this.setState({
                                    [`d_day_enable_${day}`]: event.target.checked
                                  })

                                  if (!event.target.checked) {
                                    this.setState({
                                      [`d_subcontractor_${day}`]: null,
                                      [`d_subcontractor_choices_${day}`]: [],
                                      [`d_group_${day}`]: null,
                                      [`d_vehicle_${day}`]: null,
                                      [`d_vehicle_choices_${day}`]: [],
                                      [`d_driver_${day}`]: null,
                                      [`d_drivernr_${day}`]: null,
                                      [`d_sub_invoice_trip_${day}`]: null,
                                      [`d_sub_invoice_toll_${day}`]: null,
                                      [`d_driver_choices_${day}`]: [],
                                      [`d_observer_${day}`]: [],
                                    });
                                  }
                                }
                              }}
                            />,
                            <CustomAutoComplete
                              id={`subcontractor_${day}`}
                              value={subcontractorChoices.filter(el => el.value === this.state[`d_subcontractor_${day}`])}
                              onChange={opt => {
                                this.setState({
                                  [`d_subcontractor_${day}`]: opt !== null ? opt.value : null,
                                  [`d_group_${day}`]: opt !== null ? opt.value : null,
                                  [`d_vehicle_choices_${day}`]: opt !== null ? vehicleChoices.filter(
                                    gps => gps.groups.split(';').includes(opt.value.toLowerCase())
                                  ) : [],
                                  [`d_driver_choices_${day}`]: opt !== null ? driverChoices.filter(
                                    driver => driver.groups.split(';').includes(opt.value) &&
                                      this.driverHasRequirements(driver, t)
                                  ) : []
                                });
                              }}
                              required={true}
                              disabled={!this.state[`d_day_enable_${day}`] || localStorage.getItem('role') === "sub"}
                              selectChoices={subcontractorChoices}
                            />,
                            <CustomAutoComplete
                              id={`vehicle_${day}`}
                              value={this.state[`d_vehicle_choices_${day}`].filter(el => el.value === this.state[`d_vehicle_${day}`])}
                              onChange={value => {
                                this.setState({ [`d_vehicle_${day}`]: value !== null ? value.value : null });
                              }}
                              disabled={!this.state[`d_day_enable_${day}`]}
                              selectChoices={this.state[`d_vehicle_choices_${day}`]}
                            />,
                            <CustomAutoComplete
                              id={`driver_${day}`}
                              value={this.state[`d_driver_choices_${day}`].filter(el => el.value === this.state[`d_drivernr_${day}`])}
                              onChange={value => {
                                this.setState({ [`d_driver_${day}`]: value != null ? value.label : null });
                                this.setState({ [`d_drivernr_${day}`]: value != null ? value.value : null });
                              }}
                              onOpen={() => {
                                axios.get(`${api_ipaddress}/api/drivers`,
                                  { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                  .then(res => {
                                    let driver_choices = "data" in res
                                      ? res.data.map(driver => ({ value: driver.dr_id, label: driver.dr_name, groups: driver.dr_groups, requirements: driver.dr_requirements }))
                                      : [];
                                    let subcontractor = this.state[`d_subcontractor_${day}`]
                                    this.setState({
                                      [`d_driver_choices_${day}`]: subcontractor !== null ? driver_choices.filter(
                                        driver => driver.groups.split(';').includes(subcontractor) &&
                                          this.driverHasRequirements(driver, t)
                                      ) : [] });
                                  });
                              }}
                              disabled={!this.state[`d_day_enable_${day}`]}
                              selectChoices={this.state[`d_driver_choices_${day}`]}
                            />,
                            <div>
                              <CustomCurrencyInput
                                id={`tr_sub_invoice_trip_${day}`}
                                labelText={t("disposition_sub_invoice_trip")}
                                currencySymbol={t("currency_symbol")}
                                validateOnChange={(val, error) => this.setState({ [`d_sub_invoice_trip_${day}`]: val, [`d_sub_invoice_trip_${day}_error`]: error})}
                                inputProps={{
                                  value: this.state[`d_sub_invoice_trip_${day}`] ? this.state[`d_sub_invoice_trip_${day}`] : "",
                                  disabled: localStorage.getItem('role') === "sub",
                                }}
                                required={true}
                                helperText={t("disposition_sub_invoice_trip_error")}
                                error={this.state[`d_sub_invoice_trip_${day}_error`]}
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />
                            </div>,
                            <div>
                              <CustomCurrencyInput
                                id={`tr_sub_invoice_toll_${day}`}
                                labelText={t("disposition_sub_invoice_toll")}
                                currencySymbol={t("currency_symbol")}
                                validateOnChange={(val, error) => this.setState({ [`d_sub_invoice_toll_${day}`]: val, [`d_sub_invoice_toll_${day}_error`]: error})}
                                inputProps={{
                                  value: this.state[`d_sub_invoice_toll_${day}`] ? this.state[`d_sub_invoice_toll_${day}`] : "",
                                  disabled: localStorage.getItem('role') === "sub",
                                }}
                                required={true}
                                helperText={t("disposition_sub_invoice_toll_error")}
                                error={this.state[`d_sub_invoice_toll_${day}_error`]}
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />
                            </div>
                          ])}
                      /></>}
                    { localStorage.getItem('role') === "admin" && <>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={[
                          { value: t("disposition_weekdays"), props: { width: "6em" } },
                          { value: t("disposition_subcontractor"), props: { width: "200px" } },
                          { value: t("disposition_vehicle"), props: { width: "200px" } },
                          { value: t("disposition_driver"), props: { width: "200px" } },
                          { value: t("disposition_observer"), props: { width: "200px" } },
                          { value: t("disposition_sub_invoice_trip"), props: { width: "200px" } },
                          { value: t("disposition_sub_invoice_toll"), props: { width: "200px" } }
                        ]}
                        tableData={
                          this.state.weekdays.map(day => [
                            <CustomCheckbox
                              labelText={day}
                              id={`d_day_enable_$(day)`}
                              inputProps={{
                                checked: this.state[`d_day_enable_${day}`],
                                disabled: localStorage.getItem('role') === "sub",
                                onChange: (event) => {
                                  this.setState({
                                    [`d_day_enable_${day}`]: event.target.checked
                                  })

                                  if (!event.target.checked) {
                                    this.setState({
                                      [`d_subcontractor_${day}`]: null,
                                      [`d_subcontractor_choices_${day}`]: [],
                                      [`d_group_${day}`]: null,
                                      [`d_vehicle_${day}`]: null,
                                      [`d_vehicle_choices_${day}`]: [],
                                      [`d_driver_${day}`]: null,
                                      [`d_drivernr_${day}`]: null,
                                      [`d_sub_invoice_trip_${day}`]: null,
                                      [`d_sub_invoice_toll_${day}`]: null,
                                      [`d_driver_choices_${day}`]: [],
                                      [`d_observer_${day}`]: [],
                                    });
                                  }
                                }
                              }}
                            />,
                            <CustomAutoComplete
                              id={`subcontractor_${day}`}
                              value={subcontractorChoices.filter(el => el.value === this.state[`d_subcontractor_${day}`])}
                              onChange={opt => {
                                this.setState({
                                  [`d_subcontractor_${day}`]: opt !== null ? opt.value : null,
                                  [`d_group_${day}`]: opt !== null ? opt.value : null,
                                  [`d_vehicle_choices_${day}`]: opt !== null ? vehicleChoices.filter(
                                    gps => gps.groups.split(';').includes(opt.value.toLowerCase())
                                  ) : [],
                                  [`d_driver_choices_${day}`]: opt !== null ? driverChoices.filter(
                                    driver => driver.groups.split(';').includes(opt.value) &&
                                      this.driverHasRequirements(driver, t)
                                  ) : []
                                });
                              }}
                              required={true}
                              disabled={!this.state[`d_day_enable_${day}`] || localStorage.getItem('role') === "sub"}
                              selectChoices={subcontractorChoices}
                            />,
                            <CustomAutoComplete
                              id={`vehicle_${day}`}
                              value={this.state[`d_vehicle_choices_${day}`].filter(el => el.value === this.state[`d_vehicle_${day}`])}
                              onChange={value => {
                                this.setState({ [`d_vehicle_${day}`]: value !== null ? value.value : null });
                              }}
                              disabled={!this.state[`d_day_enable_${day}`]}
                              selectChoices={this.state[`d_vehicle_choices_${day}`]}
                            />,
                            <CustomAutoComplete
                              id={`driver_${day}`}
                              value={this.state[`d_driver_choices_${day}`].filter(el => el.value === this.state[`d_drivernr_${day}`])}
                              onChange={value => {
                                this.setState({ [`d_driver_${day}`]: value != null ? value.label : null });
                                this.setState({ [`d_drivernr_${day}`]: value != null ? value.value : null });
                              }}
                              onOpen={() => {
                                axios.get(`${api_ipaddress}/api/drivers`,
                                  { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                  .then(res => {
                                    let driver_choices = "data" in res
                                      ? res.data.map(driver => ({ value: driver.dr_id, label: driver.dr_name, groups: driver.dr_groups, requirements: driver.dr_requirements }))
                                      : [];
                                    let subcontractor = this.state[`d_subcontractor_${day}`]
                                    this.setState({
                                      [`d_driver_choices_${day}`]: subcontractor !== null ? driver_choices.filter(
                                        driver => driver.groups.split(';').includes(subcontractor) &&
                                          this.driverHasRequirements(driver, t)
                                      ) : [] });
                                  });
                              }}
                              disabled={!this.state[`d_day_enable_${day}`]}
                              selectChoices={this.state[`d_driver_choices_${day}`]}
                            />,
                            <Tags
                              id={`d_observer_${day}`}
                              tags={this.state[`d_observer_${day}`]}
                              deleteCallback={(value) => { this.setState({ [`d_observer_${day}`]: value }) }}
                              additionCallback={(value) => { this.setState({ [`d_observer_${day}`]: value }) }}
                              select={true}
                              disabled={!this.state[`d_day_enable_${day}`]}
                              selectChoices={observerChoices}
                            />,
                            <div>
                              <CustomCurrencyInput
                                id={`tr_sub_invoice_trip_${day}`}
                                labelText={t("disposition_sub_invoice_trip")}
                                currencySymbol={t("currency_symbol")}
                                validateOnChange={(val, error) => this.setState({ [`d_sub_invoice_trip_${day}`]: val, [`d_sub_invoice_trip_${day}_error`]: error})}
                                inputProps={{
                                  value: this.state[`d_sub_invoice_trip_${day}`] ? this.state[`d_sub_invoice_trip_${day}`] : "",
                                  disabled: localStorage.getItem('role') === "sub",
                                }}
                                required={true}
                                helperText={t("disposition_sub_invoice_trip_error")}
                                error={this.state[`d_sub_invoice_trip_${day}_error`]}
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />
                            </div>,
                            <div>
                              <CustomCurrencyInput
                                id={`tr_sub_invoice_toll_${day}`}
                                labelText={t("disposition_sub_invoice_toll")}
                                currencySymbol={t("currency_symbol")}
                                validateOnChange={(val, error) => this.setState({ [`d_sub_invoice_toll_${day}`]: val, [`d_sub_invoice_toll_${day}_error`]: error})}
                                inputProps={{
                                  value: this.state[`d_sub_invoice_toll_${day}`] ? this.state[`d_sub_invoice_toll_${day}`] : "",
                                  disabled: localStorage.getItem('role') === "sub",
                                }}
                                required={true}
                                helperText={t("disposition_sub_invoice_toll_error")}
                                error={this.state[`d_sub_invoice_toll_${day}_error`]}
                                formControlProps={{
                                  fullWidth: true
                                }}
                              />
                            </div>
                          ])}
                      /></>}

                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <div>
                  {mode === "add" &&
                    <Button
                      color="primary"
                      onClick={() => {
                        const requestBody = this.buildRequestBody();
                        axios.post(`${api_ipaddress}/api/tours`,
                          requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                          .then((res) => {
                            this.clearData();
                            this.showNotification("tc", t(res.data.message));
                            this.props.history.goBack()
                          })
                          .catch((err) => {
                            this.showNotification("tc", t(err.response.data.message));
                          });
                      }}
                      disabled={!this.validateForm()}
                    >
                      {t("disposition_add_disposition")}
                    </Button>}
                  {mode === "edit" &&
                    <Button
                      color="primary"
                      onClick={() => {
                        let tourId = this.props.match.params.id;
                        const requestBody = this.buildRequestBody();
                        axios.put(`${api_ipaddress}/api/tours/${tourId}`,
                          requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                          .then((res) => {
                            this.clearData();
                            this.showNotification("tc", t(res.data.message));
                            this.getDispositionById(tourId);
                            this.props.history.goBack()
                          })
                          .catch((err) => {
                            this.showNotification("tc", t(err.response.data.message));
                          });
                      }}
                      disabled={!this.validateForm()}
                    >
                      {t("general_save")}
                    </Button>}
                  <Button
                    color="white"
                    onClick={() => {
                      this.clearData();
                      this.setState({
                        mode: "overview",
                        alert: false
                      });
                      this.props.history.goBack()
                    }}
                  >
                    {t("general_cancel")}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message ? this.state.message : ""}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </div>
    );
  }
}

EditDisposition.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(EditDisposition));