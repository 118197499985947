import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AlertDialog from "components/Dialog/Dialog.jsx";


import { api_ipaddress } from '../../variables.js'
import axios from 'axios'

import { withTranslation } from 'react-i18next';


const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "add",
      username: "",
      email: "",
      password: "",
      role: "",
      users: [],
      tc: false,
      message: "",
      alert: false,
    };
  }

  componentDidMount() {
    axios.get(`${api_ipaddress}/api/users`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        this.setState({ users: res.data.users });
      });
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const {
      username,
      email,
      password,
      role,
      mode,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  {mode === "add" ? t("users_add_user") : t("users_edit_user")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("users_username")}
                      id="username"
                      inputProps={{
                        value: username,
                        onChange: (event) => this.setState({
                          username: event.target.value
                        }),
                        disabled: mode === "edit"
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("users_email_address")}
                      id="email"
                      inputProps={{
                        value: email,
                        onChange: (event) => this.setState({
                          email: event.target.value
                        })
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={
                        mode === "add" ?
                          t("login_password") :
                          `${t("login_new_password")} (${t("general_optional")})`
                      }
                      id="password"
                      inputProps={{
                        value: password,
                        onChange: (event) => this.setState({
                          password: event.target.value
                        })
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("users_role")}
                      id="role"
                      selectInput
                      inputProps={{
                        value: role,
                        onChange: (event) => this.setState({
                          role: event.target.value
                        })
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                      selectChoices={[
                        { "value": "admin", "label": t("users_role_admin") },
                        { "value": "customer", "label": t("users_role_customer") },
                        { "value": "sub", "label": t("users_role_subcontractor") },
                        { "value": "observer", "label": t("users_role_observer") },
                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                {mode === "add" &&
                  <Button
                    color="primary"
                    onClick={() => {
                      const requestBody = {
                        "username": username,
                        "email": email,
                        "password": password,
                        "role": role,
                      }
                      axios.post(`${api_ipaddress}/api/registration`,
                        requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                        .then((res) => {
                          this.setState({
                            username: "",
                            email: "",
                            password: "",
                            role: "",
                          })
                          this.showNotification("tc", t(res.data.message));
                          axios.get(`${api_ipaddress}/api/users`,
                            { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                            .then(res => {
                              this.setState({ users: res.data.users });
                            });
                        })
                        .catch((err) => {
                          console.log(err);
                          this.showNotification("tc", t(err.response.data.message));
                        });
                    }}
                    disabled={
                      username === "" || email === "" ||
                      password === "" || role === ""
                    }
                  >
                    {t("users_add_user")}
                  </Button>}
                {mode === "edit" &&
                  <div>
                    <Button
                      color="primary"
                      onClick={() => {
                        const requestBody = {
                          "email": email,
                          "password": password,
                          "role": role,
                        }
                        axios.put(`${api_ipaddress}/api/users/${username}`,
                          requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                          .then((res) => {
                            this.setState({
                              username: "",
                              email: "",
                              password: "",
                              role: "",
                              mode: "add",
                            })
                            this.showNotification("tc", t(res.data.message));
                            axios.get(`${api_ipaddress}/api/users`,
                              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                              .then(res => {
                                this.setState({ users: res.data.users });
                              });
                          })
                          .catch((err) => {
                            console.log(err);
                            this.showNotification("tc", t(err.response.data.message));
                          });
                      }}
                      disabled={
                        username === "" || email === "" ||
                        role === ""
                      }
                    >
                      {t("general_save")}
                    </Button>
                    <Button
                      color="white"
                      onClick={() => {
                        this.setState({
                          mode: "add",
                          username: "",
                          email: "",
                          password: "",
                          role: "",
                        })
                      }}
                    >
                      {t("general_cancel")}
                    </Button>
                  </div>
                }
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("users_saved_users")}</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    { value: t("users_username") },
                    { value: t("users_email_address") },
                    { value: t("users_role") },
                    { value: t("users_edit_user") }
                  ]}
                  tableData={this.state.users.map(user => [
                    user.username,
                    user.email,
                    {
                      "admin": t("users_role_admin"),
                      "customer": t("users_role_customer"),
                      "sub": t("users_role_subcontractor"),
                      "observer": t("users_role_observer"),
                    }[user.role],
                    <div>
                      {user.username !== "Datahouse" &&
                        <IconButton
                          aria-label="Edit"
                          style={{ padding: 0, marginRight: 10 }}
                          onClick={() => {

                            this.setState({
                              mode: "edit",
                              username: user.username,
                              email: user.email,
                              password: "",
                              role: user.role,
                            })
                          }}
                        >
                          <EditIcon />
                        </IconButton>}
                      {(user.username !== "Datahouse" &&
                        user.username !== localStorage.getItem("username")) &&
                        <IconButton
                          aria-label="Delete"
                          style={{ padding: 0 }}
                          onClick={() => {
                            this.setState({
                              alert: true,
                              clickedUser: user.username,
                            })
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>}
                    </div>
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <AlertDialog
          open={this.state.alert}
          onClose={() => this.setState({ alert: false })}
          handleNo={() => this.setState({ alert: false })}
          handleYes={() =>
            axios.delete(`${api_ipaddress}/api/users/${this.state.clickedUser}`,
              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
              .then((res) => {
                axios.get(`${api_ipaddress}/api/users`,
                  { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                  .then(res => {
                    this.setState({
                      users: res.data.users,
                      alert: false,
                      mode: "add",
                      username: "",
                      email: "",
                      password: "",
                      role: "",
                    });
                  });
                this.showNotification("tc", t(res.data.message));
              })
              .catch((err) => {
                console.log(err);
                this.showNotification("tc", t(err.response.data.message));
              })
          }
          title={`User ${this.state.clickedUser} löschen?`}
          content={"Dies löscht den User aus der Datenbank."}
        />
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Users));
