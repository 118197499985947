import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { api_ipaddress } from '../../variables.js'
import axios from 'axios'

import { withTranslation } from 'react-i18next';

const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      new_password_1: "",
      new_password_2: "",
      tc: false,
      message: "",
    };
  }

  showNotification(place, message) {
    this.setState({
      [place] : true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function() {
        this.setState({[place]: false});
      }.bind(this),
      6000
    );
  }

  
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const {
      old_password,
      new_password_1,
      new_password_2,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("login_change_password")}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("login_old_password")}
                      id="old_password"
                      inputProps={{
                        value: old_password,
                        onChange: (event) => this.setState({
                          old_password: event.target.value
                        }),
                        type: "password"
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("login_new_password")}
                      id="new_password_1"
                      inputProps={{
                        value: new_password_1,
                        onChange: (event) => this.setState({
                          new_password_1: event.target.value
                        }),
                        type: "password"
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("login_repeat_new_password")}
                      id="new_password_2"
                      inputProps={{
                        value: new_password_2,
                        onChange: (event) => this.setState({
                          new_password_2: event.target.value
                        }),
                        type: "password"
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={() => {
                    let requestBody = {
                      username: localStorage.getItem('username'),
                      password: old_password,
                      newpassword: new_password_1
                    }
                    axios.post(`${api_ipaddress}/api/changepw`, requestBody, 
                    { headers: {"Authorization" : `Bearer ${localStorage.getItem('access_token')}`} })
                      .then((res) => {
                        this.setState({
                          old_password: "",
                          new_password_1: "",
                          new_password_2: ""
                        })
                        this.showNotification("tc", t(res.data.message));
                      })
                      .catch((err) => {
                        console.log(err);
                        this.showNotification("tc", t(err.response.data.message));
                      });
                  }}
                  disabled={
                    old_password === "" || new_password_1 === "" || new_password_2 === "" ||
                    new_password_1 !== new_password_2
                  }
                >
                  {t("login_change_password")}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Users));
