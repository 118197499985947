import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// core components
import customCheckboxStyle from "assets/jss/material-dashboard-react/components/customCheckboxStyle.jsx";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";


function CustomCheckbox({ ...props }) {
  const {
    formControlProps,
    labelText,
    id,
    inputProps,
  } = props;
  return (
    <FormControlLabel
      {...formControlProps}
      disabled={props.disabled}
      control={
        <Checkbox
          id={id}
          {...inputProps}
          color="default"
          style={{ color: primaryColor[0] }}
          value="checkedA"
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />}
      label={<label>{labelText}</label>}
    />
  );
}

export default withStyles(customCheckboxStyle)(CustomCheckbox);
