import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AlertDialog from "components/Dialog/Dialog.jsx";

import { api_ipaddress } from '../../variables.js'
import axios from 'axios'

import { withTranslation } from 'react-i18next';

import { sprintf } from "sprintf-js";

const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "add", // shows table, or add / edit
      customers: [],
      customerId: "",
      cm_name: "",
    };
  }

  componentDidMount() {
    axios.get(`${api_ipaddress}/api/customers/all`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        this.setState({ customers: res.data });
      });
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const {
      mode,
      cm_name,
      cm_comatic_id,
      cm_reference_1,
      cm_reference_2,
      cm_reference_3,
      cm_reference_4,
      cm_reference_5,
      customerId
    } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  {t("customer_edit_customer")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText={t("customer_name")}
                          id="name"
                          inputProps={{
                              value: cm_name,
                              onChange: (event) => this.setState({
                                  cm_name: event.target.value
                              }),
                              disabled: localStorage.getItem('role') === "sub"
                          }}
                          required={true}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText={t("customer_comatic_id")}
                          id="comatic_id"
                          inputProps={{
                              value: cm_comatic_id,
                              onChange: (event) => this.setState({
                                  cm_comatic_id: event.target.value
                              }),
                              disabled: localStorage.getItem('role') === "sub"
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText={t("customer_reference_1")}
                          id="reference_1"
                          inputProps={{
                              value: cm_reference_1,
                              onChange: (event) => this.setState({
                                  cm_reference_1: event.target.value
                              }),
                              disabled: localStorage.getItem('role') === "sub"
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText={t("customer_reference_2")}
                          id="reference_2"
                          inputProps={{
                              value: cm_reference_2,
                              onChange: (event) => this.setState({
                                  cm_reference_2: event.target.value
                              }),
                              disabled: localStorage.getItem('role') === "sub"
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText={t("customer_reference_3")}
                          id="reference_3"
                          inputProps={{
                              value: cm_reference_3,
                              onChange: (event) => this.setState({
                                  cm_reference_3: event.target.value
                              }),
                              disabled: localStorage.getItem('role') === "sub"
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText={t("customer_reference_4")}
                          id="reference_4"
                          inputProps={{
                              value: cm_reference_4,
                              onChange: (event) => this.setState({
                                  cm_reference_4: event.target.value
                              }),
                              disabled: localStorage.getItem('role') === "sub"
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText={t("customer_reference_5")}
                          id="reference_5"
                          inputProps={{
                              value: cm_reference_5,
                              onChange: (event) => this.setState({
                                  cm_reference_5: event.target.value
                              }),
                              disabled: localStorage.getItem('role') === "sub"
                          }}
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <div>
                  {mode === "add" &&
                    <Button
                      color="primary"
                      onClick={() => {
                        const requestBody = {
                          "cm_name": cm_name,
                          "cm_comatic_id": cm_comatic_id ? cm_comatic_id : null,
                          "cm_reference_1": cm_reference_1 ? cm_reference_1 : null,
                          "cm_reference_2": cm_reference_2 ? cm_reference_2 : null,
                          "cm_reference_3": cm_reference_3 ? cm_reference_3 : null,
                          "cm_reference_4": cm_reference_4 ? cm_reference_4 : null,
                          "cm_reference_5": cm_reference_5 ? cm_reference_5 : null,
                        }
                        axios.post(`${api_ipaddress}/api/customers`,
                          requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                          .then((res) => {
                            this.setState({
                              mode: "add",
                              cm_name: "",
                              cm_comatic_id: "",
                              cm_reference_1: "",
                              cm_reference_2: "",
                              cm_reference_3: "",
                              cm_reference_4: "",
                              cm_reference_5: "",
                            })
                            this.showNotification("tc", t(res.data.message));
                            axios.get(`${api_ipaddress}/api/customers/all`,
                              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                              .then(res => {
                                this.setState({ customers: res.data });
                              });
                          })
                          .catch((err) => {
                            this.showNotification("tc", t(err.response.data.message));
                          });
                      }}
                    >
                      {t("customer_add_customer")}
                    </Button>}
                  {mode === "edit" &&
                    <div>
                      <Button
                        color="primary"
                        onClick={() => {
                          const requestBody = {
                            "cm_name": cm_name,
                            "cm_comatic_id": cm_comatic_id,
                            "cm_reference_1": cm_reference_1,
                            "cm_reference_2": cm_reference_2,
                            "cm_reference_3": cm_reference_3,
                            "cm_reference_4": cm_reference_4,
                            "cm_reference_5": cm_reference_5,
                          }
                          axios.put(`${api_ipaddress}/api/customers/${customerId}`,
                            requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                            .then((res) => {
                              this.setState({
                                mode: "add",
                                cm_name: "",
                                cm_comatic_id: "",
                                cm_reference_1: "",
                                cm_reference_2: "",
                                cm_reference_3: "",
                                cm_reference_4: "",
                                cm_reference_5: "",
                              })
                              this.showNotification("tc", t(res.data.message));
                              axios.get(`${api_ipaddress}/api/customers/all`,
                                { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                .then(res => {
                                  this.setState({ customers: res.data });
                                });
                            })
                            .catch((err) => {
                              this.showNotification("tc", t(err.response.data.message));
                            });
                        }}
                      >
                        {t("customer_edit_customer")}
                      </Button>
                      <Button
                        color="white"
                        onClick={() => {
                          this.setState({
                            mode: "add",
                            cm_name: "",
                            cm_comatic_id: "",
                            cm_reference_1: "",
                            cm_reference_2: "",
                            cm_reference_3: "",
                            cm_reference_4: "",
                            cm_reference_5: ""
                          })
                        }}
                      >
                        {t("general_cancel")}
                      </Button>
                    </div>
                  }
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>{t("customer_saved_customers")}</h4>
                <IconButton
                  aria-label="add"
                  style={{ padding: 0, color: "white" }}
                  onClick={() => {
                    this.setState({
                      mode: "add"
                    })
                  }}
                >
                  <AddIcon />
                </IconButton>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    { value: t("customer_name") },
                    { value: t("customer_comatic_id") },
                    { value: t("customer_reference_1") },
                    { value: t("customer_reference_2") },
                    { value: t("customer_reference_3") },
                    { value: t("customer_reference_4") },
                    { value: t("customer_reference_5") },
                    { value: t("customer_edit_customer"), props: { align:"right" } },
                  ]}
                  tableData={
                    this.state.customers.map(cm => [
                      cm.cm_name,
                      cm.cm_comatic_id,
                      cm.cm_reference_1,
                      cm.cm_reference_2,
                      cm.cm_reference_3,
                      cm.cm_reference_4,
                      cm.cm_reference_5,
                      <div style={{ float: "right" }}>
                        <IconButton
                          aria-label="Edit"
                          style={{ padding: 0, marginRight: 10 }}
                          onClick={() => {
                            this.setState({
                              mode: "edit",
                              customerId: cm.cm_id,
                              cm_name: cm.cm_name,
                              cm_comatic_id: cm.cm_comatic_id,
                              cm_reference_1: cm.cm_reference_1,
                              cm_reference_2: cm.cm_reference_2,
                              cm_reference_3: cm.cm_reference_3,
                              cm_reference_4: cm.cm_reference_4,
                              cm_reference_5: cm.cm_reference_5,
                            })
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Delete"
                          style={{ padding: 0 }}
                          onClick={() => {
                            this.setState({
                              alert: true,
                              clickedCustomer: cm.cm_id,
                              clickedCustomerName: cm.cm_name,
                            })
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <AlertDialog
          open={this.state.alert}
          onClose={() => this.setState({ alert: false })}
          handleNo={() => this.setState({ alert: false })}
          handleYes={() =>
            axios.delete(`${api_ipaddress}/api/customers/${this.state.clickedCustomer}`,
              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
              .then((res) => {
                this.showNotification("tc", t(res.data.message));
                axios.get(`${api_ipaddress}/api/customers/all`,
                  { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                  .then(res => {
                    this.setState({
                      alert: false,
                      mode: "add",
                      cm_name: "",
                      cm_comatic_id: "",
                      cm_reference_1: "",
                      cm_reference_2: "",
                      cm_reference_3: "",
                      cm_reference_4: "",
                      cm_reference_5: "",
                      customers: res.data
                    });
                  });
              })
              .catch((err) => {
                this.showNotification("tc", t(err.response.data.message));
              })
          }
          title={sprintf(t("customer_delete_warning_title"), this.state.clickedCustomerName)}
          content={t("customer_delete_warning_message")}
        />
      </div>
    );
  }
}

Customers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Customers));
