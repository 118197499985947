import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

// core components
import collapseButtonStyle from "assets/jss/material-dashboard-react/components/collapseButtonStyle.jsx";

function CollapseButton(props) {
  return (
    <Grid container direction="row">
      <Grid item>
        {props.title}
      </Grid>
      <Grid item>
        {props.openStatus ? <ExpandLess /> : <ExpandMore />}
      </Grid>
    </Grid>
  );
}

export default withStyles(collapseButtonStyle)(CollapseButton);
