import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCurrencyInput from "components/CustomInput/CustomCurrencyInput.jsx"
import CustomAutoComplete from "components/CustomInput/CustomAutocomplete.jsx";
import CustomCheckbox from "components/CustomInput/CustomCheckbox.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Tags from "components/Tags/Tags.jsx";

import {api_ipaddress} from '../../variables.js'
import axios from 'axios'

import 'moment/locale/de-ch'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import CustomDateTime from "../../components/CustomInput/CustomDateTime.jsx";

import {withTranslation} from 'react-i18next';
import {floatToText, textToFloat} from "../../functions/helpers";

var moment = require('moment-timezone');

const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

class Edit extends React.Component {
  constructor(props) {
    super(props);

    const { t } = props;

    let availableStatus = [
      { "value": "active", "label": t("dashboard_active") },
      { "value": "inactive", "label": t("dashboard_inactive") },
    ];

    if (localStorage.getItem('role') === "admin") {
      availableStatus.push({ "value": "scheduled", "label": t("dashboard_scheduled") })
      availableStatus.push({ "value": "canceled", "label": t("dashboard_canceled") })
    }

    this.state = {
      tt_tour: "",
      tt_customer: "",
      tt_status: "active",
      tt_last_edit: "",
      tt_from_adrnr: "",
      tt_from_text: "",
      tt_to_adrnr: "",
      tt_to_text: "",
      tt_sub: "",
      tt_driver: "",
      tt_drivernr: "",
      tt_gps: "",
      tt_stp_error: false,
      tt_stp: "",
      tt_std_error: false,
      tt_std: "",
      tt_sta_error: false,
      tt_sta: "",
      tt_atp_error: false,
      tt_atp: "",
      tt_atd_error: false,
      tt_atd: "",
      tt_ata_error: false,
      tt_ata: "",
      tt_note: "",
      tt_info: "",
      tt_invoice_check: true,
      tt_customer_invoice_trip: '0.00',
      tt_customer_invoice_trip_error: false,
      tt_customer_invoice_toll: '0.00',
      tt_customer_invoice_toll_error: false,
      tt_customer_invoice_waiting_fee: '0.00',
      tt_customer_invoice_waiting_fee_error: false,
      tt_customer_invoice_cancellation_fee: '0.00',
      tt_customer_invoice_cancellation_fee_error: false,
      tt_customer_invoice_text: "",
      tt_sub_invoice_trip: '0.00',
      tt_sub_invoice_trip_error: false,
      tt_sub_invoice_toll: '0.00',
      tt_sub_invoice_toll_error: false,
      tt_sub_invoice_waiting_fee: '0.00',
      tt_sub_invoice_waiting_fee_error: false,
      tt_sub_invoice_cancellation_fee: '0.00',
      tt_sub_invoice_cancellation_fee_error: false,
      tt_sub_invoice_comment: "",
      customer: "",
      tc: false,
      message: "",
      chosenObservers: [],
      observerChoices: [],
      customerChoices: [],
      subcontractorChoices: [],
      vehicles: [],
      drivers: [],
      users: [],
      observers: [],
      statusChoices: availableStatus
    };
  }


  componentDidMount() {
    moment.tz.setDefault();

    axios.get(`${api_ipaddress}/api/tours`,
      {
        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
        params: {
          role: localStorage.getItem('role'),
          username: localStorage.getItem('username')
        }
      })
      .then(res => {
        this.setState({ tours: res.data });
      });

    if (localStorage.getItem('role') === "admin") {
      axios.get(`${api_ipaddress}/api/customers/all`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          let customer_choices = "data" in res
            ? res.data.map(customer => ({ value: customer.cm_id, label: customer.cm_name }))
            : [];
          this.setState({ customerChoices: customer_choices });
        });

      axios.get(`${api_ipaddress}/api/users`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          this.setState({ users: res.data.users });
        });
    }

    axios.get(`${api_ipaddress}/api/gps`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        let vehicle_choices = "data" in res
          ? res.data.map(gps => ({ value: gps.gps_id, label: gps.gps_id, groups: gps.gps_groups }))
          : [];
        this.setState({ vehicles: vehicle_choices });
      });

    if (localStorage.getItem('role') === "admin") {
      axios.get(`${api_ipaddress}/api/address`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          this.setState({ address: res.data });
        });
    }
    if(localStorage.getItem('role') === "admin"){
      axios.get(`${api_ipaddress}/api/observers`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          this.setState({ observers: res.data });
        });
    }
    axios.get(`${api_ipaddress}/api/subcontractors`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        let subcontractor_choices = "data" in res
          ? res.data.map(subcontractor => ({ value: subcontractor.sc_name, label: subcontractor.sc_name }))
          : [];

        this.setState({ subcontractorChoices: subcontractor_choices });
      });

    axios.get(`${api_ipaddress}/api/drivers`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        let driver_choices = "data" in res
          ? res.data.map(driver => ({ value: driver.dr_id, label: driver.dr_name, groups: driver.dr_groups }))
          : [];

        this.setState({ drivers: driver_choices });
      });

    if (Number.isInteger(parseInt(this.props.match.params.id))) {
      axios.get(`${api_ipaddress}/api/trips/${this.props.match.params.id}`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
        .then(res => {
          if (res.data.tt_stp !== null) {
            res.data.tt_stp = moment(res.data.tt_stp);
            this.setState({ tt_stp_error: !res.data.tt_stp._isValid });
          } else {
            res.data.tt_stp = null;
            this.setState({ tt_stp_error: true });
          }

          if (res.data.tt_std !== null) {
            res.data.tt_std = moment(res.data.tt_std);
            this.setState({ tt_std_error: !res.data.tt_std._isValid });
          } else {
            res.data.tt_std = null;
            this.setState({ tt_std_error: true });
          }

          if (res.data.tt_sta !== null) {
            res.data.tt_sta = moment(res.data.tt_sta);
            this.setState({ tt_sta_error: !res.data.tt_sta._isValid });
          } else {
            res.data.tt_sta = null;
            this.setState({ tt_sta_error: true });
          }

          if (res.data.tt_atp !== null) {
            res.data.tt_atp = moment(res.data.tt_atp);
            this.setState({ tt_atp_error: !res.data.tt_atp._isValid });
          } else {
            res.data.tt_atp = null;
          }

          if (res.data.tt_atd !== null) {
            res.data.tt_atd = moment(res.data.tt_atd);
            this.setState({ tt_atd_error: !res.data.tt_atd._isValid });
          } else {
            res.data.tt_atd = null;
          }

          if (res.data.tt_ata !== null) {
            res.data.tt_ata = moment(res.data.tt_ata);
            this.setState({ tt_ata_error: !res.data.tt_ata._isValid });
          } else {
            res.data.tt_ata = null;
          }

          if (res.data.tt_last_edit !== null) {
            res.data.tt_last_edit = moment(res.data.tt_last_edit);
          } else {
            res.data.tt_last_edit = null;
          }

          if (res.data.tt_customer_invoice_trip) {
            res.data.tt_customer_invoice_trip = floatToText(res.data.tt_customer_invoice_trip);
          } else {
            res.data.tt_customer_invoice_trip = '0.00';
          }

          if (res.data.tt_customer_invoice_toll) {
            res.data.tt_customer_invoice_toll = floatToText(res.data.tt_customer_invoice_toll);
          } else {
            res.data.tt_customer_invoice_toll = '0.00';
          }

          if (res.data.tt_customer_invoice_waiting_fee) {
            res.data.tt_customer_invoice_waiting_fee = floatToText(res.data.tt_customer_invoice_waiting_fee);
          } else {
            res.data.tt_customer_invoice_waiting_fee = '0.00';
          }

          if (res.data.tt_customer_invoice_cancellation_fee) {
            res.data.tt_customer_invoice_cancellation_fee = floatToText(res.data.tt_customer_invoice_cancellation_fee);
          } else {
            res.data.tt_customer_invoice_cancellation_fee = '0.00';
          }

          if (res.data.tt_sub_invoice_trip) {
            res.data.tt_sub_invoice_trip = floatToText(res.data.tt_sub_invoice_trip);
          } else {
            res.data.tt_sub_invoice_trip = '0.00';
          }

          if (res.data.tt_sub_invoice_toll) {
            res.data.tt_sub_invoice_toll = floatToText(res.data.tt_sub_invoice_toll);
          } else {
            res.data.tt_sub_invoice_toll = '0.00';
          }

          if (res.data.tt_sub_invoice_waiting_fee) {
            res.data.tt_sub_invoice_waiting_fee = floatToText(res.data.tt_sub_invoice_waiting_fee);
          } else {
            res.data.tt_sub_invoice_waiting_fee = '0.00';
          }

          if (res.data.tt_sub_invoice_cancellation_fee) {
            res.data.tt_sub_invoice_cancellation_fee = floatToText(res.data.tt_sub_invoice_cancellation_fee);
          } else {
            res.data.tt_sub_invoice_cancellation_fee = '0.00';
          }


          this.setState(res.data);

          if (localStorage.getItem('role') === "admin") {
            let customersAndObservers = res.data.tt_customer !== null ? res.data.tt_customer.split(';') : [];

            if (customersAndObservers.length > 0) {
              // bugfix - we need to wait for customers and users options before selecting a specific value
              // first make new request for customers
              axios.get(`${api_ipaddress}/api/customers/all`,
              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
              .then(res => {
                let customer_choices = "data" in res
                  ? res.data.map(customer => ({ value: customer.cm_id, label: customer.cm_name }))
                  : [];
                this.setState({ customerChoices: customer_choices });
                // then make new request for users
                axios.get(`${api_ipaddress}/api/users`,
                { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                .then(res => {
                  this.setState({ users: res.data.users });
                  // only then select values from this trip
                  // before the following code was used directly without waiting for the request responses
                  let customerVal = this.state.customerChoices.find(u => customersAndObservers[0] === u.label);
                  this.setState({
                    customer: customerVal !== undefined ? customerVal : '',
                  });
                  customersAndObservers = customersAndObservers.splice(1)

                  let usersInCustomers = this.state.users.filter(u => customersAndObservers.includes(u.username));
                  if (usersInCustomers.length > 0) {
                    this.setState({
                      chosenObservers: usersInCustomers.filter(u => u.role === "observer").map(u => ({ label: u.username, value: u.id }))
                    });
                  }
                  // end of code that was used directly
                });
              });
            }
          }
        })
    }
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  isDisabled(stp) {
    if(localStorage.getItem('role') !== "admin"){
      let tripDate = moment(stp);
      let dateToday = moment();
      return tripDate <= dateToday;
    }
    return false
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const {
      tt_tour,
      tt_status,
      tt_from_adrnr,
      tt_to_adrnr,
      tt_from_text,
      tt_to_text,
      tt_sub,
      tt_driver,
      tt_drivernr,
      tt_gps,
      tt_stp_error,
      tt_stp,
      tt_std_error,
      tt_std,
      tt_sta_error,
      tt_sta,
      tt_atp_error,
      tt_atp,
      tt_atd_error,
      tt_atd,
      tt_ata_error,
      tt_ata,
      tt_note,
      tt_info,
      tt_invoice_check,
      tt_customer_invoice_trip,
      tt_customer_invoice_trip_error,
      tt_customer_invoice_toll,
      tt_customer_invoice_toll_error,
      tt_customer_invoice_waiting_fee,
      tt_customer_invoice_waiting_fee_error,
      tt_customer_invoice_cancellation_fee,
      tt_customer_invoice_cancellation_fee_error,
      tt_customer_invoice_text,
      tt_sub_invoice_trip,
      tt_sub_invoice_trip_error,
      tt_sub_invoice_toll,
      tt_sub_invoice_toll_error,
      tt_sub_invoice_waiting_fee,
      tt_sub_invoice_waiting_fee_error,
      tt_sub_invoice_cancellation_fee,
      tt_sub_invoice_cancellation_fee_error,
      tt_sub_invoice_comment,
      tt_last_edit,
      statusChoices,
      subcontractorChoices,
      customerChoices,
      chosenObservers,
      customer,
      observers,
      drivers,
      vehicles,
    } = this.state;

    const add_choices = "address" in this.state ?
      this.state.address.map(item =>
        ({ value: item.add_id, name: item.add_name, label: `${item.add_name} (${item.add_id})` })
      ) :
      (tt_to_adrnr !== "") ?
        [
          { value: tt_from_adrnr, name: tt_from_text, label: `${tt_from_text} (${tt_from_adrnr})` },
          { value: tt_to_adrnr, name: tt_from_text, label: `${tt_to_adrnr} (${tt_to_text})` }
        ] : []

    const tours_choices = "tours" in this.state ?
      this.state.tours.map(el =>
        ({
          value: el[0],
          label: `${el[1]}, ${t("general_from")} ${el[2]} ${t("general_to")} ${el[3]}, 
          ${moment(el[4]).format("dd DD.MM.YYYY HH:mm")}, ${t("dashboard_car")} ${el[5]},
          ${t("dashboard_driver")} ${el[6]}`
        })
      ) : []

    let vehicleChoices = (tt_sub != null && tt_sub !== "") ? vehicles.filter(
      gps => gps.groups.split(';').includes(tt_sub.toLowerCase()) || gps.groups === ""
    ) : [];

    let driverChoices = (tt_sub != null && tt_sub !== "") ? drivers.filter(
      driver => driver.groups.split(';').includes(tt_sub)
    ) : [];

    let isDisabled = localStorage.getItem('role') !== "admin" || (localStorage.getItem('role') === "sub" && tt_status !== "active");

    let observerChoices = observers.map(observer => ({ value: observer, label: observer }))

    return (
      <div>
        <GridContainer>
          <GridItem md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("edit_edit_area")}</h4>
              </CardHeader>
              <CardBody>
                {
                  (Number.isInteger(parseInt(this.props.match.params.id))
                    || this.props.match.params.id === "neu") ?
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText={t("dashboard_tour_nr")}
                            id="tour"
                            inputProps={{
                              value: tt_tour,
                              onChange: (event) => this.setState({
                                tt_tour: event.target.value
                              }),
                              disabled: localStorage.getItem('role') !== "admin"
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        {localStorage.getItem('role') === "admin" &&
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomAutoComplete
                              labelText={"dashboard_customer"}
                              id="customer"
                              onChange={value => { this.setState({ customer: value }) }}
                              value={customerChoices.filter(el => el === customer)}
                              selectChoices={customerChoices}
                              disabled={isDisabled}
                            />
                          </GridItem>
                        }
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText={t("dashboard_status")}
                            id="status"
                            selectInput
                            inputProps={{
                              value: tt_status,
                              onChange: (event) => this.setState({
                                tt_status: event.target.value
                              })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                            selectChoices={statusChoices}
                            disabled={localStorage.getItem('role') === "sub"}
                          />
                        </GridItem>
                        {localStorage.getItem('role') === "admin" &&
                          <GridItem xs={12} sm={12} md={12}>
                            <Tags
                              id="observers"
                              labelField={t("dashboard_observers")}
                              tags={chosenObservers}
                              deleteCallback={(value) => { this.setState({ chosenObservers: value }) }}
                              additionCallback={(value) => { this.setState({ chosenObservers: value }) }}
                              select={true}
                              selectChoices={observerChoices}
                            />
                          </GridItem>
                        }
                      </GridContainer>
                      <GridContainer>
                        {localStorage.getItem('role') === "admin" &&
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomAutoComplete
                              labelText={"general_from"}
                              id="from_adrnr"
                              value={add_choices.filter(el => el.value === tt_from_adrnr)}
                              onChange={value => {
                                if (value == null) {
                                  this.setState({
                                    tt_from_adrnr: "",
                                    tt_from_text: "",
                                  })
                                } else {
                                  this.setState({
                                    tt_from_adrnr: value.value,
                                    tt_from_text: value.name,
                                  })
                                }
                              }}
                              selectChoices={add_choices}
                            />
                          </GridItem>}
                        {localStorage.getItem('role') === "sub" &&
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText={t("general_from")}
                              id="from_adr_text"
                              inputProps={{
                                value: tt_from_text,
                                disabled: true
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        }
                        {localStorage.getItem('role') === "admin" &&
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomAutoComplete
                              labelText={"general_to"}
                              id="to_adrnr"
                              value={add_choices.filter(el => el.value === tt_to_adrnr)}
                              onChange={value => {
                                if (value == null) {
                                  this.setState({
                                    tt_to_adrnr: "",
                                    tt_to_text: "",
                                  })
                                } else {
                                  this.setState({
                                    tt_to_adrnr: value.value,
                                    tt_to_text: value.name,
                                  })
                                }
                              }}
                              selectChoices={add_choices}
                            />
                          </GridItem>}
                        {localStorage.getItem('role') === "sub" &&
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText={t("general_to")}
                              id="to_adr_text"
                              inputProps={{
                                value: tt_to_text,
                                disabled: true
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        }
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomAutoComplete
                            id="sub"
                            labelText={"dashboard_subcontractor"}
                            value={subcontractorChoices.filter(el => el.value === tt_sub)}
                            onChange={opt => {
                              this.setState({
                                tt_sub: opt !== null ? opt.value : null,
                                vehicleChoices: opt !== null ? vehicles.filter(
                                  gps => gps.groups.split(';').includes(opt.value.toLowerCase()) || gps.groups === ""
                                ) : [],
                                driverChoices: opt !== null ? drivers.filter(
                                  driver => driver.groups.split(';').includes(opt.value)
                                ) : []
                              })
                            }}
                            disabled={localStorage.getItem('role') !== "admin"}
                            selectChoices={subcontractorChoices}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomAutoComplete
                            id="driver"
                            labelText={"dashboard_driver"}
                            value={driverChoices.filter(el => el.label === tt_driver)}
                            onChange={opt => this.setState({
                              tt_driver: opt !== null ? opt.label : null,
                              tt_drivernr: opt !== null ? opt.value : null
                            })}
                            selectChoices={driverChoices}
                            disabled={this.isDisabled(tt_stp)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomAutoComplete
                            labelText={"dashboard_car"}
                            id="gps"
                            value={vehicleChoices.filter(el => el.value === tt_gps)}
                            onChange={opt => this.setState({ tt_gps: opt !== null ? opt.value : null })}
                            selectChoices={vehicleChoices}
                            disabled={this.isDisabled(tt_stp)}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomDateTime
                            inputProps={{
                              label: "STP",
                              id: "stp",
                              value: tt_stp,
                              onChange: (date) => {
                                if (date === null || date === undefined)
                                  this.setState({ tt_stp_error: true })
                                else
                                  this.setState({ tt_stp: date, tt_stp_error: !date._isValid })
                              },
                              onClear: () => this.setState({ tt_stp: null, tt_stp_error: true }),
                              error: tt_stp_error,
                              required: true,
                              disabled: isDisabled
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomDateTime
                            inputProps={{
                              label: "STD",
                              id: "std",
                              value: tt_std,
                              onChange: (date) => {
                                if (date === null || date === undefined)
                                  this.setState({ tt_std_error: true })
                                else
                                  this.setState({ tt_std: date, tt_std_error: !date._isValid })
                              },
                              onClear: () => this.setState({ tt_std: null, tt_std_error: true }),
                              error: tt_std_error,
                              required: true,
                              disabled: isDisabled
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomDateTime
                            inputProps={{
                              label: "STA",
                              id: "sta",
                              value: tt_sta,
                              onChange: (date) => {
                                if (date === null || date === undefined)
                                  this.setState({ tt_sta_error: true })
                                else
                                  this.setState({ tt_sta: date, tt_sta_error: !date._isValid })
                              },
                              onClear: () => this.setState({ tt_sta: null, tt_sta_error: true }),
                              error: tt_sta_error,
                              required: true,
                              disabled: isDisabled
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomDateTime
                            inputProps={{
                              label: "ATP",
                              id: "atp",
                              value: tt_atp,
                              onChange: (date) => this.setState({ tt_atp: date, tt_atp_error: date != null && !date._isValid }),
                              onClear: () => this.setState({ tt_atp: null, tt_atp_error: false }),
                              disabled: localStorage.getItem('role') !== "admin",
                              error: tt_atp_error
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomDateTime
                            inputProps={{
                              label: "ATD",
                              id: "atd",
                              value: tt_atd,
                              onChange: (date) => this.setState({ tt_atd: date, tt_atd_error: date != null && !date._isValid }),
                              onClear: () => this.setState({ tt_atd: null, tt_atd_error: false }),
                              disabled: localStorage.getItem('role') !== "admin",
                              error: tt_atd_error
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomDateTime
                            inputProps={{
                              label: "ATA",
                              id: "ata",
                              value: tt_ata,
                              onChange: (date) => this.setState({ tt_ata: date, tt_ata_error: !date._isValid }),
                              onClear: () => this.setState({ tt_ata: null, tt_ata_error: false }),
                              disabled: localStorage.getItem('role') !== "admin",
                              error: tt_ata_error
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        {localStorage.getItem('role') === "admin" &&
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText={t("edit_notes")}
                              id="notes"
                              inputProps={{
                                multiline: true,
                                value: tt_note,
                                onChange: (event) => this.setState({
                                  tt_note: event.target.value
                                }),
                                disabled: isDisabled
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        }
                        {(localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "sub") &&
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText={t("edit_info")}
                              id="info"
                              inputProps={{
                                multiline: true,
                                value: tt_info,
                                onChange: (event) => this.setState({
                                  tt_info: event.target.value
                                }),
                                disabled: localStorage.getItem('role') !== "admin"
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        }
                        { localStorage.getItem('role') === "admin" && <>
                            <GridItem xs={12} sm={12} md={12}>
                              <h5>{t("edit_invoice_title")}</h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomCheckbox
                                  labelText={t("edit_invoice_check_yes")}
                                  id="invoice_check"
                                  inputProps={{
                                    checked: tt_invoice_check,
                                    onChange: (event) => this.setState({
                                      tt_invoice_check: event.target.checked
                                    })
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <h5>{t("edit_invoice_customer_title")}</h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_customer_invoice_trip`}
                                  labelText={t("edit_customer_invoice_trip")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange={(val, error) => this.setState({'tt_customer_invoice_trip': val, 'tt_customer_invoice_trip_error': error })}
                                  inputProps={{
                                      value: this.state['tt_customer_invoice_trip'],
                                  }}
                                  required={true}
                                  helperText={t("edit_customer_invoice_trip_error")}
                                  error={tt_customer_invoice_trip_error}
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_customer_invoice_toll`}
                                  labelText={t("edit_customer_invoice_toll")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange= {(val, error) => this.setState({'tt_customer_invoice_toll': val, 'tt_customer_invoice_toll_error': error})}
                                  inputProps={{
                                      value: this.state['tt_customer_invoice_toll'],
                                  }}
                                  required={true}
                                  helperText={t("edit_customer_invoice_toll_error")}
                                  error={tt_customer_invoice_toll_error}
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_customer_invoice_waiting_fee`}
                                  labelText={t("edit_customer_invoice_waiting_fee")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange= {(val, error) => this.setState({'tt_customer_invoice_waiting_fee': val, 'tt_customer_invoice_waiting_fee_error': error})}
                                  inputProps={{
                                    value: this.state[`tt_customer_invoice_waiting_fee`],
                                  }}
                                  required={true}
                                  helperText={t("edit_customer_invoice_waiting_fee_error")}
                                  error={tt_customer_invoice_waiting_fee_error}
                                  formControlProps={{
                                    fullWidth: true
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_customer_invoice_cancellation_fee`}
                                  labelText={t("edit_customer_invoice_cancellation_fee")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange= {(val, error) => this.setState({'tt_customer_invoice_cancellation_fee': val, 'tt_customer_invoice_cancellation_fee_error': error})}
                                  inputProps={{
                                      value: this.state[`tt_customer_invoice_cancellation_fee`],
                                  }}
                                  required={true}
                                  helperText={t("edit_customer_invoice_cancellation_fee_error")}
                                  error={tt_customer_invoice_cancellation_fee_error}
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                  labelText={t("edit_customer_invoice_text")}
                                  id={`tt_customer_invoice_text`}
                                  inputProps={{
                                    multiline: true,
                                    value: this.state[`tt_customer_invoice_text`],
                                    onChange: (event) => this.setState({
                                      'tt_customer_invoice_text': event.target.value
                                    })
                                  }}
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                              />
                            </GridItem>
                        </> }
                        { (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "sub") && <>
                            <GridItem xs={12} sm={12} md={12}>
                              <h5>{t("edit_invoice_sub_title")}</h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_sub_invoice_trip`}
                                  labelText={t("edit_sub_invoice_trip")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange= {(val, error) => this.setState({ 'tt_sub_invoice_trip': val, 'tt_sub_invoice_trip_error': error})}
                                  inputProps={{
                                    value: this.state[`tt_sub_invoice_trip`],
                                    disabled: localStorage.getItem('role') !== "admin",
                                  }}
                                  required={true}
                                  helperText={t("edit_sub_invoice_trip_error")}
                                  error={tt_sub_invoice_trip_error}
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_sub_invoice_toll`}
                                  labelText={t("edit_sub_invoice_toll")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange= {(val, error) => this.setState({ 'tt_sub_invoice_toll': val, 'tt_sub_invoice_toll_error': error})}
                                  inputProps={{
                                    value: this.state[`tt_sub_invoice_toll`],
                                    disabled: localStorage.getItem('role') !== "admin",
                                  }}
                                  required={true}
                                  helperText={t("edit_sub_invoice_toll_error")}
                                  error={tt_sub_invoice_toll_error}
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_sub_invoice_waiting_fee`}
                                  labelText={t("edit_sub_invoice_waiting_fee")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange= {(val, error) => this.setState({ 'tt_sub_invoice_waiting_fee': val, 'tt_sub_invoice_waiting_fee_error': error})}
                                  inputProps={{
                                    value: this.state[`tt_sub_invoice_waiting_fee`],
                                    disabled: localStorage.getItem('role') !== "admin",
                                  }}
                                  required={true}
                                  helperText={t("edit_sub_invoice_waiting_fee_error")}
                                  error={tt_sub_invoice_waiting_fee_error}
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomCurrencyInput
                                  id={`tt_sub_invoice_cancellation_fee`}
                                  labelText={t("edit_sub_invoice_cancellation_fee")}
                                  currencySymbol={t("currency_symbol")}
                                  validateOnChange= {(val, error) => this.setState({ 'tt_sub_invoice_cancellation_fee': val, 'tt_sub_invoice_cancellation_fee_error': error})}
                                  inputProps={{
                                    value: this.state[`tt_sub_invoice_cancellation_fee`],
                                    disabled: localStorage.getItem('role') !== "admin",
                                  }}
                                  required={true}
                                  helperText={t("edit_sub_invoice_cancellation_fee_error")}
                                  error={tt_sub_invoice_cancellation_fee_error}
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                  labelText={t("edit_sub_invoice_comment")}
                                  id={`tt_sub_invoice_comment`}
                                  inputProps={{
                                    multiline: true,
                                    value: this.state[`tt_sub_invoice_comment`],
                                    onChange: (event) => this.setState({
                                      [`tt_sub_invoice_comment`]: event.target.value
                                    })
                                  }}
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                              />
                            </GridItem>
                        </>}
                      </GridContainer>
                    </div>
                    :
                    <div>
                      <h5>
                        {t("edit_select_trip_to_edit")}
                      </h5>
                      <CustomAutoComplete
                        labelText={"edit_trip_route_date_car_driver"}
                        id="fahrt"
                        value={{ value: " ", label: "... " + t("general_select_or_search") }}
                        onChange={value => {
                          if (value != null) {
                            axios.get(`${api_ipaddress}/api/trips/${value.value}`,
                              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                              .then(res => {
                                res.data.tt_stp = moment(res.data.tt_stp)
                                res.data.tt_std = moment(res.data.tt_std)
                                res.data.tt_sta = moment(res.data.tt_sta)
                                this.setState(res.data);
                                this.props.history.push(`/admin/edit/${value.value}`)
                              });
                          }
                        }}
                        selectChoices={tours_choices}
                      />
                    </div>
                }
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-start" }}>
                {(this.props.match.params.id === ":id" && localStorage.getItem('role') === "admin") &&
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState({
                        tt_tour: "",
                        tt_customer: "",
                        tt_status: "active",
                        tt_last_edit: "",
                        tt_from_adrnr: "",
                        tt_from_text: "",
                        tt_to_adrnr: "",
                        tt_to_text: "",
                        tt_sub: "",
                        tt_driver: "",
                        tt_drivernr: "",
                        tt_gps: "",
                        tt_stp_error: false,
                        tt_stp: "",
                        tt_std_error: false,
                        tt_std: "",
                        tt_sta_error: false,
                        tt_sta: "",
                        tt_atp_error: false,
                        tt_atp: "",
                        tt_atd_error: false,
                        tt_atd: "",
                        tt_ata_error: false,
                        tt_ata: "",
                        tt_invoice_check: true,
                        tt_customer_invoice_toll: '0.00',
                        tt_customer_invoice_cancellation_fee: '0.00',
                        tt_sub_invoice_trip: '0.00',
                        tt_sub_invoice_toll: '0.00',
                        tt_sub_invoice_waiting_fee: '0.00',
                        tt_sub_invoice_cancellation_fee: '0.00',
                        tt_sub_invoice_comment: "",
                      })
                      this.props.history.push(`/admin/edit/neu`)
                    }}
                  >
                    {t("edit_enter_new_trip")}
                  </Button>}
                {this.props.match.params.id !== ":id" &&
                  <Button
                    color="primary"
                    disabled={
                      tt_tour === "" ||
                      tt_from_adrnr === "" ||
                      tt_to_adrnr === "" ||
                      tt_gps === "" || tt_gps == null ||
                      tt_stp_error ||
                      tt_std_error ||
                      tt_sta_error ||
                      tt_atp_error ||
                      tt_atd_error ||
                      tt_ata_error
                    }
                    onClick={() => {
                      let requestBody = {};
                      if(localStorage.getItem('role') === "admin"){
                        requestBody = {
                          tt_status,
                          tt_driver,
                          tt_drivernr,
                          tt_from_adrnr,
                          tt_from_text,
                          tt_gps,
                          tt_last_edit,
                          tt_sta,
                          tt_std,
                          tt_stp,
                          tt_atp,
                          tt_atd,
                          tt_ata,
                          tt_sub,
                          tt_to_adrnr,
                          tt_to_text,
                          tt_tour,
                          tt_note,
                          tt_info,
                          tt_invoice_check,
                          tt_customer_invoice_trip,
                          tt_customer_invoice_toll,
                          tt_customer_invoice_waiting_fee,
                          tt_customer_invoice_cancellation_fee,
                          tt_customer_invoice_text,
                          tt_sub_invoice_trip,
                          tt_sub_invoice_toll,
                          tt_sub_invoice_waiting_fee,
                          tt_sub_invoice_cancellation_fee,
                          tt_sub_invoice_comment,
                        }

                        let arrObs = chosenObservers.map(o => o.label);
                        arrObs.unshift(customer.label);
                        requestBody.tt_customer = arrObs.join(";");

                        requestBody.tt_customer_invoice_trip = textToFloat(tt_customer_invoice_trip)
                        requestBody.tt_customer_invoice_toll = textToFloat(tt_customer_invoice_toll)
                        requestBody.tt_customer_invoice_waiting_fee = textToFloat(tt_customer_invoice_waiting_fee)
                        requestBody.tt_customer_invoice_cancellation_fee = textToFloat(tt_customer_invoice_cancellation_fee)
                        requestBody.tt_sub_invoice_trip = textToFloat(tt_sub_invoice_trip)
                        requestBody.tt_sub_invoice_toll = textToFloat(tt_sub_invoice_toll)
                        requestBody.tt_sub_invoice_waiting_fee = textToFloat(tt_sub_invoice_waiting_fee)
                        requestBody.tt_sub_invoice_cancellation_fee = textToFloat(tt_sub_invoice_cancellation_fee)
                      }else if(localStorage.getItem('role') === "sub") {

                        const currentDate = moment();
                        let tripDate = moment(tt_stp);

                        // se a data da trip for antes da data actual
                        if(moment(tripDate).isBefore(currentDate)){
                          console.log("isBefore")
                        }
                        requestBody.tt_sub_invoice_comment = tt_sub_invoice_comment;
                        requestBody.tt_gps = tt_gps;
                        requestBody.tt_driver = tt_driver;
                        requestBody.tt_drivernr = tt_drivernr;

                      }else if(localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "sub"){
                        requestBody = { tt_driver, tt_drivernr, tt_gps, tt_last_edit }
                      }

                      // update or add via api
                      if (this.props.match.params.id !== "neu") {
                        requestBody.tt_last_edit = moment(requestBody.tt_last_edit).toISOString()
                        axios.put(`${api_ipaddress}/api/trips/${this.props.match.params.id}`,
                          requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                          .then((res) => {
                            this.showNotification("tc", t(res.data.message));

                            // update selection list
                            axios.get(`${api_ipaddress}/api/tours`,
                              {
                                headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                                params: {
                                  role: localStorage.getItem('role'),
                                  username: localStorage.getItem('username')
                                }
                              })
                              .then(res => {
                                this.setState({ tours: res.data });
                              });
                            this.props.history.goBack()
                          }).catch((err) => {
                            console.log(err);
                            this.showNotification("tc", t(err.response.data.message));
                          });
                      } else {
                        requestBody.tt_last_edit = moment().toISOString()
                        axios.post(`${api_ipaddress}/api/trips`,
                          requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                          .then((res) => {
                            this.showNotification("tc", t(res.data.message));
                            // update selection list
                            axios.get(`${api_ipaddress}/api/tours`,
                              {
                                headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                                params: {
                                  role: localStorage.getItem('role'),
                                  username: localStorage.getItem('username')
                                }
                              })
                              .then(res => {
                                this.setState({ tours: res.data });
                              });
                            this.props.history.goBack()
                          })
                          .catch((err) => {
                            console.log(err);
                            this.showNotification("tc", t(err.response.data.message));
                          });
                      }
                    }}
                  >
                    {t("general_save")}
                  </Button>}
                {this.props.match.params.id !== ":id" &&
                  <Button
                    color="white"
                    // disabled={Number.isInteger(parseInt(this.props.match.params.id)) ? false : true}
                    onClick={() => this.props.history.goBack()}
                  >
                    {t("general_cancel")}
                  </Button>}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </div>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Edit));
