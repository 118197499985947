/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core componentsapi_ipaddress
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";

// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-gin.jpg";
import logo from "assets/img/gin_express_logo.png";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { api_ipaddress } from '../variables.js'
import axios from 'axios'

import { withTranslation } from 'react-i18next';


class ResetPW extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "primary",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      tc: false,
      message: "",
      new_password_1: "",
      new_password_2: "",
    };
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  resizeFunction = () => {
    if (window.innerWidth >= 1280) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;
    const { t } = this.props;
    const { new_password_1, new_password_2 } = this.state;

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={[]}
          logoText={"TruckWatch"}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div id="mainPanel" className={classes.mainPanel} ref="mainPanel">
          <Hidden lgUp implementation="css">
            <Navbar
              routes={[]}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
          </Hidden>
          <div className={classes.content}>
            <div className={classes.container}>

              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Card>
                      <CardHeader color="primary">
                        <h4 style={{ marginTop: "0px", minHeight: "auto", marginBottom: "0px", }}>{t("login_change_password")}</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText={t("login_new_password")}
                              id="new_password_1"
                              inputProps={{
                                value: new_password_1,
                                onChange: (event) => this.setState({
                                  new_password_1: event.target.value
                                }),
                                type: "password"
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText={t("login_repeat_new_password")}
                              id="new_password_2"
                              inputProps={{
                                value: new_password_2,
                                onChange: (event) => this.setState({
                                  new_password_2: event.target.value
                                }),
                                type: "password"
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        <Button
                          color="primary"
                          onClick={() => {
                            let requestBody = {
                              "newpassword": new_password_1,
                            }
                            axios.post(`${api_ipaddress}/api/pwresetchange/${this.props.match.params.id}`,
                              requestBody)
                              .then((res) => {
                                this.showNotification("tc", t("login_password_changed"))
                                this.props.history.push('/admin/login');
                              })
                              .catch((err) => {
                                console.log(err);
                                this.showNotification("tc", t("general_request_not_successful"));
                              });
                          }}
                          disabled={new_password_1 === "" || new_password_1 !== new_password_2}
                        >
                          {t("login_change_password")}
                        </Button>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
                <Snackbar
                  place="tc" //topcenter
                  color="primary"
                  message={this.state.message}
                  open={this.state.tc}
                  closeNotification={() => this.setState({ tc: false })}
                  close
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPW.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(dashboardStyle)(ResetPW));
