import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCheckbox from "components/CustomInput/CustomCheckbox.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { api_ipaddress } from '../../variables.js'
import axios from 'axios'

import { withTranslation } from 'react-i18next';



const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      tc: false,
      message: "",
    };
  }

  componentWillMount() {
    if (localStorage.getItem("stayLoggedIn") == null) {
      localStorage.setItem("stayLoggedIn", JSON.stringify(true))
    }
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    const {
      username,
      password,
    } = this.state;

    const loginCard = <GridItem xs={12} sm={12} md={6}>
      <Card>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            event.stopPropagation()
            let requestBody = {
              "username": this.state.username,
              "password": this.state.password
            }
            axios.post(`${api_ipaddress}/api/login`, requestBody)
              .then((res) => {
                localStorage.setItem('access_token', res.data.access_token);
                localStorage.setItem('username', res.data.username);
                localStorage.setItem('role', res.data.role);
                if (JSON.parse(localStorage.getItem("stayLoggedIn"))) {
                  localStorage.removeItem('login_timeout');
                } else {
                  var loginTimeout = new Date();
                  loginTimeout.setHours(loginTimeout.getHours() + 1);
                  localStorage.setItem('login_timeout', loginTimeout);
                }
                this.props.history.push('/admin/dashboard');
              })
              .catch((err) => {
                console.log(err);
                this.showNotification("tc", t(err.response.data.message));
              });
          }}
        >
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>{t("login_login")}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText={t("users_username")}
                  id="username"
                  inputProps={{
                    value: username,
                    onChange: (event) => this.setState({
                      username: event.target.value
                    })
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText={t("login_password")}
                  id="password"
                  inputProps={{
                    value: password,
                    onChange: (event) => this.setState({
                      password: event.target.value
                    }),
                    type: "password"
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem>
                <CustomCheckbox
                  labelText={t("login_stay_logged_in")}
                  id="stay_logged_in"
                  inputProps={{
                    checked: JSON.parse(localStorage.getItem("stayLoggedIn")),
                    onChange: (event) => this.setState({
                      stayLoggedIn: localStorage.setItem(
                        "stayLoggedIn", JSON.stringify(event.target.checked))
                    })
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <div>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.username === "" || this.state.password === ""}
              >
                {t("login_login")}
              </Button>
              <Button
                color="white"
                // style={{}}
                onClick={() => {
                  axios.post(`${api_ipaddress}/api/pwreset/${username}`,
                    { language: i18n.language })
                    .then((res) => {
                      console.log(res)
                      this.showNotification("tc", t(res.data.message));
                    })
                    .catch((err) => {
                      console.log(err);
                      this.showNotification("tc", t(err.response.data.message));
                    });
                }}
                disabled={this.state.username === ""}
              >
                {t("login_password_forgotten")}
              </Button>
            </div>
          </CardFooter>
        </form>
      </Card>
    </GridItem>

    const logoutCard = <GridItem xs={12} sm={12} md={6}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("login_loggedin")}</h4>
        </CardHeader>
        <CardBody>
          <h5>{t("users_username")}: <strong>{localStorage.getItem('username')}</strong></h5>
          <h5>{t("login_access_level")}: <strong>{
            {
              "admin": t("users_role_admin"),
              "customer": t("users_role_customer"),
              "sub": t("users_role_subcontractor"),
              "observer": t("users_role_observer")
            }[localStorage.getItem('role')]}</strong></h5>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            onClick={() => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('username');
              localStorage.removeItem('role');
              this.props.history.push('/admin/login');
            }}
          >
            {t("login_logout")}
          </Button>
        </CardFooter>
      </Card>
    </GridItem>

    return (
      <div>
        <GridContainer>
          {localStorage.getItem('access_token') === null && loginCard}
          {localStorage.getItem('access_token') !== null && logoutCard}
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Login));