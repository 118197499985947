import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from '@material-ui/core/Chip';
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomAutoComplete from "../CustomInput/CustomAutocomplete";

// core components
import tagsStyle from "assets/jss/material-dashboard-react/components/tagsStyle.jsx";

//TODO: Store this key code in a common place
const ENTER_KEY = 13;

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validatorMessage: "",
      error: false,
      addIcon: false,
    };
  }

  compareItems = (element, elementToCompare) => {
    return element.value === elementToCompare.value;
  }

  addTagInput = (event) => {
    const { tags, validators, additionCallback } = this.props;

    let target = event.currentTarget.parentElement.querySelector('input.MuiInputBase-input');
    let value = target.value;
    
    let isValid = true;
    let message = null;

    for (let validator of validators) {
      if (!validator.validation(value)) {
        isValid = false
        message = validator.message;
        break;
      }
    }

    this.setState({ error: !isValid, addIcon: false, validatorMessage: message },
      () => {
        if (isValid) {
          target.value = ""
          additionCallback([...tags, { value: value, label: value }]);
        }
      }
    );
  }

  handleDelete = (el) => {
    const { tags, deleteCallback } = this.props;
    let button = el.target.closest('div.MuiChip-deletable');
    let tag = {
      value: button.attributes["value"].value,
      label: button.innerText
    };

    let index = tags.findIndex((arrayItem) => this.compareItems(arrayItem, tag));
    tags.splice(index, 1);

    deleteCallback(tags);
  };

  handleInputAddition = (event) => {
    const { tags, additionCallback } = this.props;

    if (event.keyCode === ENTER_KEY) {
      this.addTagInput(event);
    } else {
      this.setState({ error: false, validatorMessage: null }, additionCallback([...tags]));
    }
  };

  handleIconOnClick = (event) => {    
    this.addTagInput(event);
  }

  handleSelectAddition = (tags) => {
    const { additionCallback } = this.props;
    additionCallback(tags);
  };

  render() {
    const {
      id,
      tags,
      disabled,
      labelField,
      value,
      select,
      selectChoices,
    } = this.props;

    const {
      error,
      validatorMessage,
      addIcon,
    } = this.state;

    return (
      <div>
        {
          select &&
          <CustomAutoComplete
            id={id}
            labelText={labelField}
            disabled={disabled}
            value={tags}
            isMulti={true}
            onChange={this.handleSelectAddition}
            style={{ "margin-top": "27px" }}
            selectChoices={selectChoices}
          />
        }
        {
          !select &&
          <div style={{ marginTop: "22px" }}>
            <div>
              {
                tags.map(el => <Chip key={el.value} value={el.value} label={el.label} className="chip" onDelete={this.handleDelete} />)
              }
            </div>
            <CustomInput
              id={id}
              labelText={labelField}
              value={value}
              disabled={disabled}
              error={error}
              helperText={validatorMessage}
              addIcon={addIcon}
              onIconClick={this.handleIconOnClick}
              inputProps={{
                onKeyDown: (event) => this.handleInputAddition(event),
                onChange: (event) => { this.setState({ addIcon: event.target.value !== "" }) }
              }}
              formControlProps={{
                style: { "marginTop": "5px" },
                fullWidth: true
              }}
            />
          </div>
        }
      </div>
    )
  }
}

Tags.propTypes = {
  id: PropTypes.string.isRequired,
  labelField: PropTypes.string,
  tags: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  select: PropTypes.bool,
};

export default withStyles(tagsStyle)(Tags);