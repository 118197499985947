import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import AddIcon from '@material-ui/icons/Add';

// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    min,
    max,
    step,
    labelProps,
    inputProps,
    error,
    helperText,
    success,
    selectInput,
    selectChoices,
    addIcon,
    onIconClick,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      disabled={props.disabled}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      {selectInput === undefined ?
        <Input
          classes={{
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses
          }}
          id={id}
          inputProps={{ min: min, max: max, step: step }}
          {...inputProps}
        /> :
        <Select
          classes={{
            root: marginTop,
            disabled: classes.disabled,
          }}
          {...inputProps}
          inputProps={{
            name: id,
            id: id,
          }}
        >
          {selectChoices.map(item =>
            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
          )}
        </Select>
      }
      {addIcon && !error &&
        <AddIcon className={`${classes.icon}`} onClick={onIconClick} />
      }
      {error ? (
        <Clear className={`${classes.feedback} ${classes.labelRootError}`} />
      ) : success ? (
        <Check className={`${classes.feedback} ${classes.labelRootSuccess}`} />
      ) : null}
      {error && helperText !== "" &&
        <FormHelperText error={error}>{helperText}</FormHelperText>
      }
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  selectInput: PropTypes.bool,
  selectChoices: PropTypes.array,
};

export default withStyles(customInputStyle)(CustomInput);
