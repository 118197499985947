import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from "prop-types";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(13),
  },
}))(Tooltip);

export default function CustomTooltip({...props}) {
  const { title } = props;

  return (
      <div>
        <HtmlTooltip
            title={ title }
        >
          { props.children }
        </HtmlTooltip>
      </div>
  );
}

CustomTooltip.propTypes = {
  title: PropTypes.string
};