import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from '@material-ui/core/Tab';

// core components
import linkTabStyle from "assets/jss/material-dashboard-react/components/linkTabStyle.jsx";

function LinkTab(props) {
  
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default withStyles(linkTabStyle)(LinkTab);
