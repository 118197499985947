import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import Item from "../../components/ListItems/Item";
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ListItems extends React.Component {
    render() {
        const { t, startDate, endDate, addItem, removeItem, onChangeItem, data } = this.props
        return (<>
            <a style={{cursor: "pointer"}} onClick={addItem}><FontAwesomeIcon icon={faPlusCircle} size="lg" style={{ color: "lightgray", marginRight: "10px" }} /> New Invoice Item</a>
            { this.props.list.map((item, idx) => {
                return <Item key={idx} idx={idx}  startDate={startDate} data={data}
                             endDate={endDate} date={item.date} fuel={item.fuel} fuel_error={item.fuel_error}
                             extra_cost={item.extra_cost} extra_cost_error={item.extra_cost_error} invoice_text={item.invoice_text}
                             removeItem={removeItem} onChangeItem={onChangeItem} t={t}
                        />
              })}
            </>);
    }
}

ListItems.propTypes = {
    classes: PropTypes.object.isRequired,
    tasksIndexes: PropTypes.arrayOf(PropTypes.number),
    tasks: PropTypes.arrayOf(PropTypes.node),
    rtlActive: PropTypes.bool
};

export default withStyles(tasksStyle)(ListItems);
