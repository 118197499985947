import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomAutoComplete from "components/CustomInput/CustomAutocomplete.jsx";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import analysisAreaStyle from "assets/jss/material-dashboard-react/views/analysisAreaStyle.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import MUIDataTable from "mui-datatables";
import { api_ipaddress } from '../../variables.js'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faCheckSquare, faTruck, faSquare, faParking, faCheck, faTimes, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import { initlocalStorage } from 'functions/sessionInit.js'
import { withTranslation } from 'react-i18next';
import CustomDate from "../../components/CustomInput/CustomDate.jsx";
import TabPanel from "../../components/Tab/TabPanel.jsx";
import LinkTab from "../../components/Tab/LinkTab.jsx";
import Tabs from "../../components/Tab/Tabs.jsx";
import Donut from "../../components/Charts/Donut.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import CollapseButton from "../../components/Collapse/CollapseButton.jsx";
import {floatToText, textToFloat} from "functions/helpers.js";
import _ from 'lodash';

import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'
import CustomTooltip from "../../components/Tooltip/CustomTooltip";

var moment = require('moment-timezone');

const timeDelayInMinutes = (dtActual, dtTarget) => {
  return moment.duration(moment(dtActual).diff(moment(dtTarget))).asMinutes();
}

const textFromDelay = (DelayMinutes, t) => {
  return DelayMinutes >= 16 ?
    t("analysisArea_delayed") :
    DelayMinutes >= 1 ?
      t("analysisArea_marg") :
      t("analysisArea_ontime")
}

const getDelayStatus = (r, event, t) => {
  let driving, time_departure, DelayMinutes
  if (event === "positioning") {
    if (r.tt_etp == null) return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_etp, r.tt_stp)
    driving = r.tt_atp ? t("analysisArea_arrived") : t("analysisArea_in_transit")
  } else if (event === "departure") {
    if (r.tt_atp == null) return "-"
    time_departure = r.tt_atd ? moment(r.tt_atd) : moment()
    DelayMinutes = timeDelayInMinutes(time_departure, r.tt_std)
    driving = r.tt_atd ? t("analysisArea_departed") : t("analysisArea_waiting")
  } else if (event === "arrival") {
    if (r.tt_eta == null) return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_eta, r.tt_sta)
    driving = r.tt_ata ? t("analysisArea_arrived") : t("analysisArea_in_transit")
  }
  return `${textFromDelay(DelayMinutes, t)} (${driving})`
}

const getTimeDelayInMinutes = (r, event) => {
  let delayMinutes = "";
  if (event === "positioning") {
    if (r.tt_etp == null)
      return null;
    delayMinutes = timeDelayInMinutes(r.tt_etp, r.tt_stp);
  } else if (event === "departure") {
    if (r.tt_atp == null || r.tt_atd == null)
      return null;
    delayMinutes = timeDelayInMinutes(r.tt_atd, r.tt_std);
  } else if (event === "arrival") {
    if (r.tt_eta == null)
      return null;
    delayMinutes = timeDelayInMinutes(r.tt_eta, r.tt_sta);
  }
  if (delayMinutes <= 0)
    return null;

  return `${Math.round(delayMinutes)}`
}


const getDrivingStatus = (r, t) => {
  if (r.tt_status === "inactive")
    return t("analysisArea_inactive")
  if (r.tt_ignition == null || r.tt_ata != null || (r.tt_etp == null & r.tt_eta == null))
    return "-"
  const status = r.tt_ignition === 1 && (r.tt_atd != null || r.tt_atp == null)
    ? t("analysisArea_drives")
    : t("analysisArea_stands")
  return status
}

const asTime = (momentObj) => {
  try {
    return momentObj.isValid() ? momentObj.format("DD.MM HH:mm") : ""
  }
  catch (e) { return "" }
}

const getDelayStatusForPieCharts = (r, event, t) => {
  let time_departure, DelayMinutes
  if (event === "positioning") {
    if (r.tt_etp == null)
      return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_etp, r.tt_stp)
  } else if (event === "departure") {
    if (r.tt_atp == null)
      return "-"
    time_departure = r.tt_atd ? moment(r.tt_atd) : moment()
    DelayMinutes = timeDelayInMinutes(time_departure, r.tt_std)
  } else if (event === "arrival") {
    if (r.tt_eta == null)
      return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_eta, r.tt_sta)
  }

  return `${textFromDelay(DelayMinutes, t)}`
}

const datePieTemplate = (data, event, t) => {
  let delayed = t("analysisArea_delayed");
  let marg = t("analysisArea_marg");
  let ontime = t("analysisArea_ontime");

  const groupedData = _.groupBy(data.filter(r => r.pos !== '-'), event);

  return [
    {
      "id": t("analysisArea_delayed_detailed"),
      "label": t("analysisArea_delayed_detailed"),
      "value": groupedData[delayed] !== undefined
        ? groupedData[delayed].length
        : 0,
      "color": "#ff4300"
    },
    {
      "id": t("analysisArea_marg_detailed"),
      "label": t("analysisArea_marg_detailed"),
      "value": groupedData[marg] !== undefined
        ? groupedData[marg].length
        : 0,
      "color": "#ffae00"
    },
    {
      "id": ontime,
      "label": ontime,
      "value": groupedData[ontime] !== undefined
        ? groupedData[ontime].length
        : 0,
      "color": "#23c644"
    }
  ]
}

const isPieDataEmpty = (data) => {
  let total = 0;

  if (data !== null)
    data.forEach(item => total += item.value);

  return total === 0;
}

const statusValue = (value, t) => {
  switch (value) {
    case "-":
      return "NONE";
    case `${t("analysisArea_ontime")} (${t("analysisArea_arrived")})`:
      return "ON_TIME_ARRIVED";
    case `${t("analysisArea_ontime")} (${t("analysisArea_in_transit")})`:
      return "ON_TIME_TRANSIT";
    case `${t("analysisArea_marg")} (${t("analysisArea_arrived")})`:
      return "MARG_ARRIVED";
    case `${t("analysisArea_marg")} (${t("analysisArea_in_transit")})`:
      return "MARG_TRANSIT";
    case `${t("analysisArea_delayed")} (${t("analysisArea_arrived")})`:
      return "DELAYED_ARRIVED";
    case `${t("analysisArea_delayed")} (${t("analysisArea_in_transit")})`:
      return "DELAYED_TRANSIT";
    case `${t("analysisArea_ontime")} (${t("analysisArea_departed")})`:
      return "ON_TIME_DEPARTED";
    case `${t("analysisArea_ontime")} (${t("analysisArea_waiting")})`:
      return "ON_TIME_WAITING";
    case `${t("analysisArea_marg")} (${t("analysisArea_departed")})`:
      return "MARG_DEPARTED";
    case `${t("analysisArea_marg")} (${t("analysisArea_waiting")})`:
      return "MARG_WAITING";
    case `${t("analysisArea_delayed")} (${t("analysisArea_departed")})`:
      return "DELAYED_DEPARTED";
    case `${t("analysisArea_delayed")} (${t("analysisArea_waiting")})`:
      return "DELAYED_WAITING";
    default:
      return "";
  }
}

const pastWeek = moment().subtract(1, 'weeks').toISOString();
const currentDay = moment().toISOString();

class AnalysisArea extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
      tableLayout: "scroll",
      tc: false,
      message: "",
      tabValue: 0,
      openFilter: false,
      updateDT: 1,
      filterList: [],
      analysisColListDesktop: {},
      analysisColListMobile: {},
      loading: true,
      data: [],
      displayedData: null,
      dataPieArrival: null,
      dataPieDeparture: null,
      dataPiePositioning: null,
      start_date: pastWeek,
      end_date: currentDay,
      tt_p: null,
      tt_d: null,
      tt_a: null,
      tt_stp: null,
      tt_sta: null,
      tt_tour: null,
      tt_sub: null,
      tt_customer: null,
      tt_gps: null,
      tt_driver: null,
      tt_status: null,
      tt_from_text: null,
      tt_to_text: null,
    };
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  componentWillMount() {
    initlocalStorage()
  }

  filterData() {
    const { t } = this.props;
    let filter = {};

    const props = [
      "tt_stp",
      "tt_sta",
      "tt_tour",
      "tt_sub",
      "tt_gps",
      "tt_driver",
      "tt_status",
      "tt_from_text",
      "tt_to_text"
    ]

    props.forEach(item => {
      if (this.state[item] !== null)
        filter[item] = this.state[item];
    });

    let filteredData = _.filter(this.state.data, filter);

    if (this.state.tt_p !== null)
      filteredData = filteredData.filter(r => getDelayStatus(r, "positioning", t) === this.state.tt_p);

    if (this.state.tt_d !== null)
      filteredData = filteredData.filter(r => getDelayStatus(r, "departure", t) === this.state.tt_d);

    if (this.state.tt_a !== null)
      filteredData = filteredData.filter(r => getDelayStatus(r, "arrival", t) === this.state.tt_a);

    if(this.state.tt_customer !== null)
      filteredData = filteredData.filter(r => r.tt_customer.includes(this.state.tt_customer));

    this.renderDropdowns(filteredData);
    this.renderPieCharts(filteredData);
    this.renderTable(filteredData);

    this.setState({ loading: false });
  }

  renderDropdowns(data) {
    const { t } = this.props;

    //build dataset for dropdowns
    this.setState({ gps: [...new Set(data.map(r => r.tt_gps))].sort() });
    this.setState({ from: [...new Set(data.map(r => r.tt_from_text))].sort() });
    this.setState({ to: [...new Set(data.map(r => r.tt_to_text))].sort() });
    this.setState({ driver: [...new Set(data.map(r => r.tt_driver))].sort() });
    this.setState({ active: [...new Set(data.map(r => r.tt_status))].sort() }); //sorting descending because we want 'true' first
    this.setState({ subcontractor: [...new Set(data.map(r => r.tt_sub))].sort() });
    this.setState({ tour: [...new Set(data.map(r => r.tt_tour))].sort() });
    this.setState({ positioning: [...new Set(data.map(r => getDelayStatus(r, "positioning", t)))].sort() })
    this.setState({ departure: [...new Set(data.map(r => getDelayStatus(r, "departure", t)))].sort() })
    this.setState({ arrival: [...new Set(data.map(r => getDelayStatus(r, "arrival", t)))].sort() })

    // Customers
    let customers = [];
    let customerArrays = data.map(r => r.tt_customer.split(';'));
    customerArrays.forEach(array => { customers = [...new Set([...customers, ...array])]});
    this.setState({ customer: customers.sort() });
  }
  renderTable(tableData) {
    const { t } = this.props;

    let data = tableData !== null ? tableData.map(
      r => ({
        id: r.tt_id,
        active: r.tt_status,
        tour: r.tt_tour,
        from: r.tt_from_text,
        to: r.tt_to_text,
        tour_combination: r.tt_tour_combination,
        gps: r.tt_gps,
        pos: getDelayStatus(r, "positioning", t),
        pos_delay: getTimeDelayInMinutes(r, "positioning", t),
        stp: moment(r.tt_stp),
        etp: moment(r.tt_etp),
        dep: getDelayStatus(r, "departure", t),
        dep_delay: getTimeDelayInMinutes(r, "departure", t),
        std: moment(r.tt_std),
        atd: moment(r.tt_atd),
        arr: getDelayStatus(r, "arrival", t),
        arr_delay: getTimeDelayInMinutes(r, "arrival", t),
        sta: moment(r.tt_sta),
        eta: moment(r.tt_eta),
        note: localStorage.getItem('role') === "admin" ? r.tt_note : "",
        sub: r.tt_sub,
        customer: r.tt_customer,
        driver: r.tt_driver,
        overview: <div>
          {this.delayToIcon(getDelayStatus(r, "positioning", t), t)}
          {this.delayToIcon(getDelayStatus(r, "departure", t), t)}
          {this.delayToIcon(getDelayStatus(r, "arrival", t), t)}
          {this.drivingToIcon(getDrivingStatus(r, t), t)}
        </div>,
        c_trip: r.tt_customer_invoice_trip,
        c_toll: r.tt_customer_invoice_toll,
        c_waiting: r.tt_customer_invoice_waiting_fee,
        c_cnl: r.tt_customer_invoice_cancellation_fee,
        s_trip: r.tt_sub_invoice_trip,
        s_toll: r.tt_sub_invoice_toll,
        s_waiting: r.tt_sub_invoice_waiting_fee,
        s_cnl: r.tt_sub_invoice_cancellation_fee,
        info: (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "customer" || localStorage.getItem('role') === "sub" || localStorage.getItem('role') === "observer") ? r.tt_info : "",
      })
    ).filter(r =>
      (!r.arr.includes(t("analysisArea_arrived")) &&
        (r.sta.clone().add(6, 'hours') > moment()) && (
          r.pos.includes(t("analysisArea_marg")) || r.pos.includes(t("analysisArea_delayed")) ||
          r.dep.includes(t("analysisArea_marg")) || r.dep.includes(t("analysisArea_delayed")) ||
          r.arr.includes(t("analysisArea_marg")) || r.arr.includes(t("analysisArea_delayed")))) ||
      !JSON.parse(localStorage.getItem("panicbutton")))
      :
      null;

    this.setState({ displayedData: data });
  }

  renderPieCharts(data) {
    const { t } = this.props;

    const groupData = data.map(
      r => ({
        pos: getDelayStatusForPieCharts(r, "positioning", t),
        dep: getDelayStatusForPieCharts(r, "departure", t),
        arr: getDelayStatusForPieCharts(r, "arrival", t)
      })
    );

    this.setState({
      dataPiePositioning: datePieTemplate(groupData, 'pos', t),
      dataPieDeparture: datePieTemplate(groupData, 'dep', t),
      dataPieArrival: datePieTemplate(groupData, 'arr', t)
    });
  }

  componentDidMount() {
    moment.tz.setDefault();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    this.setState({
      loading: true,
      filterList: JSON.parse(localStorage.getItem("filterList")),
      analysisColListDesktop: JSON.parse(localStorage.getItem("analysisColListDesktop")),
      analysisColListMobile: JSON.parse(localStorage.getItem("analysisColListMobile"))
    }, this.updateData);
  }

  updateData() {
    let request = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };

    axios.post(`${api_ipaddress}/api/timetable/filter`,
      request,
      {
        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
        params: {
          role: localStorage.getItem('role'),
          username: localStorage.getItem('username')
        }
      })
      .then(res => {

        res.data.forEach((ele) => {
          ele.tt_customer_invoice_trip = floatToText(ele.tt_customer_invoice_trip)
          ele.tt_customer_invoice_toll = floatToText(ele.tt_customer_invoice_toll)
          ele.tt_customer_invoice_waiting_fee = floatToText(ele.tt_customer_invoice_waiting_fee)
          ele.tt_customer_invoice_cancellation_fee = floatToText(ele.tt_customer_invoice_cancellation_fee)
          ele.tt_sub_invoice_trip = floatToText(ele.tt_sub_invoice_trip)
          ele.tt_sub_invoice_toll = floatToText(ele.tt_sub_invoice_toll)
          ele.tt_sub_invoice_waiting_fee = floatToText(ele.tt_sub_invoice_waiting_fee)
          ele.tt_sub_invoice_cancellation_fee = floatToText(ele.tt_sub_invoice_cancellation_fee)
        });

        this.setState({ data: res.data })
        this.filterData();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({
          data: []
        })
      })
  }

  resize() {
    this.setState({
      tableLayout: window.innerWidth <= 620 ? "stacked" : "scroll",
      windowHeight: window.innerHeight
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableRow: {
        root: {
          height: "24px",
        }
      },
      MuiTableCell: {
        root: this.state.tableLayout === "scroll" ?
          {
            paddingRight: "4px",
            paddingTop: "6px",
            paddingLeft: "4px",
            paddingBottom: "6px",
            whiteSpace: "nowrap",
            maxWidth: "6em",
            overflow: "hidden",
            textOverflow: "ellipsis"
          } :
          {
            paddingTop: "4px",
            paddingBottom: "1px",
          }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: window.innerWidth <= 1280 ?
            `${this.state.windowHeight - 560}px !important` :
            `${this.state.windowHeight - 520}px !important`,
          minHeight: "300px",
        },
      },
    }
  })

  buildRequest = () => {
    const { t } = this.props;

    let positioning = (this.state.tt_p !== null) ? statusValue(this.state.tt_p, t) : undefined;
    let departure = (this.state.tt_d !== null) ? statusValue(this.state.tt_d, t) : undefined;
    let arrival = (this.state.tt_a !== null) ? statusValue(this.state.tt_a, t) : undefined;

    return {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      tt_sub: (this.state.tt_sub !== null) ? this.state.tt_sub : undefined,
      tt_customer: (this.state.tt_customer !== null) ? this.state.tt_customer : undefined,
      tt_tour: (this.state.tt_tour !== null) ? this.state.tt_tour : undefined,
      tt_gps: (this.state.tt_gps !== null) ? this.state.tt_gps : undefined,
      tt_driver: (this.state.tt_driver !== null) ? this.state.tt_driver : undefined,
      tt_status: (this.state.tt_status !== null) ? this.state.tt_status : undefined,
      tt_from_text: (this.state.tt_from_text !== null) ? this.state.tt_from_text : undefined,
      tt_to_text: (this.state.tt_to_text !== null) ? this.state.tt_to_text : undefined,
      positioning: positioning,
      depature: departure,
      arrival: arrival
    };
  }

  exportExcel = (request) => {
    axios.post(`${api_ipaddress}/api/reportdownload`, request,
      {
        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
        params: {
          role: localStorage.getItem('role'),
          username: localStorage.getItem('username')
        },
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'truckwatch_analysis.xlsx');
        document.body.appendChild(link);
        link.click();
        return false;
      })
      .catch((err) => {
        this.showNotification("tc", "Leider gab es ein Problem");
        return false;
      })
  }

  delayToIcon = (value, t) => {
    if (value == null)
      return null
    const iconColor = value.includes(t("analysisArea_ontime")) ? "#23c644"
      : value.includes(t("analysisArea_marg")) ? "#ffae00"
        : value.includes(t("analysisArea_delayed")) ? "#ff4300"
          : "lightgrey"
    const icon =
      value.includes(t("analysisArea_arrived")) || value.includes(t("analysisArea_departed")) ?
        <FontAwesomeIcon icon={faCheckSquare} size="lg"
          style={{ color: iconColor, paddingRight: "4px" }} /> :
        <FontAwesomeIcon icon={faSquare} size="lg"
          style={{ color: iconColor, paddingRight: "4px" }} />

    return (iconColor !== "lightgrey" && this.state.tableLayout === "scroll") ?
      <Tooltip title={value}><div>{icon}</div></Tooltip> :
      icon;
  }

  drivingToIcon = (value, t) => {
    if (value === "-" || value === t("analysisArea_inactive"))
      return " "
    const icon = value.includes(t("analysisArea_drives"))
      ? <FontAwesomeIcon icon={faTruck} size="lg" style={{ color: "gray", paddingRight: "4px" }} />
      : value.includes(t("analysisArea_stands"))
        ? <FontAwesomeIcon icon={faParking} size="lg" style={{ color: "lightgray", paddingRight: "4px" }} />
        : ""
    return this.state.tableLayout === "scroll" ?
      <Tooltip title={t("analysisArea_car") + " " + value}><div>{icon}</div></Tooltip> :
      icon;
  }

  textColor = (value, color) => {
    return color &&
      <span style={{ "color": color }} title={value}>{value}</span>
  }

  delayTextColor = (value) => {
    let displayValue = `${value} min`;

    if (value === null)
      return null;

    return value >= 16
      ? this.textColor(displayValue, "#ff4300")
      : value >= 1
        ? this.textColor(displayValue, "#ffae00")
        : this.textColor(displayValue, "#23c644");
  }

  //
  // column specifications
  // ---------------------

  dateOptions = (name, colList) => {
    return {
      sort: true,
      customBodyRender: (momentObj) => asTime(momentObj),
      display: this.state[colList][name]
    }
  }

  standardOptions = (name, colList, sortable = false, color = "inherit") => {
    return {
      sort: sortable,
      customBodyRender: (value) => this.textColor(value, color),
      display: this.state[colList][name]
    }
  }

  textDelayOptions = (name, colList) => {
    return {
      sort: false,
      customBodyRender: (value) => this.delayTextColor(value),
      display: this.state[colList][name]
    }
  }

  delayOptions = (name, colList, t) => {
    return {
      sort: false,
      customBodyRender: (value) => this.delayToIcon(value, t),
      display: this.state[colList][name]
    }
  }

  activeOptions = (colList) => {
    return {
      filter: false, searchable: false, sort: false,
      customBodyRender: (value) => {
        switch (value) {
          case "active":
            return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
          case "inactive":
            return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "red" }} />
          case "scheduled":
            return <FontAwesomeIcon icon={faClock} size="lg" style={{ color: "lightgray" }} />
          case "canceled":
            return <strong style={{ color: "red" }}>CNL</strong>
          default:
            return "";
        }
      },
      display: this.state[colList]["active"],
    }
  }

  noteDesktopOptions = (colList) => {
    return {
      filter: false, searchable: false, sort: false,
      customBodyRender: (tt_note) => {
        if (tt_note !== "" && tt_note !== null) {
          return (
            <Tooltip title={tt_note}><div>
              <FontAwesomeIcon
                icon={faCommentAlt}
                size="lg"
                style={{ color: "gray" }}
              />
            </div></Tooltip>
          )
        }
        else
          return null;
      },
      display: this.state[colList]["note"],
    }
  }

  infoDesktopOptions = (name, colList, field) => {
    return {
      filter: false, searchable: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (value) => {
        if (value !== "" && value !== null) {
          let color = 'gray'
          if(field === 'info') {
            color = '#C20E1A'
          }

          return (
            <CustomTooltip title={value}><div>
              <FontAwesomeIcon
                icon={faCommentAlt}
                size="lg"
                style={{ color: color }}
              />
            </div></CustomTooltip>
          )
        }
        else
          return null;
      },
      display: this.state[colList][field],
    }
  }

  noteMobileOptions = (colList) => {
    return {
      filter: false, searchable: false, sort: false,
      customBodyRender: (tt_note) => {
        if (tt_note !== "" && tt_note !== null) {
          return tt_note.length > 16 ? tt_note.substring(0, 15) + "..." : tt_note;
        }
        else
          return null;
      },
      display: this.state[colList]["note"]
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  };

  a11yProps = (index) => {
    return {

      id: `nav-tab-${index}`,
      'aria-controls': `nav-linkTab-${index}`,
    };
  }

  currencyOptions = (name, label, colList, t) => {
    return {
      filter: true, sort: false,
      customBodyRender: (value) => `${value} ${t('currency_symbol')}`,
      display: this.state[colList][name]
    }
  }

  render() {
    const { classes } = this.props;
    const { tableLayout } = this.state;
    const { t, i18n } = this.props;

    const columns = tableLayout === "scroll" ?
      [ // full desktop view
        { name: "id", label: "ID", options: this.standardOptions("id", "analysisColListDesktop") },
        { name: "active", label: t("analysisArea_active"), options: this.activeOptions("analysisColListDesktop") },
        { name: "tour", label: t("analysisArea_tour_nr"), options: this.standardOptions("tour", "analysisColListDesktop") },
        { name: "from", label: t("general_from"), options: this.standardOptions("from", "analysisColListDesktop") },
        { name: "to", label: t("general_to"), options: this.standardOptions("to", "analysisColListDesktop") },
        { name: "tour_combination", label: t("general_tour_combination"), options: this.standardOptions("tour_combination", "analysisColListDesktop") },
        { name: "pos", label: "P", options: this.delayOptions("pos", "analysisColListDesktop", t) },
        { name: "stp", label: "STP", options: Object.assign({}, this.dateOptions("stp", "analysisColListDesktop"), { sortDirection: 'asc' }) },
        { name: "etp", label: "ETP/ATP", options: this.dateOptions("etp", "analysisColListDesktop") },
        { name: "pos_delay", label: "P Delay", options: this.textDelayOptions("pos_delay", "analysisColListDesktop") },
        { name: "dep", label: "D", options: this.delayOptions("dep", "analysisColListDesktop", t) },
        { name: "std", label: "STD", options: this.dateOptions("std", "analysisColListDesktop") },
        { name: "atd", label: "ATD", options: this.dateOptions("atd", "analysisColListDesktop") },
        { name: "dep_delay", label: "D Delay", options: this.textDelayOptions("dep_delay", "analysisColListDesktop") },
        { name: "arr", label: "A", options: this.delayOptions("arr", "analysisColListDesktop", t) },
        { name: "sta", label: "STA", options: this.dateOptions("sta", "analysisColListDesktop") },
        { name: "eta", label: "ETA/ATA", options: this.dateOptions("eta", "analysisColListDesktop") },
        { name: "arr_delay", label: "A Delay", options: this.textDelayOptions("arr_delay", "analysisColListDesktop") },
        { name: "gps", label: t("analysisArea_car"), options: this.standardOptions("gps", "analysisColListDesktop") },
        { name: "sub", label: t("analysisArea_subcontractor"), options: this.standardOptions("sub", "analysisColListDesktop") },
        { name: "driver", label: t("analysisArea_driver"), options: this.standardOptions("driver", "analysisColListDesktop") },
        { name: "customer", label: t("analysisArea_customer"), options: this.standardOptions("customer", "analysisColListDesktop") },
        ...(localStorage.getItem('role') === "admin" ? [{ name: "c_trip", label: t("analysisArea_c_trip"), options: this.currencyOptions("c_trip", t("dashboard_customer_invoice_trip"), "analysisColListDesktop", t) }] : []),
        { name: "c_toll", label: t("analysisArea_c_toll"), options: this.currencyOptions("c_toll", t("dashboard_customer_invoice_toll"), "analysisColListDesktop", t) },
        { name: "c_waiting", label: t("analysisArea_c_waiting"), options: this.currencyOptions("c_waiting", t("dashboard_customer_invoice_waiting_fee"),"analysisColListDesktop", t) },
        { name: "c_cnl", label: t("analysisArea_c_cnl"), options: this.currencyOptions("c_cnl", t("dashboard_customer_invoice_cancellation_fee"),"analysisColListDesktop", t) },
        { name: "s_trip", label: t("analysisArea_s_trip"), options: this.currencyOptions("s_trip", t("dashboard_sub_invoice_trip"),"analysisColListDesktop", t) },
        { name: "s_toll", label: t("analysisArea_s_toll"), options: this.currencyOptions("s_toll", t("dashboard_sub_invoice_toll"),"analysisColListDesktop", t) },
        { name: "s_waiting", label: t("analysisArea_s_waiting"), options: this.currencyOptions("s_waiting", t("dashboard_sub_invoice_waiting_fee"),"analysisColListDesktop", t) },
        { name: "s_cnl", label: t("analysisArea_s_cnl"), options: this.currencyOptions("s_cnl", t("dashboard_sub_invoice_cancellation_fee"),"analysisColListDesktop", t) },
        { name: "info", label: t("dashboard_info"), options: this.infoDesktopOptions("info", "analysisColListDesktop", "info") },
        ...(localStorage.getItem('role') === "admin" ? [{ name: "note", label: t("dashboard_notes"), options: this.noteDesktopOptions("analysisColListDesktop") }] : [])
      ] :
      [ // reduced mobile view
        { name: "id", label: "ID", options: this.standardOptions("id", "analysisColListMobile") },
        { name: "active", label: t("analysisArea_active"), options: this.activeOptions("analysisColListMobile") },
        { name: "tour", label: t("analysisArea_tour_nr"), options: this.standardOptions("tour", "analysisColListMobile") },
        { name: "from", label: t("general_from"), options: this.standardOptions("from", "analysisColListMobile") },
        { name: "to", label: t("general_to"), options: this.standardOptions("to", "analysisColListMobile") },
        { name: "overview", label: "P/D/A", options: this.standardOptions("overview", "analysisColListMobile") },
        { name: "stp", label: "STP", options: Object.assign({}, this.dateOptions("stp", "analysisColListMobile"), { sortDirection: 'asc' }) },
        { name: "etp", label: "ETP/ATP", options: this.dateOptions("etp", "analysisColListMobile") },
        { name: "std", label: "STD", options: this.dateOptions("std", "analysisColListMobile") },
        { name: "atd", label: "ATD", options: this.dateOptions("atd", "analysisColListMobile") },
        { name: "sta", label: "STA", options: this.dateOptions("sta", "analysisColListMobile") },
        { name: "eta", label: "ETA/ATA", options: this.dateOptions("eta", "analysisColListMobile") },
        { name: "gps", label: t("analysisArea_car"), options: this.standardOptions("gps", "analysisColListMobile") },
        { name: "sub", label: t("analysisArea_subcontractor"), options: this.standardOptions("sub", "analysisColListMobile") },
        { name: "driver", label: t("analysisArea_driver"), options: this.standardOptions("driver", "analysisColListMobile") },
      ];

    const options = {
      pagination: false,
      elevation: 0,
      print: false,
      download: true,
      selectableRows: false,
      responsive: tableLayout,
      customSearch: {},
      filter: false,
      search: false,
      viewColumns: true,
      onColumnViewChange: (changedColumn, action) => {
        const thelist = tableLayout === "scroll" ? "analysisColListDesktop" : "analysisColListMobile"
        let tempColList = { ...this.state[thelist] }
        tempColList[changedColumn] = action === "add" ? true : false
        this.setState({ [thelist]: tempColList })
        localStorage.setItem(thelist, JSON.stringify(tempColList))
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        let request = this.buildRequest();
        this.exportExcel(request);
        return false;
      },
      textLabels: {
        body: {
          noMatch: t("analysisArea_no_trips_match_criteria"),
          toolTip: t("general_sort"),
        },
        toolbar: {
          search: t("general_search"),
          viewColumns: t("analysisArea_select_cols"),
          filterTable: t("analysisArea_filter_tab"),
          downloadCsv: "Download",
        },
        filter: {
          all: t("analysisArea_all_trips"),
          title: t("analysisArea_filter"),
          reset: t("analysisArea_filter_reset"),
        },
        viewColumns: {
          title: t("analysisArea_select_cols"),
          titleAria: t("analysisArea_show_hide_cols"),
        },
      }
    };

    const buildDropdown = (prop) => {
      return prop in this.state
        ? this.state[prop].map(item => ({ value: item, label: item }))
        : [];
    }

    const subDropdown = buildDropdown("subcontractor");
    const customerDropdown = buildDropdown("customer");
    const tourDropdown = buildDropdown("tour");
    const positioningDropdown = buildDropdown("positioning");
    const departureDropdown = buildDropdown("departure");
    const arrivalDropdown = buildDropdown("arrival");
    const gpsDropdown = buildDropdown("gps");
    const driverDropdown = buildDropdown("driver");
    const fromDropdown = buildDropdown("from");
    const toDropdown = buildDropdown("to");
    const activeDropdown = "active" in this.state ? this.state["active"].map(item => ({ value: item, label: item[0].toUpperCase() + item.slice(1) })) : [];

    const clearFilters = () => {
      this.setState({
        tt_sub: null,
        tt_customer: null,
        tt_tour: null,
        tt_p: null,
        tt_d: null,
        tt_a: null,
        tt_gps: null,
        tt_driver: null,
        tt_status: null,
        tt_from_text: null,
        tt_to_text: null
      }, () => {
        if (this.state.start_date !== pastWeek || this.state.end_date !== currentDay) {
          this.setState({
            start_date: pastWeek,
            end_date: currentDay
          }, this.updateData)
        }
        else {
          this.filterData();
        }
      })
    }

    const toggleFilters = () => {
      let previousState = this.state.openFilter;
      this.setState({ openFilter: !previousState });
    };

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("analysisArea_analysisArea")}</h4>
              </CardHeader>
              <CardBody>
                {/* START FILTERS*/}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomDate
                      inputProps={{
                        label: t("report_start_date"),
                        id: "dateDepature",
                        value: this.state.start_date,
                        onChange: (event) => {
                          this.setState({ loading: true, start_date: event.toISOString() }, this.updateData);
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomDate
                      inputProps={{
                        label: t("report_end_date"),
                        id: "dateArrival",
                        value: this.state.end_date,
                        onChange: (event) => {
                          this.setState({ loading: true, end_date: event.toISOString() }, this.updateData);
                        }
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <Box className={classes.floatRight}>
                      <Button onClick={clearFilters} color="primary">
                        {t("analysisArea_clearFilters")}
                      </Button>
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                      labelText={"analysisArea_subcontractor"}
                      id="subcontractor"
                      value={subDropdown.filter(el => el.value === this.state.tt_sub)}
                      onChange={value => {
                        this.setState({ loading: true, tt_sub: value != null ? value.value : null }, this.filterData);
                      }}
                      selectChoices={subDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                      labelText={"analysisArea_customer"}
                      id="customer"
                      disabled={localStorage.getItem('role') === "sub"}
                      value={customerDropdown.filter(el => el.value === this.state.tt_customer)}
                      onChange={value => {
                        this.setState({ loading: true, tt_customer: value != null ? value.value : null }, this.filterData);
                      }}
                      selectChoices={customerDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomAutoComplete
                      labelText={"analysisArea_tour"}
                      id="tour"
                      value={tourDropdown.filter(el => el.value === this.state.tt_tour)}
                      onChange={value => {
                        this.setState({ loading: true, tt_tour: value != null ? value.value : null }, this.filterData);
                      }}
                      selectChoices={tourDropdown}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <span className={classes.advancedFiltersWrapper} onClick={toggleFilters}>
                      <span className={classes.advancedFilters}>
                        <CollapseButton title={t("analysisArea_advancedFilters")} openStatus={this.state.openFilter} />
                      </span>
                    </span>
                  </GridItem>
                </GridContainer>
                <Collapse in={this.state.openFilter} timeout="auto" unmountOnExit>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"analysisArea_p"}
                        id="positioning"
                        value={positioningDropdown.filter(el => el.value === this.state.tt_p)}
                        onChange={value => {
                          this.setState({ loading: true, tt_p: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={positioningDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"analysisArea_d"}
                        id="departure"
                        onChange={value => {
                          this.setState({ loading: true, tt_d: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={departureDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"analysisArea_a"}
                        id="arrival"
                        value={arrivalDropdown.filter(el => el.value === this.state.tt_a)}
                        onChange={value => {
                          this.setState({ loading: true, tt_a: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={arrivalDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"analysisArea_car"}
                        id="vehicle"
                        value={gpsDropdown.filter(el => el.value === this.state.tt_gps)}
                        onChange={value => {
                          this.setState({ loading: true, tt_gps: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={gpsDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"analysisArea_driver"}
                        id="driver"
                        onChange={value => {
                          this.setState({ loading: true, tt_driver: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={driverDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"analysisArea_active"}
                        id="active"
                        value={activeDropdown.filter(el => el.value === this.state.tt_status)}
                        onChange={value => {
                          this.setState({ loading: true, tt_status: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={activeDropdown}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"general_from"}
                        id="from_adrnr"
                        value={fromDropdown.filter(el => el.value === this.state.tt_from_text)}
                        onChange={value => {
                          this.setState({ loading: true, tt_from_text: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={fromDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomAutoComplete
                        labelText={"general_to"}
                        id="to_adrnr"
                        value={toDropdown.filter(el => el.value === this.state.tt_to_text)}
                        onChange={value => {
                          this.setState({ loading: true, tt_to_text: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={toDropdown}
                      />
                    </GridItem>
                  </GridContainer>
                </Collapse>
                {/* END FILTERS */}
                {/* TABS */}
                <AppBar position="static" color="transparent" className={classes.tabBar}>
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChange}
                  >
                    <LinkTab label={t("analysisArea_pieCharts")} {...this.a11yProps(0)} />
                    <LinkTab label={t("analysisArea_dataTable")} {...this.a11yProps(1)} />
                  </Tabs>
                </AppBar>
                {!this.state.loading &&
                  <Box>
                    <TabPanel label="Pie Chart" value={this.state.tabValue} index={0}>
                      <GridContainer>
                        {!isPieDataEmpty(this.state.dataPiePositioning) &&
                          <GridItem xs={12} sm={12} md={4}>
                            <h4 className="pie-chart-title">
                              {t("analysisArea_p")}
                            </h4>
                            <div className="pie-chart-wrapper">
                              <Donut data={this.state.dataPiePositioning} />
                            </div>
                          </GridItem>
                        }
                        {!isPieDataEmpty(this.state.dataPieDeparture) &&
                          <GridItem xs={12} sm={12} md={4}>
                            <h4 className="pie-chart-title">
                              {t("analysisArea_d")}
                            </h4>
                            <div className="pie-chart-wrapper">
                              <Donut data={this.state.dataPieDeparture} />
                            </div>
                          </GridItem>
                        }
                        {!isPieDataEmpty(this.state.dataPieArrival) &&
                          <GridItem xs={12} sm={12} md={4}>
                            <h4 className="pie-chart-title">
                              {t("analysisArea_a")}
                            </h4>
                            <div className="pie-chart-wrapper">
                              <Donut data={this.state.dataPieArrival} />
                            </div>
                          </GridItem>
                        }
                      </GridContainer>
                    </TabPanel>
                    <TabPanel label="Table" value={this.state.tabValue} index={1}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MuiThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable
                              key={
                                this.state.tableLayout +
                                this.state.updateDT +
                                i18n.language
                              }
                              title={""}
                              data={this.state.displayedData}
                              columns={columns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </GridItem>
                      </GridContainer>
                    </TabPanel>
                  </Box>
                }
                {this.state.loading &&
                  <CircularProgress disableShrink className={classes.circularProgress} />
                }
              </CardBody>
            </Card>
            <Snackbar
              place="tc" //topcenter
              color="primary"
              message={this.state.message}
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </GridItem>
        </GridContainer>
      </div >
    );
  }
}

AnalysisArea.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(analysisAreaStyle)(AnalysisArea));
