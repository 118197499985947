import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ToolbarButton from "components/ToolbarButton/ToolbarButton.jsx";
import CustomTooltip from "components/Tooltip/CustomTooltip.jsx";
import MUIDataTable from "mui-datatables";
import {api_ipaddress} from '../../variables.js'
import axios from 'axios';
import AlertDialog from "components/Dialog/Dialog.jsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faFilePdf} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faCheckSquare, faCommentAlt, faInfoCircle, faParking, faSquare, faTimes, faTruck} from '@fortawesome/free-solid-svg-icons'
import Switch from '@material-ui/core/Switch';
import {initlocalStorage} from 'functions/sessionInit.js'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {primaryColor} from "assets/jss/material-dashboard-react.jsx";

import {floatToText} from "../../functions/helpers";
import {withTranslation} from 'react-i18next';

import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'

var moment = require('moment-timezone');

const shortenLongString = (string) => {
  return string.length > 12 ? string.substring(0, 12) + "..." : string
}

const timeDelayInMinutes = (dtActual, dtTarget) => {
  return moment.duration(moment(dtActual).diff(moment(dtTarget))).asMinutes()
}

const textFromDelay = (DelayMinutes, t) => {
  return DelayMinutes >= 16 ?
    t("dashboard_delayed") :
    DelayMinutes >= 1 ?
      t("dashboard_marg") :
      t("dashboard_ontime")
}

const getDelayStatus = (r, event, t) => {
  let driving, time_departure, DelayMinutes
  if (event === "positioning") {
    if (r.tt_etp == null) return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_etp, r.tt_stp)
    driving = r.tt_atp ? t("dashboard_arrived") : t("dashboard_in_transit")
  } else if (event === "departure") {
    if (r.tt_atp == null) return "-"
    time_departure = r.tt_atd ? moment(r.tt_atd) : moment()
    DelayMinutes = timeDelayInMinutes(time_departure, r.tt_std)
    driving = r.tt_atd ? t("dashboard_departed") : t("dashboard_waiting")
  } else if (event === "arrival") {
    if (r.tt_eta == null) return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_eta, r.tt_sta)
    driving = r.tt_ata ? t("dashboard_arrived") : t("dashboard_in_transit")
  }
  return `${textFromDelay(DelayMinutes, t)} (${driving})`
}


const getDrivingStatus = (r, t) => {
  if (r.tt_status === "inactive")
    return t("dashboard_inactive")
  if (r.tt_status === "canceled")
    return t("dashboard_canceled")
  if (r.tt_ignition == null || r.tt_ata != null ||
    (r.tt_etp == null & r.tt_eta == null)) return "-"
  const status = r.tt_ignition === 1 && (r.tt_atd != null || r.tt_atp == null) ?
    t("dashboard_drives") : t("dashboard_stands")
  return status
}

const getDayOfTheWeek = (momentObj, t)  => {
  let dayOfWeek = momentObj.isoWeekday()
  let dayAbbr = ""
  switch (dayOfWeek) {
    case 1:
      dayAbbr = t("general_weekday_abbr_monday")
      break;
    case 2:
      dayAbbr = t("general_weekday_abbr_tuesday")
      break;
    case 3:
      dayAbbr = t("general_weekday_abbr_wednesday")
      break;
    case 4:
      dayAbbr = t("general_weekday_abbr_thursday")
      break;
    case 5:
      dayAbbr = t("general_weekday_abbr_friday")
      break;
    case 6:
      dayAbbr = t("general_weekday_abbr_saturday")
      break;
    case 7:
      dayAbbr = t("general_weekday_abbr_sunday")
      break;
    default:
      dayAbbr = momentObj.format("dd")
      break;
  }
  return dayAbbr;
}


const asTime = (momentObj, t) => {
  try {
    return momentObj.isValid() ? `${getDayOfTheWeek(momentObj, t)}  ${momentObj.format("HH:mm")}` : ""
  }
  catch (e) { return "" }
}

const asDate = (momentObj) => {
  try {
    return momentObj.isValid() ? momentObj.format("DD.MM.YYYY") : ""
  }
  catch (e) { return "" }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      tableLayout: "scroll",
      alertAskEdit: false,
      tc: false,
      message: "",
      updateDT: 1,
      filterList: [],
      searchText: "",
      sortedCol: "stp",
      sortDirection: "asc",
      colListDesktop: {},
      colListMobile: {},
      data: "",
    };
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }


  componentWillMount() {
    initlocalStorage()
  }

  componentDidMount() {
    moment.tz.setDefault();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    this.setState({
      filterList: JSON.parse(localStorage.getItem("filterList")),
      colListDesktop: JSON.parse(localStorage.getItem("colListDesktop")),
      colListMobile: JSON.parse(localStorage.getItem("colListMobile")),
    })

    this.updateData()
    this.intervalID = setInterval(
      () => this.updateData(),
      30000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  updateData() {
    axios.get(`${api_ipaddress}/api/timetable`,
      {
        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
        params: {
          role: localStorage.getItem('role'),
          username: localStorage.getItem('username'),
          view: localStorage.getItem('dashboard_view'),
        }
      })
      .then(res => {
        this.setState({
          data: res.data,
        })
      })
      .catch(err => {
        this.setState({
          data: [],
        })
      })
  }

  resize() {
    this.setState({
      tableLayout: window.innerWidth <= 620 ? "stackedFullWidth" : "scroll",
      windowHeight: window.innerHeight
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableRow: {
        root: {
          height: "24px",
        }
      },
      MuiTableCell: {
        root: this.state.tableLayout === "scroll" ? {
          paddingRight: "4px",
          paddingTop: "6px",
          paddingLeft: "4px",
          paddingBottom: "6px",
          whiteSpace: "nowrap",
        } : {
            paddingTop: "4px",
            paddingBottom: "1px",
          }
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          width: "calc(50% - 32px) !important"
        }
      },
      MUIDataTable: {
        paperResponsiveScrollFullHeightFullWidth :{
          position: "relative",
        },
        responsiveScroll: {
          maxHeight: window.innerWidth <= 1280 ?
            `${this.state.windowHeight - 255}px !important` :
            `${this.state.windowHeight - 215}px !important`,
          minHeight: "300px",
        },
      },
    }
  })


  delayToIcon = (value, t) => {
    if (value == null) return null
    const iconColor =
      value.includes(t("dashboard_ontime")) ? "#23c644" :
        value.includes(t("dashboard_marg")) ? "#ffae00" :
          value.includes(t("dashboard_delayed")) ? "#ff4300" :
            "lightgrey"
    const icon =
      value.includes(t("dashboard_arrived")) || value.includes(t("dashboard_departed")) ?
        <FontAwesomeIcon icon={faCheckSquare} size="lg"
          style={{ color: iconColor, paddingRight: "4px" }} /> :
        <FontAwesomeIcon icon={faSquare} size="lg"
          style={{ color: iconColor, paddingRight: "4px" }} />
    return (iconColor !== "lightgrey" && this.state.tableLayout === "scroll") ?
      <Tooltip title={value}><div>{icon}</div></Tooltip> :
      icon;
  }

  drivingToIcon = (value, t) => {
    if (value === "-" || value === t("dashboard_inactive")) return " "
    const icon =
      value.includes(t("dashboard_drives")) ?
        <FontAwesomeIcon icon={faTruck} size="lg"
          style={{ color: "gray", paddingRight: "4px" }} />
        :
        value.includes(t("dashboard_stands")) ?
          <FontAwesomeIcon icon={faParking} size="lg"
            style={{ color: "lightgray", paddingRight: "4px" }} />
          : ""
    return this.state.tableLayout === "scroll" ?
      <Tooltip title={t("dashboard_car") + " " + value}><div>{icon}</div></Tooltip> :
      icon;
  }

  // column specifications
  timeOptions = (name, label, colList, t) => {
    return {
      filter: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (momentObj) => asTime(momentObj, t),
      display: this.state[colList][name]
    }
  }

  dateOptions = (name, label, colList) => {
    return {
      filter: true, sort: true,
      filterList: this.state.filterList[name],
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (momentObj) => asDate(momentObj),
      display: this.state[colList][name]
    }
  }

  standardOptions = (name, label, colList) => {
    return {
      filter: true, sort: true,
      filterList: this.state["filterList"][name],
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customFilterListRender: v => `${label}: ${v}`,
      display: this.state[colList][name]
    }
  }
  currencyOptions = (name, label, colList, t) => {
    return {
      filter: true, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",

      filterList: this.state["filterList"][name],
      customFilterListRender: v => `${label}: ${v}`,
      customBodyRender: (value) => value ? `${floatToText(value)} ${t('currency_symbol')}` : `0,00 ${t('currency_symbol')}`,
      display: this.state[colList][name]
    }
  }
  delayOptions = (name, label, colList, dep = false, t) => {
    return {
      filter: true, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      filterList: this.state.filterList[name],
      customFilterListRender: v => `${label}: ${v}`,
      customBodyRender: (value) => this.delayToIcon(value, t),
      display: this.state[colList][name],
      filterOptions: dep ?
        [
          `${t("dashboard_ontime")} (${t("dashboard_departed")})`,
          `${t("dashboard_ontime")} (${t("dashboard_waiting")})`,
          `${t("dashboard_marg")} (${t("dashboard_departed")})`,
          `${t("dashboard_marg")} (${t("dashboard_waiting")})`,
          `${t("dashboard_delayed")} (${t("dashboard_departed")})`,
          `${t("dashboard_delayed")} (${t("dashboard_waiting")})`,
        ] :
        [
          `${t("dashboard_ontime")} (${t("dashboard_arrived")})`,
          `${t("dashboard_ontime")} (${t("dashboard_in_transit")})`,
          `${t("dashboard_marg")} (${t("dashboard_arrived")})`,
          `${t("dashboard_marg")} (${t("dashboard_in_transit")})`,
          `${t("dashboard_delayed")} (${t("dashboard_arrived")})`,
          `${t("dashboard_delayed")} (${t("dashboard_in_transit")})`,
        ]
    }
  }
  drivingOptions = (name, label, colList, t) => {
    return {
      filter: true, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      filterList: this.state.filterList[name],
      customFilterListRender: v => `${label}: ${v}`,
      customBodyRender: (v) => this.drivingToIcon(v, t),
      display: this.state[colList][name],
      filterOptions: [t("dashboard_drives"), t("dashboard_stands")]
    }
  }
  activeOptions = (colList) => {
    return {
      filter: false, searchable: false, sort: true,
      customBodyRender: (value) => {
        switch (value) {
          case "active":
            return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
          case "inactive":
            return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "red" }} />
          case "scheduled":
            return <FontAwesomeIcon icon={faClock} size="lg" style={{ color: "lightgray" }} />
          case "canceled":
            return <strong style={{ color: "red" }}>CNL</strong>
          default:
            return "";
        }
      },
      display: this.state[colList]["active"],
    }
  }

  noteDesktopOptions = (name, colList, field) => {
    return {
      filter: false, searchable: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (value) => {
        if (value !== "" && value !== null) {
          let color = 'gray'
          if(field === 'info') {
            color = '#C20E1A'
          }

          return (
            <CustomTooltip title={value}><div>
              <FontAwesomeIcon
                icon={faCommentAlt}
                size="lg"
                style={{ color: color }}
              />
            </div></CustomTooltip>
          )
        }
        else
          return null;
      },
      display: this.state[colList][field],
    }
  }

  noteMobileOptions = (colList) => {
    return {
      filter: false, searchable: false, sort: false,
      customBodyRender: (tt_note) => {
        if (tt_note !== "" && tt_note !== null) {
          return tt_note.length > 16 ? tt_note.substring(0, 15) + "..." : tt_note;
        }
        else
          return null;
      },
      display: this.state[colList]["note"]
    }
  }

  idOptions = (colList) => {
    return {
      display: this.state[colList]["id"],
      filter: false,
      sort: true,
      sortDirection: this.state.sortedCol === "id" ? this.state.sortDirection : "none",
      searchable: false
    }
  }
  overviewOptions = (colList) => {
    return {
      display: this.state[colList]["overview"],
      filter: false
    }
  }


  render() {
    const { tableLayout } = this.state;
    const { t, i18n } = this.props;

    const data = this.state.data !== "" ? this.state.data.map(
      r => ({
        id: r.tt_id,
        active: r.tt_status,
        tour: r.tt_tour,
        from: r.tt_from_text,
        to: r.tt_to_text,
        gps: r.tt_gps,
        date: moment(r.tt_stp),
        status: getDrivingStatus(r, t),
        pos: getDelayStatus(r, "positioning", t),
        stp: moment(r.tt_stp),
        etp: moment(r.tt_etp),
        dep: getDelayStatus(r, "departure", t),
        std: moment(r.tt_std),
        atd: moment(r.tt_atd),
        arr: getDelayStatus(r, "arrival", t),
        sta: moment(r.tt_sta),
        eta: moment(r.tt_eta),
        driver: r.tt_driver,
        note: localStorage.getItem('role') === "admin" ? r.tt_note : "",
        info: (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "customer" || localStorage.getItem('role') === "sub" || localStorage.getItem('role') === "observer") ? r.tt_info : "",
        overview: <div>
          {this.delayToIcon(getDelayStatus(r, "positioning", t), t)}
          {this.delayToIcon(getDelayStatus(r, "departure", t), t)}
          {this.delayToIcon(getDelayStatus(r, "arrival", t), t)}
          {this.drivingToIcon(getDrivingStatus(r, t), t)}
        </div>,
        customer: localStorage.getItem('role') === "admin" ? r.tt_customer.split(';')[0] : "",
        customer_invoice_cancellation_fee: r.tt_customer_invoice_cancellation_fee,
        customer_invoice_text: r.tt_customer_invoice_text,
        customer_invoice_toll: r.tt_customer_invoice_toll,
        customer_invoice_trip: r.tt_customer_invoice_trip,
        customer_invoice_waiting_fee: r.tt_customer_invoice_waiting_fee,
        subcontractor: r.tt_sub,
        sub_invoice_cancellation_fee: r.tt_sub_invoice_cancellation_fee,
        sub_invoice_comment: r.tt_sub_invoice_comment,
        sub_invoice_toll: r.tt_sub_invoice_toll,
        sub_invoice_trip: r.tt_sub_invoice_trip,
        sub_invoice_waiting_fee: r.tt_sub_invoice_waiting_fee,
        tour_combination: r.tt_tour_combination
      })
    )
      .filter(r =>
        (!r.arr.includes(t("dashboard_arrived")) &&
          (r.sta.clone().add(6, 'hours') > moment()) && (
            r.pos.includes(t("dashboard_marg")) || r.pos.includes(t("dashboard_delayed")) ||
            r.dep.includes(t("dashboard_marg")) || r.dep.includes(t("dashboard_delayed")) ||
            r.arr.includes(t("dashboard_marg")) || r.arr.includes(t("dashboard_delayed")))) ||
        !JSON.parse(localStorage.getItem("panicbutton")))
      :
      null

    const columns = tableLayout === "scroll" ?
      [ // full desktop view
        { name: "id", label: "ID", options: this.idOptions("colListDesktop") },
        { name: "active", label: t("dashboard_active"), options: this.activeOptions("colListDesktop") },
        { name: "tour", label: t("dashboard_tour_nr"), options: this.standardOptions("tour", t("dashboard_tour_nr"), "colListDesktop") },
        { name: "from", label: t("general_from"), options: this.standardOptions("from", t("general_from"), "colListDesktop") },
        { name: "to", label: t("general_to"), options: this.standardOptions("to", t("general_to"), "colListDesktop") },
        { name: "date", label: t("dashboard_date"), options: this.dateOptions("date", t("dashboard_date"), "colListDesktop", t) },
        { name: "status", label: t("dashboard_status"), options: this.drivingOptions("status", t("dashboard_status"), "colListDesktop", t) },
        { name: "pos", label: "P", options: this.delayOptions("pos", "P", "colListDesktop", false, t) },
        { name: "stp", label: "STP", options: this.timeOptions("stp", "STP", "colListDesktop", t) },
        { name: "etp", label: "ETP/ATP", options: this.timeOptions("etp", "ETP/ATP", "colListDesktop", t) },
        { name: "dep", label: "D", options: this.delayOptions("dep", "D", "colListDesktop", true, t) },
        { name: "std", label: "STD", options: this.timeOptions("std", "STD", "colListDesktop", t) },
        { name: "atd", label: "ATD", options: this.timeOptions("atd", "ATD", "colListDesktop", t) },
        { name: "arr", label: "A", options: this.delayOptions("arr", "A", "colListDesktop", false, t) },
        { name: "sta", label: "STA", options: this.timeOptions("sta", "STA", "colListDesktop", t) },
        { name: "eta", label: "ETA/ATA", options: this.timeOptions("eta", "ETA/ATA", "colListDesktop", t) },
        { name: "gps", label: t("dashboard_car"), options: this.standardOptions("gps", t("dashboard_car"), "colListDesktop") },
        { name: "driver", label: t("dashboard_driver"), options: this.standardOptions("driver", t("dashboard_driver"), "colListDesktop") }
      ] :
      [ // reduced mobile view
        { name: "id", label: "ID", options: this.idOptions("colListMobile") },
        { name: "active", label: t("dashboard_active"), options: this.activeOptions("colListMobile") },
        { name: "tour", label: t("dashboard_tour_nr"), options: this.standardOptions("tour", t("dashboard_tour_nr"), "colListMobile") },
        { name: "from", label: t("general_from"), options: this.standardOptions("from", t("general_from"), "colListMobile") },
        { name: "to", label: t("general_to"), options: this.standardOptions("to", t("general_to"), "colListMobile") },
        { name: "overview", label: "P/D/A", options: this.overviewOptions("colListMobile") },
        { name: "stp", label: "STP", options: this.timeOptions("stp", "STP", "colListMobile", t) },
        { name: "etp", label: "ETP/ATP", options: this.timeOptions("etp", "ETP/ATP", "colListMobile", t) },
        { name: "std", label: "STD", options: this.timeOptions("std", "STD", "colListMobile", t) },
        { name: "atd", label: "ATD", options: this.timeOptions("atd", "ATD", "colListMobile", t) },
        { name: "sta", label: "STA", options: this.timeOptions("sta", "STA", "colListMobile", t) },
        { name: "eta", label: "ETA/ATA", options: this.timeOptions("eta", "ETA/ATA", "colListMobile", t) },
        { name: "gps", label: t("dashboard_car"), options: this.standardOptions("gps", t("dashboard_car"), "colListMobile") },
        { name: "driver", label: t("dashboard_driver"), options: this.standardOptions("driver", t("dashboard_driver"), "colListMobile") }
      ];

    //Columns only visible to admin
    if (localStorage.getItem("role") === "admin") {
      if (tableLayout === "scroll"){
        columns.push({ name: "note", label: t("dashboard_notes"), options: this.noteDesktopOptions("note", "colListDesktop", "note") });
        columns.push({ name: "customer", label: t("dashboard_customer"), options: this.standardOptions("customer", t("dashboard_customer"), "colListDesktop") });
        columns.push({ name: "customer_invoice_trip", label: t("dashboard_customer_invoice_trip"), options: this.currencyOptions("customer_invoice_trip", t("dashboard_customer_invoice_trip"), "colListDesktop", t)});
        columns.push({ name: "customer_invoice_toll", label: t("dashboard_customer_invoice_toll"), options: this.currencyOptions("customer_invoice_toll", t("dashboard_customer_invoice_toll"), "colListDesktop", t)});
        columns.push({ name: "customer_invoice_waiting_fee", label: t("dashboard_customer_invoice_waiting_fee"), options: this.currencyOptions("customer_invoice_waiting_fee", t("dashboard_customer_invoice_waiting_fee"), "colListDesktop", t)});
        columns.push({ name: "customer_invoice_cancellation_fee", label: t("dashboard_customer_invoice_cancellation_fee"), options: this.currencyOptions("customer_invoice_cancellation_fee", t("dashboard_customer_invoice_cancellation_fee"), "colListDesktop", t)});
        columns.push({ name: "customer_invoice_text", label: t("dashboard_customer_invoice_text"), options: this.noteDesktopOptions("customer_invoice_text", "colListDesktop", "customer_invoice_text")});
        columns.push({ name: "subcontractor", label: t("dashboard_subcontractor"), options: this.standardOptions("subcontractor", t("dashboard_subcontractor"), "colListDesktop") });
        columns.push({ name: "sub_invoice_trip", label: t("dashboard_sub_invoice_trip"), options: this.currencyOptions("sub_invoice_trip", t("dashboard_sub_invoice_trip"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_toll", label: t("dashboard_sub_invoice_toll"), options: this.currencyOptions("sub_invoice_toll", t("dashboard_sub_invoice_toll"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_waiting_fee", label: t("dashboard_sub_invoice_waiting_fee"), options: this.currencyOptions("sub_invoice_waiting_fee", t("dashboard_sub_invoice_waiting_fee"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_cancellation_fee", label: t("dashboard_sub_invoice_cancellation_fee"), options: this.currencyOptions("sub_invoice_cancellation_fee", t("dashboard_sub_invoice_cancellation_fee"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_comment", label: t("dashboard_sub_invoice_comment"), options: this.noteDesktopOptions("sub_invoice_comment", "colListDesktop","sub_invoice_comment")});
        columns.push({ name: "tour_combination", label: t("dashboard_tour_combination"), options: this.standardOptions("tour_combination", t("dashboard_tour_combination"), "colListDesktop") });

      }else{
        columns.push({ name: "note", label: t("dashboard_notes"), options: this.noteMobileOptions("colListMobile", "note") })
      }
    }else if(localStorage.getItem("role") === "sub"){
      if (tableLayout === "scroll"){
        columns.push({ name: "sub_invoice_trip", label: t("dashboard_sub_invoice_trip"), options: this.currencyOptions("sub_invoice_trip", t("dashboard_sub_invoice_trip"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_toll", label: t("dashboard_sub_invoice_toll"), options: this.currencyOptions("sub_invoice_toll", t("dashboard_sub_invoice_toll"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_waiting_fee", label: t("dashboard_sub_invoice_waiting_fee"), options: this.currencyOptions("sub_invoice_waiting_fee", t("dashboard_sub_invoice_waiting_fee"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_cancellation_fee", label: t("dashboard_sub_invoice_cancellation_fee"), options: this.currencyOptions("sub_invoice_cancellation_fee", t("dashboard_sub_invoice_cancellation_fee"), "colListDesktop", t)});
        columns.push({ name: "sub_invoice_comment", label: t("dashboard_sub_invoice_comment"), options: this.noteDesktopOptions("sub_invoice_comment", "colListDesktop","sub_invoice_comment")});
        columns.push({ name: "tour_combination", label: t("dashboard_tour_combination"), options: this.standardOptions("tour_combination", t("dashboard_tour_combination"), "colListDesktop") });
      }
    }

    if (tableLayout === "scroll")
    {
      columns.push({ name: "info", label: t("dashboard_info"), options: this.noteDesktopOptions("info", "colListDesktop", "info") })
    } else {
      columns.push({ name: "info", label: t("dashboard_info"), options: this.noteMobileOptions("colListMobile", "info") })
    }

    const options = {
      filterType: 'multiselect',
      searchText: this.state.searchText,
      onSearchChange: searchText => {
        this.setState({
          searchText: searchText
        })
      },
      onFilterChange: (changedColumn, filterList) => {
        let tempfilterList = { ...this.state["filterList"] }
        const index = columns.findIndex(el => el.name === changedColumn)
        tempfilterList[changedColumn] = filterList[index]
        this.setState((prevState, props) => ({
          updateDT: prevState.updateDT + 1,
          filterList: tempfilterList
        }));
        localStorage.setItem("filterList", JSON.stringify(tempfilterList))
      },
      onColumnViewChange: (changedColumn, action) => {
        const thelist = tableLayout === "scroll" ? "colListDesktop" : "colListMobile"
        let tempColList = { ...this.state[thelist] }
        tempColList[changedColumn] = action === "add" ? true : false
        this.setState({ [thelist]: tempColList })
        localStorage.setItem(thelist, JSON.stringify(tempColList))
      },
      onColumnSortChange: (changedColumn, direction) => {
        this.setState({
          sortedCol: changedColumn,
          sortDirection: direction === "descending" ? "desc" : "asc",
        })
      },
      pagination: false,
      elevation: 0,
      print: false,
      download: false,
      selectableRows: false,
      responsive: tableLayout,
      onRowClick: (rowData, rowMeta) => {
        let role = localStorage.getItem('role');
        if(role === "admin"){
          this.setState({
            alertAskEdit: true,
            clickedTrip: rowData[columns.findIndex(el => el.name === "id")],
            clickedTripNr: rowData[columns.findIndex(el => el.name === "tour")],
          })
        }else if(role === "sub"){
          if (["active", "scheduled"].includes(data[rowMeta.dataIndex].active)) {
            this.setState({
              alertAskEdit: true,
              clickedTrip: rowData[columns.findIndex(el => el.name === "id")],
              clickedTripNr: rowData[columns.findIndex(el => el.name === "tour")],
            })
          }else{
            console.log("not allowed")
          }
        }else{
          console.log("not allowed")
        }
      },
      textLabels: {
        body: {
          noMatch: t("dashboard_no_trips_match_criteria"),
          toolTip: t("general_sort"),
        },
        toolbar: {
          search: t("general_search"),
          viewColumns: t("dashboard_select_cols"),
          filterTable: t("dashboard_filter_tab"),
        },
        filter: {
          all: t("dashboard_all_trips"),
          title: t("dashboard_filter"),
          reset: t("dashboard_filter_reset"),
        },
        viewColumns: {
          title: t("dashboard_select_cols"),
          titleAria: t("dashboard_show_hide_cols"),
        },
      },
      customToolbar: () => {
        return (
          <ToolbarButton icon={faFilePdf} onClick={() => {
            //TODO: Add loading screen...

            let filterList = JSON.parse(localStorage.getItem('filterList'));

            let request = { view: localStorage.getItem('dashboard_view'), filterList: filterList }
            axios.post(`${api_ipaddress}/api/downloadpdf`,
              request,
              {
                headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                params: {
                  role: localStorage.getItem('role'),
                  username: localStorage.getItem('username')
                }
              })
              .then(res => {
                const link = document.createElement('a');
                link.href = `data:application/pdf;base64,${res.data.file}`;
                link.setAttribute('download', 'truckwatch_view.pdf');
                document.body.appendChild(link);
                link.click();
                return false;
              })
              .catch((err) => {
                this.showNotification("tc", "Leider gab es ein Problem");
                return false;
              })
          }} />
        );
      }
    };

    const lastUpdate = this.state.data !== "" ?
      Math.max(...this.state.data.map(
        o => o.tt_last_update ? moment(o.tt_last_update) : 0), 0
      ) : null

    const lastUpdateDate = moment(lastUpdate)
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary"
                style={tableLayout === "scroll" ?
                  { display: "flex", justifyContent: "space-between" } :
                  {}}
              >
                <h4 style={tableLayout === "scroll" ?
                  { marginTop: "0px", marginBottom: "0px" } :
                  { marginTop: "0px", marginBottom: "10px" }}>
                  {t('dashboard_overview_trips')} ({t('dashboard_last_update')}: {
                    lastUpdate ? `${getDayOfTheWeek(lastUpdateDate, t)} ${lastUpdateDate.format("DD.MM. HH:mm")}` : t("general_unknown")
                  })
                </h4>
                <div style={{ marginTop: "-5px", marginBottom: "-10px"}}>
                  {tableLayout === "scroll" &&
                    <Tooltip title={
                      <table>
                        <tbody>
                          <tr><td>P</td><td>{t('dashboard_p')}</td></tr>
                          <tr><td>STP</td><td>{t('dashboard_stp')}</td></tr>
                          <tr><td>ETP/ATP</td><td>{t('dashboard_eatp')}</td></tr>
                          <tr><td>D</td><td>{t("dashboard_d")}</td></tr>
                          <tr><td>STD</td><td>{t('dashboard_std')}</td></tr>
                          <tr><td>ATD</td><td>{t("dashboard_atd")}</td></tr>
                          <tr><td>A</td><td>{t('dashboard_a')}</td></tr>
                          <tr><td>STA</td><td>{t('dashboard_sta')}</td></tr>
                          <tr><td>ETA/ATA</td><td>{t('dashboard_eata')}</td></tr>
                        </tbody>
                      </table>
                    }><span>
                        <FontAwesomeIcon icon={faInfoCircle} size="lg"
                          style={{ color: "white", paddingRight: "18px" }} />
                      </span>
                    </Tooltip>
                  }
                  <Select
                    inputProps={{
                      name: "view",
                      id: "view",
                      value: localStorage.getItem('dashboard_view'),
                      onChange: (event) => {
                        localStorage.setItem('dashboard_view', event.target.value)
                        localStorage.setItem('panicbutton', JSON.stringify(false))
                        this.setState({ data: "" })
                        this.updateData()
                      },
                    }}
                    fullWidth={tableLayout === "scroll" ? false : true}
                    style={{ color: "white" }}
                  >
                    <MenuItem value="next_week">{t("dashboard_view_next_week")}</MenuItem>
                    <MenuItem value="today">{t("dashboard_view_today")}</MenuItem>
                    <MenuItem value="current_week">{t("dashboard_view_current_week")}</MenuItem>
                    <MenuItem value="last_week">{t("dashboard_view_last_week")}</MenuItem>
                  </Select>
                  <Switch
                    checked={JSON.parse(localStorage.getItem('panicbutton'))}
                    onChange={() => {
                      this.setState((prevState, props) => ({
                        updateDT: prevState.updateDT + 1,
                      }));
                      const temp = JSON.parse(localStorage.getItem('panicbutton'))
                      localStorage.setItem('panicbutton', JSON.stringify(!temp))
                    }}
                    disabled={localStorage.getItem('dashboard_view') === "last_week"}
                    value="panic"
                    style={{ color: "white" }}
                  />
                  {t("dashboard_current_delays")}
                </div>
              </CardHeader>
              <CardBody>
                {this.state.data !== "" &&
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      key={
                        this.state.tableLayout +
                        this.state.updateDT +
                        i18n.language
                      }
                      title={""}
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>}
                {this.state.data === "" &&
                  <CircularProgress
                    disableShrink
                    style={{
                      color: primaryColor[0],
                      margin: "auto",
                      display: "block"
                    }} />}
              </CardBody>
            </Card>
            <AlertDialog
              open={this.state.alertAskEdit}
              onClose={() => this.setState({ alertAskEdit: false })}
              handleNo={() => this.setState({ alertAskEdit: false })}
              handleYes={() => this.props.history.push(`/admin/edit/${this.state.clickedTrip}`)}
              title={`${t("edit_edit_trip")}: ${this.state.clickedTripNr}?`}
            // content={"Dies leitet Sie auf die Bearbeitungsmaske weiter."}
            />
            <Snackbar
              place="tc" //topcenter
              color="primary"
              message={this.state.message}
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(dashboardStyle)(Dashboard));
