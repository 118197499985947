import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from '@material-ui/core/Tabs';

// core components
import tabsStyle from "assets/jss/material-dashboard-react/components/tabsStyle.jsx";

function customTabs(props) {

  return (
    <Tabs
      variant="fullWidth"
      value={props.tabValue}
      onChange={props.handleChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: "#c20e1a"
        }
      }}
      {...props}
    ></Tabs>
  );
}

export default withStyles(tabsStyle)(customTabs);
