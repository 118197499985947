import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Box from '@material-ui/core/Box';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomAutoComplete from "components/CustomInput/CustomAutocomplete.jsx";
import CustomCheckbox from "components/CustomInput/CustomCheckbox.jsx";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AlertDialog from "components/Dialog/Dialog.jsx";
import Tags from "components/Tags/Tags.jsx";
import Chip from '@material-ui/core/Chip';

import { api_ipaddress } from '../../variables.js'
import axios from 'axios'

import { withTranslation } from 'react-i18next';

import { sprintf } from "sprintf-js";
import MUIDataTable from "mui-datatables";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {initlocalStorage} from "functions/sessionInit";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from 'lodash';

const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

class Alerts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      mode: "overview", // shows table, or add / edit
      alertId: "",
      alertType: null,
      active: true,
      customer: null,
      sub: null,
      lang: null,
      adrnrChoices: [],
      alertSubChoices: [],
      alertCustomerChoices: [],
      adrnrArr: [],
      emailArr: [],
      smsArr: [],
      updateDT: 1,
      loading: true,
      al_active: null,
      al_type: null,
      al_customer: null,
      al_sub: null,
      al_adrnr_arr: [],
      al_email_arr: [],
      al_sms_arr: [],
      al_lang: null,
      alertListColListDesktop: {},
      alertListColListMobile: {},
      message: ""
    };
  }

  componentWillMount() {
    initlocalStorage()
  }

  filterData() {
    const {t} = this.props;
    let filter = {};

    const props = [
      "al_active",
      "al_type",
      "al_customer",
      "al_sub",
      "al_adrnr_arr",
      "al_email_arr",
      "al_sms_arr",
      "al_lang",
    ]

    props.forEach(item => {
      if(this.state[item] !== null)
        filter[item] = this.state[item];
    });

    let filteredData = _.filter(this.state.alerts, filter);
    this.renderDropdowns(filteredData);
    this.renderTable(filteredData);
    this.setState({ loading: false });
  }

  renderDropdowns(data) {
    const { t } = this.props;

    //build dataset for dropdowns
    this.setState({ dropData_type : [...new Set(data.filter(f => f.al_type != null).map(r => r.al_type))].sort() });
    this.setState({ dropData_customer : [...new Set(data.filter(f => f.al_customer != null).map(r => r.al_customer))].sort() });
    this.setState({ dropData_sub : [...new Set(data.filter(f => f.al_sub != null).map(r => r.al_sub))].sort() });
    this.setState({ dropData_lang : [...new Set(data.filter(f => f.al_lang != null).map(r => r.al_lang))].sort() });
  }

  renderTable(tableData) {
    this.setState({ displayedData: tableData });
  }

  componentDidMount() {

    const { t } = this.props;
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    this.setState({
      filterList: JSON.parse(localStorage.getItem("filterList")),
      alertListColListDesktop: JSON.parse(localStorage.getItem("alertListColListDesktop")),
      alertListColListMobile: JSON.parse(localStorage.getItem("alertListColListMobile"))
    }, this.updateData(t));

  }

  updateData(t) {


    axios.get(`${api_ipaddress}/api/address`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const address_choices = "data" in res
          ? res.data.map(item => ({ value: item.add_id, label: `${item.add_name} (${item.add_id})` }))
          : [];
        this.setState({ adrnrChoices: address_choices });
      });

    axios.get(`${api_ipaddress}/api/customers/all`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        let customer_choices = "data" in res
          ? res.data.map(customer => ({ value: customer.cm_id, label: customer.cm_name }))
          : [];
        this.setState({ alertCustomerChoices: customer_choices });
      });

    axios.get(`${api_ipaddress}/api/subcontractors`, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const subcontractor_choices = "data" in res
          ? res.data.map(item => ({ value: item.sc_name, label: item.sc_name }))
          : [];
        this.setState({ alertSubChoices: subcontractor_choices });
      });

    axios.get(`${api_ipaddress}/api/alerts`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {
        const data = res.data.alerts
        data.forEach( d => {
          d["translated_type"] = t(d.al_type)
          d["actions"] = d
        })
        this.setState({ alerts: data });
        this.filterData();
      });


    this.setState({ loading: false });
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  setAddrnr = (tags) => { this.setState({ adrnrArr: tags }) }
  setEmail = (tags) => { this.setState({ emailArr: tags }) }
  setSms = (tags) => { this.setState({ smsArr: tags }) }

  validateForm = () => {
    const {
      alertType,
      lang,
      emailArr,
      smsArr
    } = this.state;

    //Required Alert Type
    if (alertType === null)
      return false;

    //Required Language
    if (lang === null)
      return false;

    //At least one email or one phone number
    if (emailArr !== null && smsArr !== null && emailArr.length + smsArr.length === 0)
      return false;

    return true;
  }

  resize() {
    this.setState({
      tableLayout: window.innerWidth <= 620 ? "stacked" : "scroll",
      windowHeight: window.innerHeight
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableRow: {
        root: {
          height: "24px",
        }
      },
      MuiTableCell: {
        root: this.state.tableLayout === "scroll" ?
          {
            paddingRight: "4px",
            paddingTop: "6px",
            paddingLeft: "4px",
            paddingBottom: "6px",
            whiteSpace: "wrap",
            maxWidth: "20em",
            overflow: "hidden",
            textOverflow: "ellipsis"
          } : {
            paddingTop: "4px",
            paddingBottom: "1px",
          }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: window.innerWidth <= 1280 ?
            `${this.state.windowHeight - 560}px !important` :
            `${this.state.windowHeight - 300}px !important`,
          minHeight: "300px",
        },
      },
    }
  })

  //
  // column specifications
  // ---------------------

  textColor = (value, color, t) => {
    let translated;
    if(t){
      translated = t(value)
    }
    return color &&
      <span style={{ "color": color }} title={value}>{translated ? translated :  value}</span>

  }

  standardOptions = (name, colList, color = "inherit") => {
    return {
      sort: true,
      searchable: true,
      customBodyRender: (value) => this.textColor(value, color),
      display: this.state[colList][name]
    }
  }

  customerOptions = (name, colList, color = "inherit") => {
    return {
      sort: true,
      searchable: true,
      customBodyRender: (value) => this.state.alertCustomerChoices.find((el) => el.value.toString() === value) !== undefined ? this.state.alertCustomerChoices.find((el) => el.value.toString() === value).label : null,
      display: this.state[colList][name]
    }
  }

  addressOptions = (name, colList, color = "inherit") => {
    return {
      sort: false,
      searchable: true,
      customBodyRender: (value) => value !== null ? this.emailTag(value.map((el) => this.state.adrnrChoices.length > 0 ? this.state.adrnrChoices.find((adr) => adr.value === el).label : [])) : [],
      display: this.state[colList][name]
    }
  }

  arrayOptions = (name, colList, color = "inherit") => {
    return {
      sort: false,
      searchable: true,
      customBodyRender: (value) => this.emailTag(value),
      display: this.state[colList][name]
    }
  }

  emailTag = (value) =>{
    if(value == null) return []
    const chipList = [];
    value.forEach( ele => {
      chipList.push(
        <Chip label={ele}
              key={ele}
              style={{ marginTop: "2px", minHeight: "auto", marginBottom: "2px", }}
              variant="outlined"
              color="default"
              size="medium"
        />
      )
    })
    return chipList
  }

  translatedLanguagesOptions = (name, colList, t, color = "inherit",) => {
    return {
      sort: true,
      customBodyRender: (value) => this.translatedLanguagesDropdown(value, t),
      display: this.state[colList][name]
    }
  }

  translatedLanguagesDropdown = (value, t) =>{
    switch (value) {
      case "de":
        return t("alert_lang_de")
      case "en":
        return t("alert_lang_en")
      case "fr":
        return t("alert_lang_fr")
      case "it":
        return t("alert_lang_it")
      case "nl":
        return t("alert_lang_nl")
      default:
        return t("alert_lang_en")
    }
  }

  translatedAlertTypeOptions = (name, colList, t, color = "inherit",) => {
    return {
      sort: true,
      customBodyRender: (value) => this.translatedAlertType(value, t),
      display: this.state[colList][name]
    }
  }

  translatedAlertType = (value, t) =>{
    switch (value) {
      case "alert_atp":
        return t("alert_atp")
      case "alert_atd":
        return t("alert_atd")
      case "alert_ata":
        return t("alert_ata")
      case "alert_etp_plus15":
        return t("alert_etp_plus15")
      case "alert_eta_plus15":
        return t("alert_eta_plus15")
      case "alert_eta_minus15":
        return t("alert_eta_minus15")
      case "alert_sub_vehicle":
        return t("alert_sub_vehicle")
    }
  }

  activeOptions = (name, value, colList) => {
    return {
      filter: false,
      searchable: true,
      sort: true,
      customBodyRender: (value) => {
        if(value){
          return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
        }else{
          return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "red" }} />
        }
      },
      display: this.state[colList][name]
    }
  }

  actionOptions = (t) => {
    return {
      filter: false,
      sort: false,
      searchable: false,
      print: false,
      download: false,
      viewColumns: false,
      display: true,
      customBodyRender: (value) => this.actionIcons(value, t),
    }
  }

  actionIcons = (d, t) => {
    return <div>
      <IconButton
        aria-label="Edit"
        style={{ padding: 0, marginRight: 10 }}
        onClick={() => {
          this.setState({
            mode: "edit",
            alertId: d.al_id,
            alertType: d.al_type,
            active: d.al_active,
            customer: parseInt(d.al_customer),
            sub: d.al_sub,
            adrnrArr: d.al_adrnr_arr !== null ? d.al_adrnr_arr.map((el) => this.state.adrnrChoices.find((adr) => adr.value === el)) : [],
            emailArr: d.emailArr !== null ? d.al_email_arr.map((el) => ({ value: el, label: el })) : [],
            smsArr: d.smsArr !== null ? d.al_sms_arr.map((el) => ({ value: el, label: el })) : [],
            lang: d.al_lang,
          })
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        aria-label="Delete"
        style={{ padding: 0 }}
        onClick={() => {
          this.setState({
            alert: true,
            clickedAlert: d.al_id,
          })
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div >
  }

  render() {
    const { classes } = this.props;
    const { tableLayout } = this.state;
    const { t, i18n } = this.props;
    const {
      mode,
      alertId,
      alertType,
      active,
      customer,
      sub,
      adrnrChoices,
      alertSubChoices,
      alertCustomerChoices,
      adrnrArr,
      emailArr,
      smsArr,
      lang,
    } = this.state;


    const alertTypeDropdown = [
      { value: "alert_atp", label: t("alert_atp") },
      { value: "alert_atd", label: t("alert_atd") },
      { value: "alert_ata", label: t("alert_ata") },
      { value: "alert_etp_plus15", label: t("alert_etp_plus15") },
      { value: "alert_eta_plus15", label: t("alert_eta_plus15") },
      { value: "alert_eta_minus15", label: t("alert_eta_minus15") },
      { value: "alert_sub_vehicle", label: t("alert_sub_vehicle") },
    ];

    const languagesDropdown = [
      { value: "de", label: t("alert_lang_de") },
      { value: "en", label: t("alert_lang_en"), },
      { value: "fr", label: t("alert_lang_fr") },
      { value: "it", label: t("alert_lang_it"), },
      { value: "nl", label: t("alert_lang_nl") },
    ];

    // TABLE CONFIG

    const columns = tableLayout === "scroll" ?
      [ // full desktop view
        { name: "al_active", label: t("alert_active"), options: this.activeOptions("al_active", "al_active", "alertListColListDesktop") },
        { name: "al_type", label: t("alert_type"), options: this.translatedAlertTypeOptions("al_type", "alertListColListDesktop", t, "inherit") },
        { name: "al_customer", label: t("alert_customer"), options: this.customerOptions("al_customer", "alertListColListDesktop") },
        { name: "al_sub", label: t("alert_sub"), options: this.standardOptions("al_sub", "alertListColListDesktop") },
        { name: "al_adrnr_arr", label: t("alert_adrnr_arr"), options: this.addressOptions("al_adrnr_arr", "alertListColListDesktop") },
        { name: "al_email_arr", label: t("alert_email_arr"), options: this.arrayOptions("al_email_arr", "alertListColListDesktop") },
        { name: "al_sms_arr", label: t("alert_sms_arr"), options: this.arrayOptions("al_sms_arr", "alertListColListDesktop") },
        { name: "al_lang", label: t("alert_lang"), options: this.translatedLanguagesOptions("al_lang", "alertListColListDesktop", t, "inherit") },
        { name: "actions", label: " ", options: this.actionOptions(t) },
      ] :
      [ // reduced mobile view
        { name: "al_active", label: t("alert_active"), options: this.activeOptions("al_active", "al_active", "alertListColListMobile") },
        { name: "al_type", label: t("alert_type"), options: this.translatedAlertTypeOptions("al_type", "alertListColListDesktop", t, "inherit") },
        { name: "al_customer", label: t("alert_customer"), options: this.customerOptions("al_customer", "alertListColListMobile") },
        { name: "al_sub", label: t("alert_sub"), options: this.standardOptions("al_sub", "alertListColListMobile") },
        { name: "al_adrnr_arr", label: t("alert_adrnr_arr"), options: this.addressOptions("al_adrnr_arr", "alertListColListMobile") },
        { name: "al_email_arr", label: t("alert_email_arr"), options: this.arrayOptions("al_email_arr", "alertListColListMobile") },
        { name: "al_sms_arr", label: t("alert_sms_arr"), options: this.arrayOptions("al_sms_arr", "alertListColListMobile") },
        { name: "al_lang", label: t("alert_lang"), options: this.translatedLanguagesOptions("al_lang", "alertListColListMobile", t, "inherit") },
        { name: "actions", label: " ", options: this.actionOptions(t) },
      ];

    const options = {
      filterType: 'multiselect',
      pagination: false,
      elevation: 0,
      print: false,
      download: false,
      selectableRows: false,
      responsive: tableLayout,
      filter: false,
      search: true,
      viewColumns: true,
      onColumnViewChange: (changedColumn, action) => {
        const thelist = tableLayout === "scroll" ? "alertListColListDesktop" : "alertListColListMobile"
        let tempColList = { ...this.state[thelist] }
        tempColList[changedColumn] = action === "add" ? true : false
        this.setState({ [thelist]: tempColList })
        localStorage.setItem(thelist, JSON.stringify(tempColList))
      },
      textLabels: {
        body: {
          noMatch: t("analysisArea_no_trips_match_criteria"),
          toolTip: t("general_sort"),
        },
        toolbar: {
          search: t("general_search"),
          viewColumns: t("analysisArea_select_cols"),
          filterTable: t("analysisArea_filter_tab"),
          downloadCsv: "Download",
        },
        filter: {
          all: t("analysisArea_all_trips"),
          title: t("analysisArea_filter"),
          reset: t("analysisArea_filter_reset"),
        },
        viewColumns: {
          title: t("analysisArea_select_cols"),
          titleAria: t("analysisArea_show_hide_cols"),
        },
      }
    };

    const buildDropdown = (prop) => {
      return prop in this.state
        ? this.state[prop].map(item => ({ value: item, label: item }))
        : [];
    }

    const buildtranslatedDropdown = (prop) => {
      return prop in this.state
        ? this.state[prop].map(item => ({ value: item, label: t(item) }))
        : [];
    }

    const buildcustomerDropdown = (prop) => {
      return prop in this.state
        ? this.state[prop].map(item => ({ value: item, label: this.state.alertCustomerChoices.find((el) => el.value.toString() === item) !== undefined ? this.state.alertCustomerChoices.find((el) => el.value.toString() === item).label : null }))
        : [];
    }

    const buildtranslatedLanguagesDropdown = (prop) => {
      return prop in this.state
        ? this.state[prop].map(item => ({ value: item, label: this.translatedLanguagesDropdown(item, t) }))
        : [];
    }

    const al_typeDropdown = buildtranslatedDropdown("dropData_type");
    const al_customerDropdown = buildcustomerDropdown("dropData_customer");
    const al_subDropdown = buildDropdown("dropData_sub");
    const al_langDropdown = buildtranslatedLanguagesDropdown("dropData_lang");

    const clearFilters = () => {
      this.setState({
        al_active: null,
        al_type: null,
        al_customer: null,
        al_sub: null,
        al_adrnr_arr: null,
        al_email_arr: null,
        al_sms_arr: null,
        al_lang: null,
      }, () => {
        this.filterData();
      })
    }


    return (
      <div>
        <GridContainer>
          {mode !== "overview" &&
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    {t("alert_edit_alert")}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomAutoComplete
                        labelText={"alert_type"}
                        id="type"
                        value={alertTypeDropdown.filter(el => el.value === this.state.alertType)}
                        onChange={value => {
                          this.setState({ alertType: value != null ? value.value : null });
                        }}
                        required={true}
                        selectChoices={alertTypeDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomAutoComplete
                        labelText={"alert_lang"}
                        id="lang"
                        value={languagesDropdown.filter(el => el.value === this.state.lang)}
                        onChange={value => {
                          this.setState({ lang: value != null ? value.value : null });
                        }}
                        required={true}
                        selectChoices={languagesDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomAutoComplete
                        labelText={"alert_customer"}
                        id="type"
                        value={alertCustomerChoices.filter(el => el.value === this.state.customer)}
                        onChange={value => {
                          this.setState({ customer: value != null ? value.value : null });
                        }}
                        required={true}
                        selectChoices={alertCustomerChoices}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomAutoComplete
                        labelText={"alert_sub"}
                        id="type"
                        value={alertSubChoices.filter(el => el.value === this.state.sub)}
                        onChange={value => {
                          this.setState({ sub: value != null ? value.value : null });
                        }}
                        required={true}
                        selectChoices={alertSubChoices}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Tags
                        id="adnr_arr"
                        labelField={t("alert_adrnr_arr")}
                        tags={adrnrArr}
                        deleteCallback={this.setAddrnr}
                        additionCallback={this.setAddrnr}
                        select={true}
                        selectChoices={adrnrChoices}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Tags
                        id="email_arr"
                        labelField={t("alert_email_arr")}
                        tags={emailArr}
                        validators={[
                          {
                            validation: (val) => val !== "",
                            message: t("input_empty_email")
                          },
                          {
                            validation: (val) => new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(val),
                            message: t("input_enter_valid_email")
                          },
                        ]}
                        deleteCallback={this.setEmail}
                        additionCallback={this.setEmail}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Tags
                        id="sms_arr"
                        labelField={t("alert_sms_arr")}
                        tags={smsArr}
                        validators={[
                          {
                            validation: (val) => val !== "",
                            message: t("input_empty_phone")
                          },
                          {
                            validation: (val) => new RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/).test(val),
                            message: t("input_enter_valid_phone")
                          },
                        ]}

                        deleteCallback={this.setSms}
                        additionCallback={this.setSms}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomCheckbox
                        labelText={t("alert_active")}
                        id="active"
                        inputProps={{
                          checked: active,
                          onChange: (event) => this.setState({
                            active: event.target.checked
                          })
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <div>
                    {mode === "add" &&
                      <Button
                        color="primary"
                        onClick={() => {
                          const requestBody = {
                            "al_type": alertType,
                            "al_active": active,
                            "al_customer": customer,
                            "al_sub": sub,
                            "al_adrnr_arr": adrnrArr.map((el) => el.value),
                            "al_email_arr": emailArr.map((el) => el.value),
                            "al_sms_arr": smsArr.map((el) => el.value),
                            "al_lang": lang,
                          }
                          axios.post(`${api_ipaddress}/api/alerts`,
                            requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                            .then((res) => {
                              this.setState({
                                mode: "overview",
                                alertType: null,
                                active: null,
                                customer: null,
                                sub: null,
                                lang: null,
                                adrnrArr: [],
                                emailArr: [],
                                smsArr: [],
                              })
                              this.showNotification("tc", t(res.data.message));
                              axios.get(`${api_ipaddress}/api/alerts`,
                                { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                .then(res => {
                                  const data = res.data.alerts
                                  data.forEach( d => {
                                    d["translated_type"] = t(d.al_type)
                                    d["actions"] = d
                                  })
                                  this.setState({
                                    alerts: data,
                                    alert: false,
                                    mode: "overview",
                                    alertType: null,
                                    active: true,
                                    customer: null,
                                    sub: null,
                                    lang: null,
                                    adrnrArr: [],
                                    emailArr: [],
                                    smsArr: [],
                                  });
                                  this.filterData();
                                });
                            })
                            .catch((err) => {
                              this.showNotification("tc", t(err.response.data.message));
                            });
                        }}
                        disabled={!this.validateForm()}
                      >
                        {t("alert_add_alert")}
                      </Button>}
                    {mode === "edit" &&
                      <Button
                        color="primary"
                        onClick={() => {
                          const requestBody = {
                            "al_type": alertType,
                            "al_active": active,
                            "al_customer": customer,
                            "al_sub": sub,
                            "al_adrnr_arr": adrnrArr.map((el) => el.value),
                            "al_email_arr": emailArr.map((el) => el.value),
                            "al_sms_arr": smsArr.map((el) => el.value),
                            "al_lang": lang,
                          }
                          axios.put(`${api_ipaddress}/api/alerts/${alertId}`,
                            requestBody, { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                            .then((res) => {
                              this.setState({
                                mode: "overview",
                                alertType: null,
                                active: null,
                                customer: null,
                                sub: null,
                                lang: null,
                                adrnrArr: [],
                                emailArr: [],
                                smsArr: [],
                              })
                              this.showNotification("tc", t(res.data.message));
                              axios.get(`${api_ipaddress}/api/alerts`,
                                { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                                .then(res => {
                                  const data = res.data.alerts
                                  data.forEach( d => {
                                    d["translated_type"] = t(d.al_type)
                                    d["actions"] = d
                                  })
                                  this.setState({ alerts: data });
                                  this.filterData();
                                });
                            })
                            .catch((err) => {
                              this.showNotification("tc", t(err.response.data.message));
                            });
                        }}
                        disabled={!this.validateForm()}
                      >
                        {t("general_save")}
                      </Button>}
                    <Button
                      color="white"
                      onClick={() => {
                        this.setState({
                          mode: "overview",
                          alertType: null,
                          active: null,
                          customer: null,
                          sub: null,
                          adrnrArr: [],
                          emailArr: [],
                          smsArr: [],
                          lang: null,
                        })
                      }}
                    >
                      {t("general_cancel")}
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          }
          {mode === "overview" &&
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>{t("alert_overview")}</h4>
                  <IconButton
                    aria-label="add"
                    style={{ padding: 0, color: "white" }}
                    onClick={() => {
                      this.setState({
                        mode: "add"
                      })
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </CardHeader>
                <CardBody>
                  {/* START FILTERS*/}
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={3}>
                      <CustomAutoComplete
                        labelText={"al_typeDropdown"}
                        id="type"
                        value={al_typeDropdown.filter(el => el.value === this.state.al_type)}
                        onChange={value => {
                          this.setState({ loading: true, al_type: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={al_typeDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                      <CustomAutoComplete
                        labelText={"al_customerDropdown"}
                        id="type"
                        value={al_customerDropdown.filter(el => el.value === this.state.al_customer)}
                        onChange={value => {
                          this.setState({ loading: true, al_customer: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={al_customerDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                      <CustomAutoComplete
                        labelText={"al_subDropdown"}
                        id="type"
                        value={al_subDropdown.filter(el => el.value === this.state.al_sub)}
                        onChange={value => {
                          this.setState({ loading: true, al_sub: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={al_subDropdown}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                      <CustomAutoComplete
                        labelText={"al_langDropdown"}
                        id="type"
                        value={al_langDropdown.filter(el => el.value === this.state.al_lang)}
                        onChange={value => {
                          this.setState({ loading: true, al_lang: value != null ? value.value : null }, this.filterData);
                        }}
                        selectChoices={al_langDropdown}
                      />
                    </GridItem>
                  </GridContainer>
                  {/* END FILTERS */}
                  {!this.state.loading &&
                  <Box>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            key={
                              this.state.tableLayout +
                              this.state.updateDT +
                              i18n.language
                            }
                            title={""}
                            data={this.state.displayedData}
                            columns={columns}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </GridItem>
                    </GridContainer>
                  </Box>
                  }
                  {this.state.loading &&
                    <CircularProgress disableShrink className={classes.circularProgress} />
                  }
                </CardBody>
              </Card>
            </GridItem>
          }
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <AlertDialog
          open={this.state.alert ? this.state.alert : false}
          onClose={() => this.setState({ alert: false })}
          handleNo={() => this.setState({ alert: false })}
          handleYes={() =>
            axios.delete(`${api_ipaddress}/api/alerts/${this.state.clickedAlert}`,
              { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
              .then((res) => {
                axios.get(`${api_ipaddress}/api/alerts`,
                  { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
                  .then(res => {
                    const data = res.data.alerts
                    data.forEach( d => {
                      d["translated_type"] = t(d.al_type)
                      d["actions"] = d
                    })
                    this.setState({
                      alerts: data,
                      alert: false,
                      mode: "overview",
                      alertType: null,
                      active: true,
                      customer: null,
                      sub: null,
                      lang: null,
                      adrnrArr: [],
                      emailArr: [],
                      smsArr: [],
                    });
                    this.filterData();
                  });
                this.showNotification("tc", t(res.data.message));
              })
              .catch((err) => {
                this.showNotification("tc", t(err.response.data.message));
              })
          }
          title={sprintf(t("alert_delete_warning_title"), this.state.clickedAlert)}
          content={t("alert_delete_warning_message")}
        />
      </div>
    );
  }
}

Alerts.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Alerts));
