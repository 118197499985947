/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import CancelIcon from '@material-ui/icons/Cancel';

import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: "22px 0 0 0",
    position: "relative",
    paddingBottom: "10px",
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    font: "inherit",
  },
  singleValue: {
    font: "inherit",
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    font: "inherit",
    whiteSpace: "nowrap",
    width: "80%",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Keine Übereinstimmung
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          style: { height: "29px", display: "contents" },
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{ font: "inherit" }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={clsx(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps} />}
  />
);

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  componentDidUpdate(nextProps) {
    this.render();
  }

  render() {
    const { classes, theme } = this.props;
    const { t } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      })
    };

    return (
      <div className={classes.root}>
        <Typography
          variant="caption"
          gutterBottom
          style={{ marginBottom: "px" }}
        >
          {t(this.props.labelText)}
        </Typography>
        <NoSsr>
          {
            !this.props.isCreatable &&
            <Select
              classes={classes}
              styles={selectStyles}
              id={this.props.id}
              options={this.props.selectChoices}
              components={components}
              value={this.props.value}
              onChange={this.props.onChange}
              onMenuOpen={this.props.onOpen}
              isMulti={this.props.isMulti}
              placeholder={t("general_select_or_search")}
              isClearable
              isDisabled={this.props.disabled}
            />
          }
          {
            this.props.isCreatable &&
            <CreatableSelect
              classes={classes}
              styles={selectStyles}
              id={this.props.id}
              options={this.props.selectChoices}
              components={components}
              value={this.props.value}
              onChange={this.props.onChange}
              onMenuOpen={this.props.onOpen}
              onCreateOption={this.props.onCreateOption}
              isMulti={this.props.isMulti}
              placeholder={t("general_select_or_search")}
              isClearable
              isDisabled={this.props.disabled}
            />
          }
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(IntegrationReactSelect));