export function textToFloat(float){
    if(!float){
        return 0;
    }

    //Index of first comma
    const posSC = float.indexOf("'")

    if(posSC > 0){
        //Remove single quotes (e.g. 1,23 --> 1.23)
        return parseFloat(float.replace(/'/g, ''))
    }
    return parseFloat(float)
}

export function floatToText(float){
    if (!float)
        return '0.00'

    return float.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
}