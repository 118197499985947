import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";

function CustomDate({ ...props }) {
  const {
    classes,
    formControlProps,
    inputProps,
  } = props;
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl
          {...formControlProps}
          className={formControlProps.className + " " + classes.formControl}
      >
        <KeyboardDatePicker
            className={classes.underline}
            {...inputProps}
            fullWidth
            invalidDateMessage={t("general_date_invalid")}
            autoOk
            variant="inline"
            format="DD.MM.YYYY"
            InputAdornmentProps={{ position: "end" }}
        />

      </FormControl>
    </MuiPickersUtilsProvider>
  );
}

CustomDate.propTypes = {
  classes: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
};

export default withStyles(customInputStyle)(CustomDate);
