import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import CustomInput from "../../components/CustomInput/CustomInput";

class CalendarItem extends React.Component {
    render() {
        const { key, week, onChangeCalendar } = this.props;

        return <CustomInput
                    id={`reference_${key}`}
                    labelText={week}
                    inputProps={{
                        value: this.props.reference,
                        onChange: (event) => { onChangeCalendar(week, event.target.value) },
                    }}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
    }
}

CalendarItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(tasksStyle)(CalendarItem);
