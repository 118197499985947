import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { api_ipaddress } from '../../variables.js'
import axios from 'axios'

import 'moment/locale/de-ch'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import CustomDate from "../../components/CustomInput/CustomDate.jsx";

import { withTranslation } from 'react-i18next';

var moment = require('moment-timezone');

const styles = {
  cardTitleWhite: {
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "0px",
  }
};

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tc: false,
      message: "",
      from: moment().subtract(1, "weeks"),
      to: moment(),
      log_date: moment(),
    };
  }


  componentDidMount() {
    moment.tz.setDefault();
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ [place]: false });
      }.bind(this),
      6000
    );
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const { from, to, log_date } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem md={12} lg={8} >
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("report_download_report")}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomDate
                      inputProps={{
                        label: t("report_start_date"),
                        id: "from",
                        value: from,
                        onChange: (date) => this.setState({ from: date }),
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomDate
                      inputProps={{
                        label: t("report_end_date"),
                        id: "to",
                        value: to,
                        onChange: (date) => this.setState({ to: date }),
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-start" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    let request = {
                      start_date: from.format("YYYY-MM-DD"),
                      end_date: to.format("YYYY-MM-DD")
                    };

                    axios.post(`${api_ipaddress}/api/reportdownload`, request,
                      {
                        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                        responseType: 'blob',
                      })
                      .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'truckwatch_export.xlsx');
                        document.body.appendChild(link);
                        link.click();
                      })
                      .catch((err) => {
                        console.log(err);
                        this.showNotification("tc", "Leider gab es ein Problem");
                      })
                  }}
                >
                  {t("report_download_report")}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem md={12} lg={4} >
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("report_download_log")}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomDate
                      inputProps={{
                        label: t("report_log_date"),
                        id: "log_date",
                        value: log_date,
                        onChange: (date) => this.setState({ log_date: date }),
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-start" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    let request = {
                      log_date: log_date.format("YYYY-MM-DD")
                    };
                    axios.post(`${api_ipaddress}/api/logdownload`, request,
                      {
                        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                        responseType: 'blob',
                      })
                      .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'log_export.xlsx');
                        document.body.appendChild(link);
                        link.click();
                      })
                      .catch((err) => {
                        console.log(err);
                        this.showNotification("tc", "Leider gab es ein Problem");
                      })
                  }}
                >
                  {t("report_download_log")}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem md={12} lg={4} >
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("report_download_webfleet_log")}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomDate
                      inputProps={{
                        label: t("report_log_date"),
                        id: "log_date",
                        value: log_date,
                        onChange: (date) => this.setState({ log_date: date }),
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-start" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    let request = {
                      log_date: log_date.format("YYYY-MM-DD")
                    };
                    axios.post(`${api_ipaddress}/api/webfleetdlogdownload`, request,
                      {
                        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
                        responseType: 'blob',
                      })
                      .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'webfleet_log_export.xlsx');
                        document.body.appendChild(link);
                        link.click();
                      })
                      .catch((err) => {
                        console.log(err);
                        this.showNotification("tc", "Leider gab es ein Problem");
                      })
                  }}
                >
                  {t("report_download_log")}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc" //topcenter
          color="primary"
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </div>
    );
  }
}

Report.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(Report));
