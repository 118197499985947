import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";

import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

import { useTranslation } from 'react-i18next';

function CustomDateTime({ ...props }) {
  const {
    classes,
    formControlProps,
    inputProps,
  } = props;
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}
      >
        <KeyboardDateTimePicker
          className={classes.underline }
          {...inputProps}
          ampm={false}
          allowKeyboardControl={true}
          fullWidth
          invalidDateMessage={t("general_date_invalid")}
          autoOk
          clearable
          variant="inline"
          format="DD.MM.YYYY HH:mm"
          InputLabelProps={{
            shrink: inputProps.value !== null && inputProps.value !== "",
            animated: true,
          }}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
}

CustomDateTime.propTypes = {
  classes: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
};

export default withStyles(customInputStyle)(CustomDateTime);