import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import CalendarItem from "../../components/CalendarList/CalendarItem";
class CalendarList extends React.Component {
    render() {
        const { t, onChangeCalendar } = this.props
        return (<>
            <span>Calendar Week References</span>
            { this.props.list.length > 0 ? this.props.list.map((item, idx) => {
                return <CalendarItem key={idx} week={item.week}  reference={item.reference} onChangeCalendar={onChangeCalendar} t={t} />
            }) : <div>No dates selected</div>}
        </>);
    }
}

CalendarList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(tasksStyle)(CalendarList);
