// @material-ui/icons
import DashboardIcon from "@material-ui/icons/ViewList";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import AreaAnalysisIcon from '@material-ui/icons/DonutLarge';
import AlertsIcon from "@material-ui/icons/Alarm";
import UsersIcon from "@material-ui/icons/People";
import CustomersIcon from '@material-ui/icons/Person';
import LoginIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import DispositionIcon from "@material-ui/icons/Create";
import CustomerJournalIcon from "@material-ui/icons/AccountBalanceWallet";
import SubcontractorJournalIcon from "@material-ui/icons/AccountBalance";
import PriceListIcon from "@material-ui/icons/EuroSymbol";
// import SupplierJournalIcon from "@material-ui/icons/CardTravel";
// import PriceListIcon from "@material-ui/icons/LocalAtm";


// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import ReportPage from "views/Report/Report.jsx";
import EditPage from "views/Edit/Edit.jsx";
import AnalysisAreaPage from "views/AnalysisArea/AnalysisArea.jsx";
import DispositionPage from "views/Disposition/Disposition.jsx";
import UsersPage from "views/Users/Users.jsx";
import CustomersPage from "views/Customers/Customers.jsx";
import AlertsPage from "views/Alerts/Alerts.jsx";
import LoginPage from "views/Login/Login.jsx";
import SettingPage from "views/Setting/Setting.jsx";
import CustomerJournalPage from "views/CustomerJournal/CustomerJournal.jsx";
import SubcontractorJournalPage from "views/SubcontractorJournal/SubcontractorJournal";
import PriceListPage from "views/PriceList/PriceList";
import EditDisposition from "./views/EditDisposition/EditDisposition";

// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/login",
    name: "login_login",
    icon: LoginIcon,
    component: LoginPage,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "dashboard_dashboard",
    icon: DashboardIcon,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/edit/:id",
    name: "edit_edit_trip",
    icon: EditIcon,
    component: EditPage,
    layout: "/admin"
  },
  {
    path: "/analysisArea",
    name: "analysisArea_analysisArea",
    icon: AreaAnalysisIcon,
    component: AnalysisAreaPage,
    layout: "/admin"
  },
  {
    path: "/disposition",
    name: "disposition_disposition",
    icon: DispositionIcon,
    component: DispositionPage,
    layout: "/admin"
  },
  {
    path: "/editdisposition/:id",
    name: "edit_edit_disposition",
    icon: EditIcon,
    component: EditDisposition,
    layout: "/admin"
  },
  {
    path: "/customerjournal",
    name: "customerjournal_customerjournal",
    icon: CustomerJournalIcon,
    component: CustomerJournalPage,
    layout: "/admin"
  },
  {
    path: "/subcontractorjournal",
    name: "subcontractorjournal_subcontractorjournal",
    icon: SubcontractorJournalIcon,
    component: SubcontractorJournalPage,
    layout: "/admin"
  },
  {
    path: "/pricelist",
    name: "pricelist_pricelist",
    icon: PriceListIcon,
    component: PriceListPage,
    layout: "/admin"
  },
  {
    path: "/report",
    name: "report_report_download",
    icon: DownloadIcon,
    component: ReportPage,
    layout: "/admin"
  },
  {
    path: "/alerts",
    name: "alert_alerts",
    icon: AlertsIcon,
    component: AlertsPage,
    layout: "/admin"
  },
  {
    path: "/customers",
    name: "customer_manage_customers",
    icon: CustomersIcon,
    component: CustomersPage,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "users_manage_users",
    icon: UsersIcon,
    component: UsersPage,
    layout: "/admin"
  },
  {
    path: "/setup",
    name: "settings_settings",
    icon: SettingsIcon,
    component: SettingPage,
    layout: "/admin"
  },

];

export default dashboardRoutes;
